import React, { useEffect } from "react";
import { useState } from "react";
import { getMDTTokenInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-tooltip/dist/react-tooltip.css";
import ReactTooltip from "react-tooltip";
import { web3 } from "../web3";
import { storeNFT } from "../config/storage";
import { services } from "../services";
import getContractAddresses from "../contractData/address";
import ERC20Token from "../contractData/erc20Token.json";
import { TokenByteCode } from "../contractData/byteCode";
import { useNavigate } from "react-router-dom";
import useWeb3AccountData from "../helpers/customHooks";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const CreateERC20Token = ({ header }) => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const { couponContractAddress } = getContractAddresses();
  const [ipfsData, setIpfsData] = useState({
    tokenName: "",
    tokenSymbol: "",
    totalSupply: "",
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  // const [allowance, setAllowance] = useState(false);

  const changeIPFSData = async (e) => {
    setIpfsData({ ...ipfsData, [e.target.name]: e.target.value });
  };

  const CreateERC20 = async () => {
    if (
      ipfsData.tokenName == "" ||
      ipfsData.tokenSymbol == "" ||
      ipfsData.totalSupply == ""
    )
      toast.error("All fields are required");
    else {
      setLoading(true);
      const mdtContractInstance = await getMDTTokenInstance();
      let tokenABI = await new web3.eth.Contract(ERC20Token);
      console.log("tokenABI", tokenABI);
      await tokenABI
        .deploy({
          data: TokenByteCode,
          arguments: [
            ipfsData.tokenName,
            ipfsData.tokenSymbol,
            web3.utils.toWei(ipfsData.totalSupply),
          ],
        })
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log("on hash ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", (receipt) => {
          console.log("on receipt ", receipt);
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          setLoading(false);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoading(false);
        });
    }
  };
  console.log(ipfsData);

  console.log("timeStamp", ipfsData);
  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-3">
          <h3 className="my-4">Create ERC20 Token</h3>
          <div className="row">
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="cost">Token Name:</label>
                <input
                  className="form-control"
                  placeholder="Enter Token Name here..."
                  id="tokenName"
                  type={"text"}
                  name="tokenName"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="validity">Token Symbol:</label>
                <input
                  className="form-control"
                  placeholder="Enter Token Symbol here..."
                  id="tokenSymbol"
                  type={"text"}
                  name="tokenSymbol"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Total Supply(in Ether):</label>
                <input
                  className="form-control"
                  placeholder="Total Supply"
                  id="totalSupply"
                  type={"text"}
                  name="totalSupply"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
          </div>
          <div className="">
            <button
              type="button"
              className="btn btnBackground px-5 btn-lg"
              onClick={CreateERC20}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "CREATE ERC20 TOKEN"
              )}{" "}
            </button>
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default CreateERC20Token;
