import React, { useEffect } from "react";
import { useState } from "react";
import { getlazyNFTTokenInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { web3 } from "../web3";
import { services } from "../services";
// import Loader from "../assets/loading_arc.gif";
import Timer from "./timer";
import useWeb3AccountData from "../helpers/customHooks";
import { Loader1 } from './loader';

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const ViewDistributedCoupon = () => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [couponDetails, setCouponDetails] = useState({
    couponCode: null,
    reedemType: 0,
  });
  const [loading, setLoading] = useState({ id: null, load: false });
  const [emptyLoading, setEmptyLoading] = useState(true);
  const [distributedList, setDistributedList] = useState([]);
  const [page, setPage] = useState(1);
  // console.log("page: " + page);

  useEffect(() => {
    getnftList();
  }, []);
  const getnftList = async () => {
    // if (address) {
    let list = [];
    const couponListRes = await services.get(`/distributed-token/listTokens`);
    if (couponListRes?.status == 200) {
      for (let i = +couponListRes.data.data.length - 1; i >= 0; i--) {
        let fetchjson = await fetch(couponListRes.data.data[i].uri).then(
          (response) => {
            return response.json();
          }
        );
        console.log(fetchjson);
        await list.push({
          ...couponListRes.data.data[i],
          imageUrl: "https://ipfs.io/ipfs/" + fetchjson.image.split("//")[1],
        });
      }
      console.log("couponListRes", couponListRes, "list", list);
      await setDistributedList(list);
      await setEmptyLoading(false);
    }
    await setEmptyLoading(false);
  };
  console.log("couponList", distributedList);

  return (
    <>
      <div
        className="container-fluid my-5"
        // style={{
        //   margin: "50px 10px auto 10px",
        // }}
      >
     
       <h2 className="fw-bold">View Distribute Coupon</h2>
        {emptyLoading ? (
          <div
            className="text-center mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <img src={Loader} width={200} height={200} /> */}
          <Loader1 />
          </div>
        ) : distributedList?.length !== 0 ? (
          <div className="">
            <div className="row text-center bg-secondary">
              <div className="col-1">SN</div>
              <div className="col-3">Coupon Image</div>
              <div className="col-2">Name</div>
              <div className="col-2">CouponCode</div>
              <div className="col-1">Coupon Cost</div>
              <div className="col-3">Creator</div>
            </div>
            {distributedList &&
              distributedList.map((coupon, key) => (
                <div className="row my-2 text-center disBG ">
                  <div className="col-1 p-2">{key + 1}</div>
                  <div className="col-3 p-2">
                    <img src={coupon?.imageUrl} width={80} height={80} alt="" />
                  </div>
                  <div className="col-2 p-2">
                    {coupon?.name ? coupon?.name : "Coupon Name"}
                  </div>
                  <div className="col-2 p-2">{coupon?.couponCode}</div>
                  <div className="col-1 p-2">
                    {coupon?.rewardCost > 1
                      ? web3.utils.fromWei(coupon?.rewardCost)
                      : coupon?.rewardCost / 1000000000000000000}
                  </div>
                  <div className="col-3 p-2">
                    {coupon?.creator.slice(0, 7)}
                    {"...."}
                    {coupon?.creator.slice(33)}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <h2 className="text-center">No Data found in Current Page!!</h2>
        )}
        {/* <div className="row">
          {distributedList &&
            distributedList.map((coupon, i) => (
              <div className={`col-5 m-3 p-2 bg-light text-dark`} key={i}>
                <div className="row border">
                  <div className="col-12 p-3 text-center">
                    <img src={coupon?.imageUrl} width={100} height={100} />
                  </div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td className=" text-break">
                          {coupon?.name ? coupon?.name : "NFT Name"}
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td className=" text-break">{coupon?.description}</td>
                      </tr>
                      <tr>
                        <td>Validity</td>
                        <td className=" text-break">{coupon?.validity} Days</td>
                      </tr>
                      <tr>
                        <td>Coupon Cost</td>
                        <td className=" text-break">{coupon?.couponCost}</td>
                      </tr>
                      <tr>
                        <td>Creator</td>
                        <td className=" text-break">{coupon?.creator}</td>
                      </tr>
                      <tr>
                        <td>Minted By</td>
                        <td className=" text-break">
                          {coupon?.txnThroughOwner
                            ? "Owner Wallet"
                            : "User Wallet"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-12 pb-3">
                    <div className="form-group">
                      <label className="p-1" for="CouponCode">
                        CouponCode:
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter CouponCode"
                        id="CouponCode"
                        type={"number"}
                        name="couponCode"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mt-1">
                      <label className="p-1" for="CouponCode">
                        Reedem Coupon Token Type:
                      </label>
                      <select
                        className="form-select form-select"
                        aria-label=".form-select-lg example"
                        name="reedemType"
                        onChange={handleChange}
                        required
                      >
                        <option selected disabled>
                          Select an Option
                        </option>
                        <option value={0}>ERC Token</option>
                        <option value={1}>NFT Token</option>
                      </select>
                    </div>
                    <br />
                    <div className="d-grid gap-2 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => redeemCoupon(coupon)}
                        disabled={loading.id == coupon._id ? true : false}
                      >
                        {" "}
                        {loading.id == coupon._id
                          ? "Loading..."
                          : "Redeem Coupon"}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div> */}
      </div>
      {/* <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={page > 2 ? "page-item" : "page-item disabled"}>
            <a
              className="page-link"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              onClick={() => setPage(page - 1)}
            >
              Previous
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => setPage(1)}>
              1
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => setPage(2)}>
              2
            </a>
          </li>
          {page > "2" ? (
            <>
              <li className="page-item">
                <a className="page-link" href="#">
                  ...
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  {page}
                </a>
              </li>
            </>
          ) : null}
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
              Next
            </a>
          </li>
        </ul>
      </nav> */}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default ViewDistributedCoupon;
