import React, { useEffect, useState } from "react";

const Timer = ({ timeLeft }) => {
  const [countDown, setCountDown] = useState({});
  useEffect(() => {
    const timer = async () => {
      // let timeLeft = Math.floor(1684199924);
      const total = (await timeLeft) - Date.parse(new Date()) / 1000;
      const seconds = await Math.floor(total % 60);
      const minutes = await Math.floor((total / 60) % 60);
      const hours = await Math.floor((total / 60 / 60) % 24);
      const days = await Math.floor(total / 86400);
      // console.log(
      //   "total",
      //   total,
      //   "hours",
      //   hours,
      //   "minutes",
      //   minutes,
      //   "seconds",
      //   seconds
      // );
      if (total == 0) {
        clearInterval(interval);
      }
      return { total, days, hours, minutes, seconds };
    };
    let interval = setInterval(async () => {
      setCountDown(await timer());
    }, 1000);
  }, [timeLeft]);

  // console.log(countDown, timeLeft);
  return (
    <>
      {countDown?.total == undefined ? (
        "Loading..."
      ) : countDown.total > 0 ? (
        <>
          {countDown.days > 0 ? countDown.days : "00"}{" "}
          <span className="p-0">Days</span>{" "}
          {countDown.hours > 0 ? countDown.hours : "00"}{" "}
          <span className="p-0">Hours</span>{" "}
          {countDown.minutes > 0 ? countDown.minutes : "00"}{" "}
          <span className="p-0">Minutes</span>{" "}
          {countDown.seconds > 0 ? countDown.seconds : "00"}{" "}
          <span className="p-0">Seconds</span>{" "}
        </>
      ) : (
        <span>Time Over</span>
      )}
    </>
  );
};

export default Timer;
