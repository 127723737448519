import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// import { getSeaDropInstance } from "../helpers/functions";
// import getContractAddresses from "../contractData/address";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { storeDirNFTImage, storeDirNFTMetadata } from "../config/storage";
import { useNavigate } from "react-router-dom";

const CreateFolderIPFS = () => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [files, setFiles] = useState([]);
  const [ipfsMetadata, setIPFSMetadata] = useState({
    CID: "",
    Metadata: "",
    baseURI: "",
  });
  const navigate = useNavigate();

  const [copy, setCopy] = useState(false);
  const [loading, setloading] = useState(false);
  console.log(name, desc, files);
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);

  console.log("file", files.length);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    let imageFile = [];
    for (let i = 0; i < files.length; i++) {
      await imageFile.push(new File([files[i]], `${i + 1}.png`));
    }
    let imageCID = await storeDirNFTImage(imageFile);
    console.log(imageCID);
    if (imageCID) {
      let jsonFile = [];  
      for (let i = 0; i < files.length; i++) {
        await jsonFile.push(
          new File(
            [
              JSON.stringify({
                name: `${name} Collection #${i + 1}`,
                description: `${desc} #${i + 1}`,
                image: `ipfs://${imageCID}/${i + 1}.png`,
                edition: i + 1,
              }),
            ],
            `${i + 1}.json`
          )
        );
      }
      let NFTMetadata = await storeDirNFTMetadata(jsonFile);
      console.log("NFTMetadata", NFTMetadata);
      setIPFSMetadata({
        CID: NFTMetadata,
        Metadata: `https://ipfs.io/ipfs/${NFTMetadata}/`,
        baseURI: `ipfs/${NFTMetadata}/`,
      });
    }
    setloading(false);
    // }
  };

  // const handleChange = async (e) => {};
  // const handleMintPublic = async () => {};
  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-3  text-start">
          <h3 className="my-4">Create Folder IPFS</h3>
          <div className="row">
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="cost">NFT Name:</label>
                <input
                  className="form-control"
                  placeholder="Enter NFT Name here..."
                  id="nftName"
                  type={"text"}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="validity">NFT Description:</label>
                <input
                  className="form-control"
                  placeholder="Enter NFT Description here..."
                  id="nftDesc"
                  type={"text"}
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="no of coupon">Import Files:</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setFiles(e.target.files)}
                  multiple
                />
              </div>
            </div>
          </div>

          <div className="">
            <button
              type="button"
              className="btn btnBackground px-5 fw-bold btn-lg"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "CREATE IPFS"
              )}
            </button>
          </div>
          {ipfsMetadata?.CID ? (
            <div className="my-5">
              <div className=" text-start fs-5 d-flex justify-content-between pe-4">
                <span>
                  <span className="fw-bold">CID: </span> {ipfsMetadata.CID}
                </span>
                <CopyToClipboard
                  text={ipfsMetadata.CID}
                  onCopy={() => setCopy(ipfsMetadata.CID)}
                >
                  <span
                    className={`btn ${
                      copy == ipfsMetadata.CID ? "btn-success" : "btn-info"
                    }`}
                  >
                    {copy == ipfsMetadata.CID ? "Copied" : "Copy to clipboard"}
                  </span>
                </CopyToClipboard>
                {/* <span className="btn btn-success">copy</span> */}
              </div>{" "}
              <hr />
              <div className=" text-start fs-5 d-flex justify-content-between pe-4">
                <span>
                  <span className="fw-bold">Metadata: </span>
                  {ipfsMetadata.Metadata}
                </span>
                <CopyToClipboard
                  text={ipfsMetadata.Metadata}
                  onCopy={() => setCopy(ipfsMetadata.Metadata)}
                >
                  <span
                    className={`btn ${
                      copy == ipfsMetadata.Metadata ? "btn-success" : "btn-info"
                    }`}
                  >
                    {copy == ipfsMetadata.Metadata
                      ? "Copied"
                      : "Copy to clipboard"}
                  </span>
                </CopyToClipboard>
                {/* <span className="btn btn-success">copy</span> */}
              </div>{" "}
              <hr />
              <div className=" text-start fs-5 d-flex justify-content-between pe-4">
                <span>
                  <span className="fw-bold">BaseURI: </span>{" "}
                  {ipfsMetadata.baseURI}
                </span>
                <CopyToClipboard
                  text={ipfsMetadata.baseURI}
                  onCopy={() => setCopy(ipfsMetadata.baseURI)}
                >
                  <span
                    className={`btn ${
                      copy == ipfsMetadata.baseURI ? "btn-success" : "btn-info"
                    }`}
                  >
                    {copy == ipfsMetadata.baseURI
                      ? "Copied"
                      : "Copy to clipboard"}
                  </span>
                </CopyToClipboard>
                {/* <span className="btn btn-success">copy</span> */}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        navigate("/login")
      )}
    </>
  );
};

export default CreateFolderIPFS;
