import React from "react";
import { useState } from "react";
import { useEffect } from "react";
// import { getSeaDropInstance } from "../helpers/functions";
import getContractAddresses from "../contractData/address";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeDirNFTImage, storeDirNFTMetadata } from "../config/storage";
import { services } from "../services";
import { useNavigate } from "react-router-dom";
import DropSchedulepopUp from "../modal/DropSchedulepopUp";
import { useLocation } from "react-router-dom";
import PresalepopUp from "../modal/PresalepopUp";
import ERC721DropABI from "../contractData/ERC721SeaDrop.json";
import {
  TimeStampToDateString,
  getERC721SeaDropInstance,
} from "../helpers/functions";
import { parseBalanceMap } from "./SeaDrop/seadropmerkle";
import { web3 } from "../web3";
import { byteCode } from "../contractData/ERC721ByteCode";
import useWeb3AccountData from "../helpers/customHooks";
const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const CreateCollection = () => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const { ERC721SeaDropContract } = getContractAddresses();
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [files, setFiles] = useState([]);
  const [noOfItems, setNoOfItems] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [formattedTime, setFormattedTime] = useState();
  const [preSaleData, setPreSaleData] = useState([]);
  const [erc721Address, setERC721Address] = useState("");
  const [publicDrop, setPublicDrop] = useState({
    name: "Public Drop",
    type: "public",
    rootHash: "",
    allowList: "",
    mintPrice: "1000000000000000",
    maxTotalMintableByWallet: 3,
    startTime: 1687868562,
    endTime: 0,
    dropStageIndex: "",
    maxTokenSupplyForStage: "",
    feeBps: "1000",
    restrictFeeRecipients: true,
  });
  const [ipfsMetadata, setIPFSMetadata] = useState({
    CID: "",
    Metadata: "",
    baseURI: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  console.log("dataaaa", data);
  const [copy, setCopy] = useState(false);
  const [loading, setloading] = useState(false);
  const updatePreSaleData = (newSaleData) => {
    // let prev = preSaleData
    setPreSaleData([...preSaleData, newSaleData]);
    // setFormattedTime(
    //   startTimeSet(newSaleData?.days, newSaleData?.hours, newSaleData?.mins)
    // );
    console.log("thus", preSaleData);
  };
  console.log(
    ERC721SeaDropContract,
    "ERC721SeaDropContract",
    name,
    desc,
    files,
    startTime,
    endTime
  );
  useEffect(() => {
    setFormattedTime(startTimeSet(data?.days, data?.hours, data?.mins));
  }, [data, startTime]);

  function startTimeSet(daysToAdd, hoursToAdd, minutesToAdd) {
    var currentTime = new Date(startTime * 1000);
    console.log("currentTime: ", currentTime);
    currentTime.setDate(currentTime.getDate() + +daysToAdd);
    currentTime.setHours(currentTime.getHours() + +hoursToAdd);
    currentTime.setMinutes(currentTime.getMinutes() + +minutesToAdd);
    var formattedTime = currentTime.valueOf() / 1000;
    console.log("New Time: ", currentTime, "formattedTime", formattedTime);
    return formattedTime;
  }
  //
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);

  const deployContract = async () => {
    const curr_abi = ERC721DropABI;
    let deploy_contract = new web3.eth.Contract(curr_abi);
    let _seaDropContract = getContractAddresses().seaDropContract;
    let _name = name;
    let _symbol = "test";
    let args = [_name, _symbol, [_seaDropContract]];
    console.log(args);
    return await deploy_contract
      .deploy({
        data: byteCode,
        arguments: args,
      })
      .send({
        from: address,
      })
      .on("error", (error) => {
        toast.error("Contract deployment failed.");
      })
      .on("transactionHash", (transactionHash) => {})
      .on("receipt", (receipt) => {
        toast.success("Your contract deployed!");
        setERC721Address(receipt.contractAddress);
      });
  };

  console.log("file", files.length);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    let deployedContract = await deployContract();
    if (!deployedContract._address) return toast.error("Please try again");
    // console.log("result ", result);
    let imageFile = [];
    for (let i = 0; i < files.length; i++) {
      await imageFile.push(new File([files[i]], `${i + 1}.png`));
    }
    let imageCID = await storeDirNFTImage(imageFile);
    console.log(imageCID);
    if (imageCID) {
      let jsonFile = [];
      for (let i = 0; i < files.length; i++) {
        await jsonFile.push(
          new File(
            [
              JSON.stringify({
                name: `${name} #${i + 1}`,
                description: `${desc} #${i + 1}`,
                image: `ipfs://${imageCID}/${i + 1}.png`,
                edition: i + 1,
              }),
            ],
            `${i + 1}.json`
          )
        );
      }
      let NFTMetadata = await storeDirNFTMetadata(jsonFile);
      console.log("NFTMetadata", NFTMetadata);
      setIPFSMetadata({
        CID: NFTMetadata,
        Metadata: `https://ipfs.io/ipfs/${NFTMetadata}/`,
        baseURI: `ipfs/${NFTMetadata}/`,
      });
      //////////////setting stage drop index///////////////
      let checkPresale = preSaleData;
      let prevLastInedx = 0;
      for (let i = 0; i < checkPresale.length; i++) {
        // if (i === checkPresale.length - 1) break;
        if (i === 0) {
          checkPresale[i].dropStageIndex = 1;
          prevLastInedx = checkPresale[i].maxTokenSupplyForStage;
        } else {
          checkPresale[i].dropStageIndex = prevLastInedx;
          prevLastInedx = checkPresale[i].maxTokenSupplyForStage;
        }
        if (checkPresale[i].type === "allowed") {
          let {
            mintPrice,
            maxTotalMintableByWallet,
            startTime,
            endTime,
            dropStageIndex,
            maxTokenSupplyForStage,
            feeBps,
            restrictFeeRecipients,
          } = checkPresale[i];
          let merkleval = parseBalanceMap(checkPresale[i].allowList, [
            mintPrice,
            maxTotalMintableByWallet,
            startTime,
            endTime,
            dropStageIndex,
            maxTokenSupplyForStage,
            feeBps,
            restrictFeeRecipients,
          ]);
          console.log(merkleval);
          checkPresale[i].rootHash = merkleval.merkleRoot;
        }
      }
      let _publicDrop = publicDrop;
      _publicDrop.startTime = _publicDrop.startTime
        ? _publicDrop.startTime
        : checkPresale[checkPresale.length - 1].endTime;
      _publicDrop.endTime = endTime;
      checkPresale.push(publicDrop);
      let res = await services.post(`/collection/create/`, {
        erc721Address: deployedContract._address,
        feeRecipient: [address],
        name: name,
        discription: desc,
        ipfsUrl: `https://ipfs.io/ipfs/${NFTMetadata}/`,
        baseIpfsUrl: `ipfs/${NFTMetadata}/`,
        contractAddress: ERC721SeaDropContract,
        totalNfts: files.length,
        nftSold: 0,
        startTime: startTime,
        endTime: endTime,
        dropStageSchedule: checkPresale,
      });
      console.log("res", res);
      configureMultiStages(res.data.data, deployedContract._address);
      console.log("res", res);
      if (res.status == 200) {
        setloading(false);
        toast.success("Transaction Successful!");
      } else {
        toast.error("Transaction Failed!");
        setloading(false);
      }
    }
    setloading(false);
    // }
  };
  const configureMultiStages = async (data, erc721Address) => {
    let dropStageSchedule = data.dropStageSchedule;
    let maxSupply = data.totalNfts, //1000, //
      baseURI = data.baseIpfsUrl, //"this base", //
      contractURI = data.ipfsUrl, //"contractURI", //
      seaDropImpl = getContractAddresses().seaDropContract;
    let lI = dropStageSchedule.length - 1;
    let publicDrop = [
        dropStageSchedule[lI].mintPrice,
        dropStageSchedule[lI].startTime,
        dropStageSchedule[lI].endTime,
        dropStageSchedule[lI].maxTotalMintableByWallet,
        dropStageSchedule[lI].feeBps,
        dropStageSchedule[lI].restrictFeeRecipients,
      ],
      // let publicDrop = ["1000000000", 1681730520, 1699015920, 3, 1000, true],
      dropURI = data.ipfsUrl, //"dataipfs", //
      // allowListData = [
      //   "0xd44fec381892e6c49b756000d0ea0745eb3eceb4e380095d41ea5755e3bcf97a",
      //   [],
      //   "",
      // ], //
      allowListData = [dropStageSchedule[0].rootHash, [], ""],
      creatorPayoutAddress = address,
      provenanceHash =
        "0x0000000000000000000000000000000000000000000000000000000000000000",
      allowedFeeRecipients = [address],
      disallowedFeeRecipients = [],
      allowedPayers = [], //address
      disallowedPayers = [], //address
      // Token-gated
      tokenGatedAllowedNftTokens = [], //address
      tokenGatedDropStages = [],
      disallowedTokenGatedAllowedNftTokens = [], //address
      // Server-signed
      signers = [], //address
      signedMintValidationParams = [],
      disallowedSigners = []; //address
    let MulticonfigTuple = [
      maxSupply,
      baseURI,
      contractURI,
      seaDropImpl,
      publicDrop,
      dropURI,
      allowListData,
      creatorPayoutAddress,
      provenanceHash,
      allowedFeeRecipients,
      disallowedFeeRecipients,
      allowedPayers,
      disallowedPayers,
      tokenGatedAllowedNftTokens,
      tokenGatedDropStages,
      disallowedTokenGatedAllowedNftTokens,
      signers,
      signedMintValidationParams,
      disallowedSigners,
    ];
    console.log("this is multiconfig tuple", MulticonfigTuple);
    const erc721SeaDropInstance = await getERC721SeaDropInstance(erc721Address);
    await erc721SeaDropInstance.methods
      .multiConfigure(MulticonfigTuple)
      .send({ from: address })
      .on("transactionHash", (hash) => {
        toast.info("Transaction is Processing...");
      })
      .on("receipt", (receipt) => {
        console.log("on receipt ", receipt);
        toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
        // setLoading(false);
      })
      .on("error", (error) => {
        console.log("on error ", error);
        toast.error("Transaction Failed!");
        // setLoading(false);
      });
  };

  // const handleChange = async (e) => {};
  // const handleMintPublic = async () => {};

  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-5  text-start">
          <h3>Create Collection</h3>
          <div className="form-group mt-2">
            <label for="cost">NFT Name:</label>
            <input
              className="form-control"
              placeholder="Enter NFT Name here..."
              id="nftName"
              type={"text"}
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label for="validity">NFT Description:</label>
            <input
              className="form-control"
              placeholder="Enter NFT Description here..."
              id="nftDesc"
              type={"text"}
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              required
            />
          </div>

          <div className="form-group mt-2">
            <label for="no of coupon">Import Files:</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => setFiles(e.target.files)}
              multiple
            />
          </div>
          <div className="form-group mt-2">
            <label for="noOfItems">Number of items:</label>
            <input
              disabled
              className="form-control"
              placeholder="Enter Number of items here..."
              id="noOfItems"
              name="noOfItems"
              type={"text"}
              // onChange={handleChange}
              value={files?.length}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label for="validity">Start Time:</label>
            <input
              className="form-control"
              placeholder="Enter Start Time here..."
              id="startTime"
              type={"datetime-local"}
              onChange={(e) =>
                setStartTime(new Date(e.target.value).valueOf() / 1000)
              }
              required
            />
          </div>
          <div className="form-group mt-2">
            <label for="validity">End Time:</label>
            <input
              className="form-control"
              placeholder="Enter Start Time here..."
              id="endTime"
              type={"datetime-local"}
              onChange={(e) =>
                setEndTime(new Date(e.target.value).valueOf() / 1000)
              }
              required
            />
          </div>
          <div className="form-group mt-2">
            <button type="button" className="border-0">
              <PresalepopUp
                updatePreSaleData={updatePreSaleData}
                startTime={startTime}
                preSaleData={preSaleData}
              />
            </button>
            <span>Add a presale</span>
          </div>
          {preSaleData.map((ele, key) => (
            <div className="form-group mt-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{ele.name}</h5>
                  <p className="card-text">
                    Duration:{TimeStampToDateString(ele.startTime)} -
                    {TimeStampToDateString(ele.endTime)}
                  </p>
                  <p>
                    {ele.mintPrice}
                    {""}BNB
                  </p>
                </div>
              </div>
            </div>
          ))}
          <div className="form-group mt-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{publicDrop.name}</h5>
                <p className="card-text">
                  Duration:{TimeStampToDateString(publicDrop.startTime)} -
                  {TimeStampToDateString(publicDrop.endTime)}
                </p>
                <p>
                  {publicDrop.mintPrice}
                  {""}BNB
                </p>
              </div>
            </div>
          </div>
          {/* <div className="form-group mt-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{"tt"}</h5>
                <p className="card-text">Duration:-{8}</p>
                <p>
                  {data.price}
                  {""}BNB
                </p>
              </div>
            </div>
          </div> */}
          {/* {noOfItems !== "" ? (
            <div className="form-group mt-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{data.name}</h5>
                  <p className="card-text">StartDate:{startDate}</p>
                  <p className="card-text">noOfItems:{noOfItems}</p>
                  <p className="card-text">Duration:-{data.price}</p>
                  <p>
                    Price:-{data.price}
                    {""}BNB
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          <br />
          <button type="button" onClick={() => configureMultiStages()}>
            configure
          </button>
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-primary  btn-lg"
              onClick={handleSubmit}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "Create Collection"
              )}{" "}
            </button>
          </div>
          {/* {ipfsMetadata?.CID ? (
            <div className="my-5">
              <div className=" text-start fs-5 d-flex justify-content-between pe-4">
                <span>
                  <span className="fw-bold">CID: </span> {ipfsMetadata.CID}
                </span>
                <CopyToClipboard
                  text={ipfsMetadata.CID}
                  onCopy={() => setCopy(ipfsMetadata.CID)}
                >
                  <span
                    className={`btn ${
                      copy == ipfsMetadata.CID ? "btn-success" : "btn-info"
                    }`}
                  >
                    {copy == ipfsMetadata.CID ? "Copied" : "Copy to clipboard"}
                  </span>
                </CopyToClipboard>
              </div>{" "}
              <hr />
              <div className=" text-start fs-5 d-flex justify-content-between pe-4">
                <span>
                  <span className="fw-bold">Metadata: </span>{" "}
                  {ipfsMetadata.Metadata}
                </span>
                <CopyToClipboard
                  text={ipfsMetadata.Metadata}
                  onCopy={() => setCopy(ipfsMetadata.Metadata)}
                >
                  <span
                    className={`btn ${
                      copy == ipfsMetadata.Metadata ? "btn-success" : "btn-info"
                    }`}
                  >
                    {copy == ipfsMetadata.Metadata
                      ? "Copied"
                      : "Copy to clipboard"}
                  </span>
                </CopyToClipboard>
              </div>{" "}
              <hr />
              <div className=" text-start fs-5 d-flex justify-content-between pe-4">
                <span>
                  <span className="fw-bold">BaseURI: </span>{" "}
                  {ipfsMetadata.baseURI}
                </span>
                <CopyToClipboard
                  text={ipfsMetadata.baseURI}
                  onCopy={() => setCopy(ipfsMetadata.baseURI)}
                >
                  <span
                    className={`btn ${
                      copy == ipfsMetadata.baseURI ? "btn-success" : "btn-info"
                    }`}
                  >
                    {copy == ipfsMetadata.baseURI
                      ? "Copied"
                      : "Copy to clipboard"}
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default CreateCollection;
