import React from "react";
import video from "../assets/Vid.mp4"
import "../App.css"
import img from "../assets/airdrop.png"

const Dashboard = () => {
  return (
  <>
    {/* <div className="container p-5">
      <h2>Welcome to ARC Dashboard</h2>
    </div> */}
    
    <div className='video-container'>
      <video autoPlay loop muted> 
      <source src={video} type = "video/mp4"/>
      </video>
      <div className='video-textbox'>
    <img src={img} className="w-50" alt="..." />
      <h1 className='title1'>Welcome <br/>
      To ARC Dashboard</h1>
      </div>
    </div>
  </>
  );
};

export default Dashboard;
