import React, { useEffect } from "react";
import { useState } from "react";
import {
  getMerkelClaimTokenInstance,
  getMDTTokenInstance,
} from "../helpers/functions";
import mdttokenAbi from "../contractData/token.json";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
// import { web3 } from "../web3.js";
import { services } from "../services";
import Loader from "../assets/loading_arc.gif";
import getContractAddresses from "../contractData/address";
import { parseBalanceMap } from "./testMerkle/parse-balance-map";
import addresses from "./testMerkle/Address.json";
import { useNavigate } from "react-router-dom";
import Template from "../csvfileTemplate/merkleAirdrop.csv";
import useWeb3AccountData from "../helpers/customHooks";
import Web3 from "web3";
// import { createRootHash } from "../helpers/Merkle.js";
const keccak256 = require("keccak256");
const { MerkleTree } = require("merkletreejs");
// const web3 = require("web3");

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const CreateMerkleDrop = () => {

  const [address,isLoading,isConnected]=useWeb3AccountData()

  const { merkleClaimContract } = getContractAddresses();
  const fileReader = new FileReader();
  const [paymentToken, setPaymentToken] = useState(true);
  const [users, setUsers] = useState([]);
  const [mdtAllowance, setMdtAllowance] = useState(0);
  const [claimDetails, setClaimDetails] = useState({
    name: "",
    tokenName: "",
    tokenSymbol: "",
    tokenAddress: "",
    csvFile: "",
    root: "",
    users: {},
    starttime: "",
    endtime: "",
    tokenAmount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getTokenDetails = async () => {
      if (claimDetails.tokenAddress) {
        let obj = {};
        const tokenInstance = await new Web3.eth.Contract(
          mdttokenAbi,
          claimDetails.tokenAddress
        );
        console.log("tokenInstance", tokenInstance);
        await tokenInstance.methods
          .name()
          .call()
          .then((tokenName) => {
            obj.tokenName = tokenName;
          })
          .catch((error) => {
            obj.tokenName = "Invalid token";
          });
        await tokenInstance.methods
          .symbol()
          .call()
          .then((tokenSymbol) => {
            obj.tokenSymbol = tokenSymbol;
          })
          .catch((error) => {
            obj.tokenSymbol = "Invalid token";
          });
        console.log("obj", obj);
        await setClaimDetails({
          ...claimDetails,
          ...obj,
        });
      }
    };
    getTokenDetails();
  }, [claimDetails.tokenAddress]);

  const handleChange = (e) => {
    if (e.target.name == "paymentToken") {
      setPaymentToken(e.target.value == "true" ? true : false);
    } else if (e.target.name == "csvFile") {
      // setCouponImage(e.target.files[0]);
      setClaimDetails({ ...claimDetails, [e.target.name]: e.target.files[0] });
    } else {
      setClaimDetails({ ...claimDetails, [e.target.name]: e.target.value });
    }
    // setCouponDetails({ ...couponDetails, [e.target.name]: e.target.value });
  };

  const csvFileToJson = async (string) => {
    let addressObj = {};
    let newObj = {};
    var array = await string.toString().split("\r\n");
    //  console.log(array); here we are getting the first rows which is our header rows to convert it into keys we are logging it here
    var data = [];
    // console.log(data);
    for (const r of array) {
      // console.log(r);
      let row = await r.toString().split(",");
      data.push(row);
    }
    console.log(data);
    var heading = await data[0];
    // console.log(heading); to get the column headers which will act as key
    var ans_array = [];
    // console.log(ans_array);
    for (var i = 1; i < data.length; i++) {
      var row = await data[i];
      var obj = {};
      for (var j = 0; j < heading.length; j++) {
        if (!row[j]) {
          row[j] = "NA";
        }
        // console.log(row[j].toString())
        obj[heading[j].replaceAll(" ", "_")] = row[j]
          .toString()
          .replaceAll(" ", "_");
      }
      if (obj.walletAddress !== "NA") {
        addressObj[obj.walletAddress] = obj.eTokens;
        ans_array.push(obj);
      }
    }

    for (let index = 0; index < ans_array.length; index++) {
      newObj[ans_array[index].walletAddress] = {
        amount: ans_array[index].eTokens,
        index: index,
      };
    }
    await setUsers(newObj);
    // setTokenAmount(total_amount);
    return addressObj;
  };

  const generateRootHash = async () => {
    if (claimDetails.csvFile == "") toast.error("Please Choose CSV File...");
    else {
      setGenerateLoading(true);
      fileReader.onload = async function (event) {
        const text = event.target.result;
        // let jsonArray = await csvFileToArray(text);
        let jsonArray = await csvFileToJson(text);
        console.log(jsonArray);
        let hash = await parseBalanceMap(jsonArray);
        setClaimDetails({
          ...claimDetails,
          root: hash.merkleRoot,
          users: jsonArray,
          tokenAmount: Web3.utils.hexToNumberString(hash.tokenTotal),
        });
        console.log(
          "hash",
          hash,
          "web3.utils.hexToNumber(hash.tokenTotal)",
          Web3.utils.hexToNumberString(hash.tokenTotal)
        );
        await setGenerateLoading(false);
      };

      fileReader.readAsText(claimDetails.csvFile);
    }
  };

  const checkTokenApproval = async (tokenAddress) => {
    const tokenInstance = await new Web3.eth.Contract(
      mdttokenAbi,
      tokenAddress
    );
    let tokenName = await tokenInstance.methods.name().call();
    let tokenSymbol = await tokenInstance.methods.symbol().call();
    console.log("tokenName", tokenName, "tokenSymbol", tokenSymbol);
    let tokenAllowance = await tokenInstance.methods
      .allowance(address, merkleClaimContract)
      .call();
    if (+claimDetails.tokenAmount > +tokenAllowance) {
      await tokenInstance.methods
        .approve(merkleClaimContract, claimDetails.tokenAmount)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log("on hash ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", (receipt) => {
          console.log("on receipt ", receipt);
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          createAirdrop();
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoading(false);
        });
    } else {
      createAirdrop();
    }
  };

  // const approveFunction = async () => {
  //   const mdtContractInstance = await getMDTTokenInstance();
  //   console.log(
  //     +claimDetails.tokenAmount > +mdtAllowance,
  //     +claimDetails.tokenAmount,
  //     +mdtAllowance
  //   );
  //   if (+claimDetails.tokenAmount > +mdtAllowance) {
  //     await mdtContractInstance.methods
  //       .approve(merkleClaimContract, claimDetails.tokenAmount)
  //       .send({ from: address })
  //       .on("transactionHash", (hash) => {
  //         console.log("on hash ", hash);
  //         toast.info("Transaction is Processing...");
  //       })
  //       .on("receipt", (receipt) => {
  //         console.log("on receipt ", receipt);
  //         toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
  //         // submitCoupon();
  //         redeemCoupon();
  //       })
  //       .on("error", (error) => {
  //         console.log("on error ", error);
  //         toast.error("Transaction Failed!");
  //         setLoading(false);
  //       });
  //   } else {
  //     redeemCoupon();
  //     // submitCoupon();
  //   }
  // };

  const redeemCoupon = async () => {
    if (claimDetails.csvFile != "" && claimDetails.root == "")
      toast.error("Please Generate Root hash first!");
    else if (
      claimDetails.tokenAddress == "" ||
      claimDetails.csvFile == "" ||
      claimDetails.root == "" ||
      claimDetails.starttime == "" ||
      claimDetails.endtime == "" ||
      claimDetails.tokenAmount == 0
    )
      toast.error("All fields are required");
    else if (
      Math.floor(Date.now() / 1000) >
      new Date(claimDetails.starttime).valueOf() / 1000
    )
      toast.error("Start time should be greater than current time");
    else if (
      Math.floor(Date.now() / 1000) >
      new Date(claimDetails.endtime).valueOf() / 1000
    )
      toast.error("End time must be greater than current time");
    else if (
      new Date(claimDetails.starttime).valueOf() / 1000 >
      new Date(claimDetails.endtime).valueOf() / 1000
    )
      toast.error("End time must be greater than start time");
    else if (!address) toast.error("Please Connect Wallet First");
    else {
      setLoading(true);
      checkTokenApproval(claimDetails.tokenAddress);
      // createAirdrop();
    }
  };

  const createAirdrop = async () => {
    const merkleClaimContractInstance = await getMerkelClaimTokenInstance();
    await merkleClaimContractInstance.methods
      .createAirDrop(
        claimDetails.tokenAddress,
        claimDetails.tokenAmount,
        claimDetails.root,
        new Date(claimDetails.starttime).valueOf() / 1000,
        new Date(claimDetails.endtime).valueOf() / 1000,
        paymentToken
      )
      .send(
        paymentToken
          ? { from: address }
          : { from: address, value: "10000000000" }
      )
      .on("transactionHash", (hash) => {
        console.log("transaction hash : ", hash);
        toast.info("Transaction is Processing...");
      })
      .on("receipt", async (receipt) => {
        console.log("on receipt ", receipt);
        toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
        let res = await services.post(`/claim/addClaim/`, {
          name: claimDetails?.name,
          token_name: claimDetails?.tokenName,
          symbol: claimDetails?.tokenSymbol,
          tokenAddress: claimDetails?.tokenAddress,
          airdropAdress: merkleClaimContract,
          rootHash: claimDetails?.root,
          users: claimDetails?.users,
          startTime: new Date(claimDetails?.starttime).valueOf() / 1000,
          endTime: new Date(claimDetails?.endtime).valueOf() / 1000,
          tokenPayment: paymentToken,
        });
        console.log("res", res);
        if (res.status == 201) {
          setLoading(false);
          toast.success("Transaction Successful!");
        } else {
          toast.error("Transaction Failed!");
          setLoading(false);
        }
      })
      .on("error", (error) => {
        console.log("on error ", error);
        toast.error("Transaction Failed!");
        setLoading(false);
      });
  };

  console.log("claimDetails", claimDetails, "users", users);
  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-3">
          <h3 className="my-4">Create Merkle Airdrop</h3>
          <div className="row">
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="cost">Name:</label>
                <input
                  className="form-control"
                  placeholder="Enter Name here..."
                  id="name"
                  type={"text"}
                  name="name"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group ">
                <label for="cost">Token Address:</label>
                <input
                  className="form-control"
                  placeholder="Enter Token Address here..."
                  id="tokenAddress"
                  type={"text"}
                  name="tokenAddress"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="cost">Token Name:</label>
                <input
                  className="form-control"
                  placeholder="Enter Token Name here..."
                  id="tokenName"
                  type={"text"}
                  name="tokenName"
                  value={claimDetails.tokenName}
                  onChange={handleChange}
                  required
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="cost">Token Symbol:</label>
                <input
                  className="form-control"
                  placeholder="Enter Token Symbol here..."
                  id="tokenSymbol"
                  type={"text"}
                  name="tokenSymbol"
                  value={claimDetails.tokenSymbol}
                  onChange={handleChange}
                  required
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-12 pe-5 pb-4">
              <div className="form-group">
                <label
                  for="validity"
                  className="d-flex justify-content-between"
                >
                  Upload CSV File:
                  <span>
                    <a
                      href={Template}
                      download="MerkleAirdrop_CSV_Template"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Template
                    </a>
                  </span>
                </label>
                <div className="input-group">
                  <input
                    className="form-control"
                    placeholder="Choose CSV File"
                    id="csvFile"
                    type={"file"}
                    accept=".csv"
                    name="csvFile"
                    onChange={handleChange}
                    required
                  />
                  <button
                    className="btn backgroundBtn"
                    type="button"
                    id="button-addon2"
                    onClick={generateRootHash}
                    disabled={generateLoading}
                  >
                    {generateLoading ? "Loading..." : "Generate Root hash"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group ">
                <label for="no of coupon">Root hash:</label>
                <input
                  className="form-control"
                  placeholder="Generate root hash here..."
                  id="rootHash"
                  type={"text"}
                  name="root"
                  value={claimDetails.root}
                  onChange={handleChange}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Total Amount(in Ether):</label>
                <input
                  className="form-control"
                  placeholder="Total Amount"
                  id="tokenAmount"
                  type={"number"}
                  name="tokenAmount"
                  value={Web3.utils.fromWei(`${claimDetails?.tokenAmount}`,'ether')}
                  onChange={handleChange}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Start time(timestamp):</label>
                <input
                  className="form-control"
                  placeholder="Enter Start time"
                  id="starttime"
                  type="datetime-local"
                  name="starttime"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group ">
                <label for="no of coupon">End time(timestamp):</label>
                <input
                  className="form-control"
                  placeholder="Enter End time"
                  id="endtime"
                  type="datetime-local"
                  name="endtime"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4 ">
              <div className="">
                <div className="my-1" for="">
                  Payment in Token <ReactTooltip id="tooltip" />
                </div>
                <div
                  className="form-check form-check-inline"
                  data-for="tooltip"
                  // data-tooltip-content="BNB"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymentToken"
                    id="inlineRadio1"
                    value={true}
                    onChange={handleChange}
                    checked={paymentToken}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    true
                  </label>
                </div>
                <div
                  className="form-check form-check-inline ms-3"
                  data-for="tooltip"
                  // data-tooltip-content="MDT"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymentToken"
                    id="inlineRadio2"
                    value={false}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    false
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="m-0">
            <button
              type="button"
              className="btn  btnBackground px-5 fw-bold btn-lg"
              onClick={redeemCoupon}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "CREATE MERKLE AIRDROP"
              )}{" "}
            </button>
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default CreateMerkleDrop;
