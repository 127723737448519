import React from "react";
import "../App.css";

export const Loader1 = () => {
  return (
    <>
      <div class="spinner" >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};
