import React, { useEffect, useState } from 'react'
// import TrustWallet from "./pages/trusrwallet";
// import LoginpopUp from "./modal/LoginpopUp";
import Wallet from './pages/Wallet'
import Avatar from './pages/Avatar'
import Logo from './assets/logo-png.png'
import { init, useConnectWallet, useWallets } from '@web3-onboard/react'
import Web3 from 'web3'
import { setWeb3Provider } from './web3'
import { chains, wallets } from './helpers/walletConfigs'
import { initWeb3Onboardd } from './helpers/newWalletConfigs'
// import WalletTest from "./components/wallettest";
const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070'
// init({
//   connect: {
//     autoConnectAllPreviousWallet: true,
//     autoConnectLastWallet: true,
//   },
//   apiKey,
//   wallets: wallets,
//   chains: chains,
// });
const Header = props => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const [ethersProvider, setProvider] = useState()
  const connectedWallets = useWallets()
  const [web3Onboard, setWeb3Onboard] = useState()

  useEffect(() => {
    console.log('get connected walletss', connectedWallets)
    if (wallet?.provider) {
      setWeb3Provider(wallet.provider)
      setProvider(new Web3(wallet.provider))
    }
  }, [wallet])
  // useEffect(() => {
  //   connect();
  // }, []);
  let authToken = localStorage.getItem('arcAdminAuthToken')
  console.log(ethersProvider)
  console.log('this is wallet', wallet)
  return (
    <>
      <nav
        className="navbar navbar-expand-lg "
        style={{ height: '10%', backgroundColor: '#191d26' }}
      >
        <div className="container-fluid pe-5">
          <a className="navbar-brand fs-1 fw-bold text-white ms-2" href="/">
            <img className="" alt="" src={Logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item d-flex">
                {/* <Wallet /> */}
                {/* <WalletTest /> */}
                {/* <button disabled={connecting} onClick={connect}>
                  Connect
                </button> */}
                <button
                  disabled={connecting}
                  onClick={() => (wallet ? disconnect(wallet) : connect())}
                >
                  {connecting ? 'connecting' : wallet ? '' : 'connect'}
                </button>
                {authToken ? <Avatar /> : null}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
