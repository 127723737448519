import React from "react";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { MDBSwitch } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { csvFileToJson } from "../helpers/functions";
import { parseBalanceMap } from "../components/SeaDrop/seadropmerkle";

const PresalepopUp = (props) => {
  const { updatePreSaleData, startTime, preSaleData } = props;
  const fileReader = new FileReader();
  const [isShow, setShowModal] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(true);
  const fileTypes = ["CSV"];
  const [file, setFile] = useState(null);
  const [times, setTimes] = useState({ days: 0, hr: 0, min: 0 });
  const [presaleFields, setPresaleFields] = useState({
    name: "",
    type: "",
    rootHash: "",
    allowList: "",
    mintPrice: "",
    maxTotalMintableByWallet: "",
    startTime: "",
    endTime: "",
    dropStageIndex: "",
    maxTokenSupplyForStage: "",
    feeBps: 0,
    restrictFeeRecipients: false,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPresaleFields({ ...presaleFields, [e.target.name]: e.target.value });
  };
  const handleAllowSwitch = (checked) => {
    setSwitch2(checked);
    setPresaleFields({
      ...presaleFields,
      type: checked ? "allowed" : "gated",
    });
    if (!checked) setFile(null);
  };
  const handleFileChange = (file) => {
    fileReader.onload = async function (event) {
      const text = event.target.result;
      let jsonArray = await csvFileToJson(text);
      // setPresaleFields({ ...presaleFields, allowList: jsonArray });
      // const res = parseBalanceMap(jsonArray);
      setPresaleFields({
        ...presaleFields,
        // rootHash: res.merkleRoot,
        allowList: jsonArray,
        type: "allowed",
      });
    };
    fileReader.readAsText(file[0]);
    setFile(file);
  };
  const handleSubmit = () => {
    let presaleFieldsUpdated = presaleFields;
    if (preSaleData.length === 0) {
      presaleFieldsUpdated.startTime = startTime;
      presaleFieldsUpdated.endTime =
        startTime +
        (Number(times.days) * 86400 +
          Number(times.hr) * 3600 +
          Number(times.min) * 60);
    } else {
      presaleFieldsUpdated.startTime =
        preSaleData[preSaleData.length - 2].endTime;
      presaleFieldsUpdated.endTime =
        presaleFieldsUpdated.startTime +
        (Number(times.days) * 86400 +
          Number(times.hr) * 3600 +
          Number(times.min) * 60);
    }
    presaleFieldsUpdated.maxTokenSupplyForStage =
      presaleFields.maxTotalMintableByWallet *
      Object.keys(presaleFields.allowList).length;

    updatePreSaleData(presaleFieldsUpdated);
    setShowModal(!isShow);
  };
  return (
    <div className="rounded-9">
      <Button onClick={() => setShowModal(true)}>+</Button>
      <Modal className="rounded-9" show={isShow}>
        <Modal.Header closeButton onClick={() => setShowModal(false)}>
          <Modal.Title>Add a Presale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mt-2">
            <label className="text-dark" for="name">
              Name:
            </label>
            <input
              className="form-control"
              placeholder="Enter Name here..."
              id="name"
              name="name"
              type={"text"}
              onChange={handleChange}
              value={presaleFields.name}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label className="text-dark" for="mintPrice">
              Price:
            </label>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control"
                id="mintPrice"
                name="mintPrice"
                placeholder="0"
                onChange={handleChange}
                value={presaleFields.mintPrice}
                required
              />
              <div className="input-group-prepend">
                <div className="input-group-text text-dark">BNB</div>
              </div>
            </div>
          </div>
          <div className="form-group mt-2">
            <label className="text-dark" for="days">
              Durations:
            </label>
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control"
                id="days"
                name="days"
                placeholder="0"
                onChange={(e) => setTimes({ ...times, days: e.target.value })}
                value={times.days}
                required
              />
              <div className="input-group-prepend">
                <div className="input-group-text">days</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="hours"
                name="hours"
                placeholder="0"
                onChange={(e) => setTimes({ ...times, hr: e.target.value })}
                value={times.hr}
                required
              />
              <div className="input-group-prepend">
                <div className="input-group-text">hours</div>
              </div>
              <input
                type="text"
                className="form-control"
                id="mins"
                name="mins"
                placeholder="0"
                onChange={(e) => setTimes({ ...times, min: e.target.value })}
                value={times.min}
                required
              />
              <div className="input-group-prepend">
                <div className="input-group-text">mins</div>
              </div>
            </div>
          </div>
          <div className="form-group mt-2 d-flex flex-row justify-content-between">
            <label className="w-75 text-dark" for="maxTotalMintableByWallet">
              Pre-wallet mint limit (Optional):
            </label>
            <div className="input-group mb-2 w-25">
              <input
                disabled={!switch1 ? true : false}
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                name="maxTotalMintableByWallet"
                placeholder="0"
                onChange={handleChange}
                value={presaleFields.maxTotalMintableByWallet}
              />
              <div className="input-group-prepend m-2">
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  checked={switch1}
                  onChange={() => setSwitch1(!switch1)}
                />
              </div>
            </div>
          </div>
          <div className="form-group mt-2">
            <div className="d-flex justify-content-between">
              <label className="text-dark" for="validity">
                Allowlist
              </label>
              <MDBSwitch
                id="flexSwitchCheckDefault"
                checked={switch2}
                onChange={handleAllowSwitch}
              />
            </div>
            <p>
              Upload a list of specific wallet addresses that are allowed to
              mint during presale
            </p>
          </div>
          {switch2 ? (
            <div className="form-group mt-2">
              <FileUploader
                multiple={true}
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
              />
              <p>
                :-
                {file ? `File name: ${file[0].name}` : "no files uploaded yet"}
              </p>
            </div>
          ) : null}
        </Modal.Body>
        <button
          type="button"
          className="btn btn-primary btn-lg m-1"
          variant="danger"
          onClick={handleSubmit}
        >
          Done
        </button>
      </Modal>
    </div>
  );
};

export default PresalepopUp;
