import React from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import QRCode from "react-qr-code";

const ModalDialog = ({ id }) => {
  const [isShow, invokeModal] = useState(false);
  const initModal = () => {
    return invokeModal(!isShow);
  };
  localStorage.setItem("lazyNftId", id);
  //   console.log("QRCode_idd", id);
  return (
    <>
      <Button variant="btn btnQRBackground fw-bold" onClick={initModal}>
        SHOW QRCODE
      </Button>
      <Modal show={isShow}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title>Scan QrCode Here</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center rounded">
          <QRCode
            size={256}
            style={{
              height: "200px",
              maxWidth: "100%",
              //   width: "200px",
              marginTop: "10px",
              borderRadius: "15px",
            }}
            value={`https://arc.minddeft.com/redeemLazyNft/${id}`}
            // value={`http://localhost:3000/redeemLazyNft/${id}`}
            viewBox={`0 0 256 256`}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center mt-2 mb-1">
          <Button variant="danger" onClick={initModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalDialog;
