import { useState, useEffect, useCallback } from "react";
import { web3 } from "../web3";

const useWeb3AccountData = (url) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);
  const [status, setStatus] = useState({ isLoading: true, isConnected: false });
  const getAddress = useCallback(async (val) => {
    if (web3) {
      try {
        const responseData = await web3.eth.getAccounts();
        if (responseData.length) {
          setAddress(responseData[0]);
          setStatus({ isLoading: false, isConnected: true });
        } else {
          setAddress();
          setStatus({ isLoading: false, isConnected: false });
        }
      } catch {
        setAddress("");
        setStatus({ isLoading: false, isConnected: false });
      }
    }
  }, []);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(url);
    //     const json = await response.json();
    //     setData(json);
    //     setLoading(false);
    //   } catch (error) {
    //     setError(error);
    //     setLoading(false);
    //   }
    // };
    getAddress();
    // fetchData();
  }, []);
  return [address, loading, error];
};
export default useWeb3AccountData;
