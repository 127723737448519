import { web3 } from "../web3";
import contractAbi from "../contractData/abi.json";
import mdttokenAbi from "../contractData/token.json";
import airDropTokenAbi from "../contractData/airdrop.json";
import getContractAddresses from "../contractData/address";
import lazyTokenAbi from "../contractData/lazynft.json";
import merkleClaimTokenAbi from "../contractData/merkleClaim.json";
import distributedCouponAbi from "../contractData/distributedCoupon.json";
import sweepStakesABI from "../contractData/sweepstakes.json";
import metricNFTABI from "../contractData/metricNFT.json";
import seaDropABI from "../contractData/seaDrop.json";
import ERC721SeaDropABI from "../contractData/ERC721SeaDrop.json";
export async function getContractInstance() {
  const { couponContractAddress } = getContractAddresses();
  console.log(couponContractAddress);

  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        contractAbi,
        couponContractAddress
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getMDTTokenInstance() {
  const { MDTContract } = getContractAddresses();
  console.log(MDTContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        mdttokenAbi,
        MDTContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getAirDropTokenInstance() {
  const { airDropContract } = getContractAddresses();
  console.log(airDropContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        airDropTokenAbi,
        airDropContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getlazyNFTTokenInstance() {
  const { lazyNFTContract } = getContractAddresses();
  console.log(lazyNFTContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        lazyTokenAbi,
        lazyNFTContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getMerkelClaimTokenInstance() {
  const { merkleClaimContract } = getContractAddresses();
  console.log(merkleClaimContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        merkleClaimTokenAbi,
        merkleClaimContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getDistributedCouponInstance() {
  const { distributedCouponContract } = getContractAddresses();
  console.log(distributedCouponContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        distributedCouponAbi,
        distributedCouponContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getSweepstakesInstance() {
  const { sweepStakesContract } = getContractAddresses();
  console.log(sweepStakesContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        sweepStakesABI,
        sweepStakesContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function getMetricNFTInstance() {
  const { metricNFTContract } = getContractAddresses();
  console.log(metricNFTContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        metricNFTABI,
        metricNFTContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSeaDropInstance() {
  const { seaDropContract } = getContractAddresses();
  console.log(seaDropContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        seaDropABI,
        seaDropContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
export async function createContractInstance(address) {
  const { ERC721SeaDropContract } = getContractAddresses();
  console.log(ERC721SeaDropContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        ERC721SeaDropABI,
        ERC721SeaDropContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getERC721SeaDropInstance(ERC721SeaDropContract) {
  // const { ERC721SeaDropContract } = getContractAddresses();
  // console.log(ERC721SeaDropContract);
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        ERC721SeaDropABI,
        ERC721SeaDropContract
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}

export const csvFileToJson = async (string) => {
  let addressObj = {};
  let newObj = {};
  var array = await string.toString().split("\r\n");
  console.log("array", array); //here we are getting the first rows which is our header rows to convert it into keys we are logging it here
  var data = [];
  // console.log(data);
  for (const r of array) {
    // console.log(r);
    let row = await r.toString().split(",");
    data.push(row);
  }
  console.log(data);
  var heading = await data[0];
  // console.log(heading); to get the column headers which will act as key
  var ans_array = [];
  // console.log(ans_array);
  for (var i = 1; i < data.length; i++) {
    var row = await data[i];
    var obj = {};
    for (var j = 0; j < heading.length; j++) {
      if (!row[j]) {
        row[j] = "NA";
      }
      // console.log(row[j].toString())
      obj[heading[j].replaceAll(" ", "_")] = row[j]
        .toString()
        .replaceAll(" ", "_");
    }
    console.log("obj", obj);
    if (obj.walletAddress !== "NA") {
      addressObj[obj.walletAddress] = true;
      ans_array.push(obj);
    }
  }
  // for (let index = 0; index < ans_array.length; index++) {
  //   newObj[ans_array[index].walletAddress] = true;
  // }
  // console.log("newObj", newObj, "addressObj", addressObj);
  // setTokenAmount(total_amount);
  return addressObj;
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export function TimeStampToDateString(val) {
  const dateString = new Date(Number(val) * 1000);
  var year = dateString.getFullYear();
  // month as 2 digits (MM)
  var month = dateString.getMonth();
  // date as 2 digits (DD)
  var date = ("0" + dateString.getUTCDate()).slice(-2);
  var hrs = ("0" + dateString.getUTCHours()).slice(-2);
  var min = ("0" + dateString.getUTCMinutes()).slice(-2);
  var sec = ("0" + dateString.getUTCSeconds()).slice(-2);
  let d = `${year}-${months[month]}-${date} ${hrs}:${min} UTC`;
  return d;
}
