import React, { useEffect } from "react";
import { useState } from "react";
import { getDistributedCouponInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip } from "react-tooltip";
import { web3 } from "../web3";
import { storeNFT } from "../config/storage";
import { services } from "../services";
import getContractAddresses from "../contractData/address";
import { useNavigate } from "react-router-dom";
import Template from "../csvfileTemplate/distributeCoupon.csv";
import useWeb3AccountData from "../helpers/customHooks";
const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const DistributedCoupon = ({ header }) => {
  const [address,isLoading,isConnected]=useWeb3AccountData()

  const [ipfsData, setIpfsData] = useState({
    nameCoupon: "",
    description: "",
    imageOfCoupon: "",
    noOfCoupons: "",
    validity: "",
    rewardCost: "",
    starttime: "",
    creator: "",
    addressesCSV: "",
    couponCode: "0",
  });
  const navigate = useNavigate();

  const fileReader = new FileReader();
  const [couponCode, setCouponCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const changeIPFSData = async (e) => {
    if (e.target.name == "imageOfCoupon" || e.target.name == "addressesCSV") {
      // setCouponImage(e.target.files[0]);
      setIpfsData({ ...ipfsData, [e.target.name]: e.target.files[0] });
    } else {
      setIpfsData({ ...ipfsData, [e.target.name]: e.target.value });
    }
  };

  console.log(ipfsData);

  const distributionData = async (obj) => {
    console.log(obj);
    try {
      let res = await services.post(`/distributed-token/saveToken/`, {
        name: obj[0],
        uri: obj[1],
        creator: obj[2],
        rewardCost: obj[3],
        consumers: obj[5],
        startTime: obj[6],
        endTime: obj[7],
      });
      console.log("res", res);
      // console.log("res", res);
      if (res.status == 200) {
        setLoading(false);
        toast.success("Data save successful!");
      } else {
        toast.error("Transaction Failed!");
        setLoading(false);
      }
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };
  console.log("couponCode", ipfsData.couponCode);

  const airdropCouponCode = async () => {
    let startTime = new Date(ipfsData.starttime).valueOf() / 1000;
    let endtime = +startTime + 86400 * +ipfsData.validity;
    try {
      if (
        ipfsData.nameCoupon == "" ||
        ipfsData.description == "" ||
        // ipfsData.couponCode == "" ||
        ipfsData.imageOfCoupon == "" ||
        ipfsData.validity == "" ||
        ipfsData.rewardCost == "" ||
        ipfsData.starttime == "" ||
        ipfsData.creator == "" ||
        ipfsData.addressesCSV == ""
      )
        toast.error("All fields are required");
      else if (
        Math.floor(Date.now() / 1000) >
        new Date(ipfsData.starttime).valueOf() / 1000
      )
        toast.error("Start time should be greater than current time");
      else {
        setLoading(true);
        let CSVaddresses = [];
        const getCouponInstance = await getDistributedCouponInstance();
        console.log(getCouponInstance);
        if (ipfsData.addressesCSV) {
          console.log(ipfsData.addressesCSV);
          fileReader.onload = async function (event) {
            const text = await event.target.result;
            // console.log(event);
            // console.log(event.target.result);
            var array = await text.toString().split("\r\n");
            // console.log(array.toString().split(","));
            let addresses = await array
              .slice(1, array.length - 1)
              .toString()
              .split(",");

            for (let i = 0; i < addresses.length; i++) {
              // if (i % 2 == 0) {
              await CSVaddresses.push(addresses[i]);
              // } else {
              //   await res2.push(addresses[i]);
              // }
            }
            // let result = await [res1, res2];
            console.log("result: ", CSVaddresses);
          };
          fileReader.readAsText(ipfsData.addressesCSV);
          let stringUri = await storeNFT(
            ipfsData.imageOfCoupon,
            ipfsData.nameCoupon,
            ipfsData.description,
            ipfsData.noOfCoupons,
            ipfsData.validity,
            ipfsData.couponCost
          );
          if (stringUri) {
            let obj = await [
              ipfsData.nameCoupon,
              stringUri.metadataUri,
              ipfsData.creator,
              web3.utils.toWei(ipfsData.rewardCost),
              +ipfsData.couponCode,
              CSVaddresses,
              startTime,
              endtime,
            ];
            console.log("obj:", obj);

            await getCouponInstance.methods
              .distributeCoupon(obj)
              .send({ from: address })
              .on("transactionHash", (hash) => {
                console.log("on hash ", hash);
                toast.info("Transaction is Processing...");
              })
              .on("receipt", async (receipt) => {
                console.log("on receipt ", receipt);
                distributionData(obj);
                toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
                setLoading(false);
              })
              .on("error", (error) => {
                console.log("on error ", error);
                toast.error("Transaction Failed!");
                setLoading(false);
              });
          }
        } else {
          toast.error("Transaction Failed!");
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error?.message);
      setLoading(false);
    }
  };

  console.log("timeStamp", new Date(ipfsData.starttime).valueOf() / 1000);

  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-3">
          <h3 className="my-4">Distribute Coupon</h3>
          <div className="row">
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="cost">Coupon name:</label>
                <input
                  className="form-control"
                  placeholder="Enter Coupon name"
                  id="nameCoupon"
                  type={"text"}
                  name="nameCoupon"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="validity">Description:</label>
                <input
                  className="form-control"
                  placeholder="Description"
                  id="description"
                  type={"text"}
                  name="description"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Validity of coupons:</label>
                <input
                  className="form-control"
                  placeholder="Enter Validity of coupons"
                  id="Validity of coupons"
                  type={"number"}
                  name="validity"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="Rewards cost">Rewards Cost:</label>
                <input
                  className="form-control"
                  placeholder="Enter Rewards Cost"
                  id="Rewards Cost"
                  type={"number"}
                  min="0"
                  name="rewardCost"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="title">Image Of Coupon:</label>
                <input
                  className="form-control"
                  placeholder="Image Of Coupon"
                  id="imageOfCoupon"
                  type={"file"}
                  accept="image/*,video/mp4,video/x-m4v,video/*"
                  name="imageOfCoupon"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="title" className="d-flex justify-content-between">
                  Import Addresses CSV:{" "}
                  <span>
                    <a
                      href={Template}
                      download="Distribute_CSV_Template"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Template
                    </a>
                  </span>
                </label>
                <input
                  className="form-control"
                  placeholder=""
                  id="addressesCSV"
                  type={"file"}
                  accept=".csv"
                  name="addressesCSV"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Start time(timestamp):</label>
                <input
                  className="form-control"
                  placeholder="Enter Start time"
                  id="starttime"
                  type="datetime-local"
                  name="starttime"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Creator Address:</label>
                <input
                  className="form-control"
                  placeholder="Enter Creator Address"
                  id="Coupon Cost"
                  type={"text"}
                  name="creator"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
          </div>

          <div className="">
            <button
              type="button"
              className="btn btnBackground px-5 btn-lg"
              onClick={airdropCouponCode}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "Airdrop Coupon"
              )}{" "}
            </button>
            {/* {couponCode && (
            <button
              type="button"
              className="btn btn-primary btn-lg mx-4"
              onClick={() => downloadCoupons("CouponCodes")}
            >
              Download Coupon Code
            </button>
          )} */}
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default DistributedCoupon;
