import React from "react";
import { useNavigate } from "react-router-dom";


const Avatar = () => {
  const navigate = useNavigate();
  
  const clearStroage = () => {
    localStorage.clear();
    
  };
  return (
    <div className="dropdown me-1">
      <button
        type="button"
        className="btn btn-secondary py-1 mx-3 dropdown-toggle "
        id="dropdownMenuOffset"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-offset="10,20"
      > 
        Admin
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
        <li>
          {/* <a class="dropdown-item" href="#">
            Action
          </a> */}
        </li>
        <li>
          <a className="dropdown-item" href="/login" onClick={clearStroage}>
            LogOut
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Avatar;
