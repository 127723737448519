// import { services } from "../../services";
let networkId = 1;
// async function fetchNetworkId() {
//   networkId = await services.getNetworkId();
// }
// fetchNetworkId();
  
function getContractAddresses() {
  if (networkId === "0x4" || +networkId === 4)
    return {
      MDTContract: "0x510601cb8Db1fD794DCE6186078b27A5e2944Ad6", //"0x0fC5025C764cE34df352757e82f7B5c4Df39A836"
      couponContractAddress: "0xee56795CF923EFBa13B7b863b7E39F44EEAF2fd0", //"0x3e6f92B17F8acee681E61117ad83904b6d6586cd",
      lazyNFTContract: "0xd6bF19E97b9eB688d0AF703ba606d537Ac38031c", //"0x422CE0D8712f1B59C6a6B7FfBB30D2d8aEe06f14", //"0x422CE0D8712f1B59C6a6B7FfBB30D2d8aEe06f14",
      airDropContract: "0xB64Dc719Ca0B7975b719D32E028290721e37993F",
      merkleClaimContract: "0xBa8dae96ce36e736be3349e1d657CDeA41ad82E8",
      distributedCouponContract: "0xDAdB5a5E31B70eb1deC5874b48BC2acd4C4d04b8",
      sweepStakesContract: "0x07CE9082373122B6B5B8E64e1E996b234c62fA3e", //"0x4B175E2aEeF509371f1aF510DF4eC3bd1cc6B778"
      metricNFTContract: "0xe9F9dEc9bA8D0d85F72d0eE15DC9Bcdb93F92478", // "0xE7c795590250e93EFf31F5900DbBeC7d24de9aCC"
      seaDropContract: "0xCb0C12A4566E194DE146Ac0E0ce2b2f927aD153e", //"0x2BcbcAb56aD22e056E9C8Ef964f185D85cB879e0",
      ERC721SeaDropContract: "0x12C40663c379BBd8DA76ceFbc09a4314Bba6fE6E", //"0x9d2b0081a2d6CcD7a8Dc703aEac6A61EDdB21930", //"0xA721D143270A90fbEf1E9572e5754b4f06da38E5",
    };
  else if (+networkId === 1 || networkId === "0x1")
    return {
      MDTContract: "0x510601cb8Db1fD794DCE6186078b27A5e2944Ad6", //"0x0fC5025C764cE34df352757e82f7B5c4Df39A836"
      couponContractAddress: "0xee56795CF923EFBa13B7b863b7E39F44EEAF2fd0", //"0x3e6f92B17F8acee681E61117ad83904b6d6586cd",
      lazyNFTContract: "0xd6bF19E97b9eB688d0AF703ba606d537Ac38031c", //"0x422CE0D8712f1B59C6a6B7FfBB30D2d8aEe06f14", //"0x0b76aB3f6d358554824CF6B1b18481c2F476Cd8c",
      airDropContract: "0xB64Dc719Ca0B7975b719D32E028290721e37993F",
      merkleClaimContract: "0xBa8dae96ce36e736be3349e1d657CDeA41ad82E8",
      distributedCouponContract: "0xDAdB5a5E31B70eb1deC5874b48BC2acd4C4d04b8",
      sweepStakesContract: "0x07CE9082373122B6B5B8E64e1E996b234c62fA3e", //"0x4B175E2aEeF509371f1aF510DF4eC3bd1cc6B778"
      metricNFTContract: "0xe9F9dEc9bA8D0d85F72d0eE15DC9Bcdb93F92478", // "0xE7c795590250e93EFf31F5900DbBeC7d24de9aCC"
      seaDropContract: "0xCb0C12A4566E194DE146Ac0E0ce2b2f927aD153e", //"0x2BcbcAb56aD22e056E9C8Ef964f185D85cB879e0",
      ERC721SeaDropContract: "0x12c40663c379bbd8da76cefbc09a4314bba6fe6e", //"0x9d2b0081a2d6CcD7a8Dc703aEac6A61EDdB21930", //"0xA721D143270A90fbEf1E9572e5754b4f06da38E5",
    };
  else
    return {
      MDTContract: "0x510601cb8Db1fD794DCE6186078b27A5e2944Ad6", //"0x0fC5025C764cE34df352757e82f7B5c4Df39A836"
      couponContractAddress: "0xee56795CF923EFBa13B7b863b7E39F44EEAF2fd0", //"0x3e6f92B17F8acee681E61117ad83904b6d6586cd",
      lazyNFTContract: "0xd6bF19E97b9eB688d0AF703ba606d537Ac38031c", //"0x422CE0D8712f1B59C6a6B7FfBB30D2d8aEe06f14", //"0x422CE0D8712f1B59C6a6B7FfBB30D2d8aEe06f14",
      airDropContract: "0xB64Dc719Ca0B7975b719D32E028290721e37993F",
      merkleClaimContract: "0xBa8dae96ce36e736be3349e1d657CDeA41ad82E8",
      distributedCouponContract: "0xDAdB5a5E31B70eb1deC5874b48BC2acd4C4d04b8",
      sweepStakesContract: "0x07CE9082373122B6B5B8E64e1E996b234c62fA3e", //"0x4B175E2aEeF509371f1aF510DF4eC3bd1cc6B778"
      metricNFTContract: "0xe9F9dEc9bA8D0d85F72d0eE15DC9Bcdb93F92478", // "0xE7c795590250e93EFf31F5900DbBeC7d24de9aCC"
      seaDropContract: "0xCb0C12A4566E194DE146Ac0E0ce2b2f927aD153e", //"0x2BcbcAb56aD22e056E9C8Ef964f185D85cB879e0",
      ERC721SeaDropContract: "0x12C40663c379BBd8DA76ceFbc09a4314Bba6fE6E", //"0x9d2b0081a2d6CcD7a8Dc703aEac6A61EDdB21930", //"0xA721D143270A90fbEf1E9572e5754b4f06da38E5",
    };
}
export default getContractAddresses;
