import React, { useEffect } from "react";
import { useState } from "react";
import { getMDTTokenInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactTooltip from "react-tooltip";
import { web3 } from "../web3";
import { storeNFT } from "../config/storage";
import { services } from "../services";
import getContractAddresses from "../contractData/address";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { parseBalanceMap } from "./testMerkle/parse-balance-map";
import { useNavigate } from "react-router-dom";
import useWeb3AccountData from "../helpers/customHooks";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const MainLayout = ({ header }) => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const { couponContractAddress } = getContractAddresses();
  const [ipfsData, setIpfsData] = useState({
    nameNFT: "",
    description: "",
    imageOfCoupon: "",
    noOfCoupons: "",
    validity: "",
    couponCost: "",
    starttime: "",
    creator: "",
  });
  const navigate = useNavigate();

  const [txnThroughOwner, setTxnThroughOwner] = useState(true);
  const [couponCode, setCouponCode] = useState(null);
  // const [couponCode, setCouponCode] = useState([
  //   { address: "12323232323213", amount: 23 },
  //   { address: "12323232323213", amount: 13 },
  //   { address: "12323232323213", amount: 43 },
  //   { address: "12323232323213", amount: 27 },
  // ]);
  const [loading, setLoading] = useState(false);
  // const [allowance, setAllowance] = useState(false);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  useEffect(() => {
    const mdtAllowance = async () => {
      const mdtContractInstance = await getMDTTokenInstance();
      let res = await mdtContractInstance.methods
        .allowance(address, couponContractAddress)
        .call();
      console.log("res", res);
    };
    mdtAllowance();
  }, []);

  const changeIPFSData = async (e) => {
    if (e.target.name == "txnThroughOwner") {
      setTxnThroughOwner(e.target.value == "true" ? true : false);
    } else if (e.target.name == "imageOfCoupon") {
      // setCouponImage(e.target.files[0]);
      setIpfsData({ ...ipfsData, [e.target.name]: e.target.files[0] });
    } else {
      setIpfsData({ ...ipfsData, [e.target.name]: e.target.value });
    }
  };

  // const testsign = () => {
  //   const domain = [
  //     { name: "name", type: "string" },
  //     { name: "version", type: "string" },
  //     { name: "chainId", type: "uint256" },
  //     { name: "verifyingContract", type: "address" },
  //   ];
  //   const Coupon = [
  //     { name: "name", type: "string" },
  //     { name: "uri", type: "string" },
  //     { name: "creator", type: "address" },
  //   ];
  //   const domainData = {
  //     name: "LazyCoupon",
  //     version: "1",
  //     chainId: 97,
  //     verifyingContract: "0xd9145CCE52D386f254917e481eB44e9943F39138",
  //   };
  //   var message = {
  //     name: "StarBucks",
  //     uri: "https://ipfs.io/ipfs/bafyreibxqkh5n3mb6vjgzdriiea3nfoczrtr5qta37jqen3h6fauq2edme/metadata.json",
  //     creator: address,
  //   };

  //   //     Bid

  //   // Amount:
  //   // 100
  //   // Bidder:
  //   // UserId:
  //   // 323
  //   // Wallet:

  //   const data = JSON.stringify({
  //     types: {
  //       EIP712Domain: domain,
  //       "Generate Coupon": Coupon,
  //       // Identity: identity,
  //     },
  //     domain: domainData,
  //     primaryType: "Generate Coupon",
  //     message: message,
  //   });

  //   var from = address;

  //   var params = [from, data];
  //   var method = "eth_signTypedData_v4";

  //   web3.currentProvider.sendAsync(
  //     {
  //       method,
  //       params,
  //       from,
  //     },
  //     function (err, result) {
  //       if (err) return console.dir(err);
  //       if (result.error) {
  //         alert(result.error.message);
  //       }
  //       if (result.error) return console.error("ERROR", result);
  //       console.log("TYPED SIGNED:" + JSON.stringify(result.result));
  //     }
  //   );
  // const msgParams = JSON.stringify({
  //   domain: {
  //     // Defining the chain aka Rinkeby testnet or Ethereum Main Net
  //     chainId: 97,
  //     // Give a user friendly name to the specific contract you are signing for.
  //     name: "Ether Mail",
  //     // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
  //     verifyingContract: "0x5266fF551287e29a4515a68abE658EbcB9c08205",
  //     // Just let's you know the latest version. Definitely make sure the field name is correct.
  //     version: "1",
  //   },

  //   // Defining the message signing data content.
  //   message: {
  //     /*
  //      - Anything you want. Just a JSON Blob that encodes the data you want to send
  //      - No required fields
  //      - This is DApp Specific
  //      - Be as explicit as possible when building out the message schema.
  //     */
  //     contents: "Hello, Bob!",
  //     attachedMoneyInEth: 4.2,
  //     from: {
  //       name: "Cow",
  //       wallets: [
  //         "0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826",
  //         "0xDeaDbeefdEAdbeefdEadbEEFdeadbeEFdEaDbeeF",
  //       ],
  //     },
  //     to: [
  //       {
  //         name: "Bob",
  //         wallets: [
  //           "0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB",
  //           "0xB0BdaBea57B0BDABeA57b0bdABEA57b0BDabEa57",
  //           "0xB0B0b0b0b0b0B000000000000000000000000000",
  //         ],
  //       },
  //     ],
  //   },
  //   // Refers to the keys of the *types* object below.
  //   primaryType: "Mail",
  //   types: {
  //     // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
  //     EIP712Domain: [
  //       { name: "name", type: "string" },
  //       { name: "version", type: "string" },
  //       { name: "chainId", type: "uint256" },
  //       { name: "verifyingContract", type: "address" },
  //     ],
  //     // Not an EIP712Domain definition
  //     Group: [
  //       { name: "name", type: "string" },
  //       { name: "members", type: "Person[]" },
  //     ],
  //     // Refer to PrimaryType
  //     Mail: [
  //       { name: "from", type: "Person" },
  //       { name: "to", type: "Person[]" },
  //       { name: "contents", type: "string" },
  //     ],
  //     // Not an EIP712Domain definition
  //     Person: [
  //       { name: "name", type: "string" },
  //       { name: "wallets", type: "address[]" },
  //     ],
  //   },
  // });

  // var from = address;

  // var params = [from, msgParams];
  // var method = "eth_signTypedData_v4";

  // web3.currentProvider.sendAsync(
  //   {
  //     method,
  //     params,
  //     from: from,
  //   },
  //   function (err, result) {
  //     if (err) return console.dir(err);
  //     if (result.error) {
  //       alert(result.error.message);
  //     }
  //     if (result.error) return console.error("ERROR", result);
  //     console.log("TYPED SIGNED:" + JSON.stringify(result.result));
  //   }
  // );
  // };

  const submitCoupon = async () => {
    // setLoading(true);
    toast.info("Transaction is Processing...");
    let stringUri = await storeNFT(
      ipfsData.imageOfCoupon,
      ipfsData.nameNFT,
      ipfsData.description,
      ipfsData.noOfCoupons,
      ipfsData.validity,
      ipfsData.couponCost
    );
    console.log("2", ipfsData);
    if (stringUri) {
      await signData(stringUri);
    } else {
      toast.error("Transaction Failed!");
      setLoading(false);
    }
  };

  const createCoupon = async () => {
    if (
      ipfsData.nameNFT == "" ||
      ipfsData.description == "" ||
      ipfsData.imageOfCoupon == "" ||
      ipfsData.noOfCoupons == "" ||
      ipfsData.validity == "" ||
      ipfsData.couponCost == "" ||
      ipfsData.starttime == "" ||
      ipfsData.creator == ""
    )
      toast.error("All fields are required");
    else if (
      Math.floor(Date.now() / 1000) >
      new Date(ipfsData.starttime).valueOf() / 1000
    )
      toast.error("Start time should be greater than current time");
    else {
      setLoading(true);
      let totalMDT = await web3.utils.toWei(
        `${+ipfsData.couponCost * +ipfsData.noOfCoupons}`
      );
      const mdtContractInstance = await getMDTTokenInstance();
      let res = await mdtContractInstance.methods
        .allowance(address, couponContractAddress)
        .call();
      if (+totalMDT > +res) {
        console.log(+totalMDT > +res, totalMDT, res, mdtContractInstance);
        await mdtContractInstance.methods
          .approve(couponContractAddress, totalMDT)
          .send({ from: address })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", (receipt) => {
            console.log("on receipt ", receipt);
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            submitCoupon();
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setLoading(false);
          });
      } else {
        submitCoupon();
      }
    }
  };
  console.log(ipfsData);

  const signData = async (stringUri) => {
    let startTime = new Date(ipfsData.starttime).valueOf() / 1000;
    let endtime = +startTime + 86400 * +ipfsData.validity;
    // const { web3, accounts, contract } = this.state;
    var signer = address;
    var milsec_deadline = Date.now() / 1000 + 100;
    console.log(milsec_deadline, "milisec");
    var deadline = parseInt(String(milsec_deadline).slice(0, 10));
    console.log(deadline, "sec");
    var x = 157;
    console.log("deadline", deadline);

    web3.currentProvider.sendAsync(
      {
        method: "net_version",
        params: [],
        jsonrpc: "2.0",
      },
      function (err, result) {
        const netId = result.result;
        console.log("netId", netId);
        const msgParams = JSON.stringify({
          types: {
            EIP712Domain: [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              { name: "verifyingContract", type: "address" },
            ],
            set: [
              { name: "name", type: "string" },
              { name: "creator", type: "address" },
              { name: "uri", type: "string" },
              { name: "endtime", type: "uint256" },
            ],
          },
          //make sure to replace verifyingContract with address of deployed contract
          primaryType: "set",
          domain: {
            name: "SetTest",
            version: "1",
            chainId: 97,
            verifyingContract: couponContractAddress,
          },
          message: {
            name: ipfsData?.nameNFT,
            creator: address,
            uri: stringUri?.metadataUri,
            endtime: endtime,
          },
        });

        var from = signer;

        console.log(
          "CLICKED, SENDING PERSONAL SIGN REQ",
          "from",
          from,
          msgParams
        );
        var params = [from, msgParams];
        console.dir(params);
        var method = "eth_signTypedData_v3";

        web3.currentProvider.sendAsync(
          {
            method,
            params,
            from,
          },
          async function (err, result) {
            if (err) {
              setLoading(false);
              return console.dir(err);
            }
            if (result.error) {
              alert(result.error.message);
            }
            if (result.error) return console.error("ERROR", result);
            console.log("TYPED SIGNED:" + JSON.stringify(result.result));

            // const recovered = sigUtil.recoverTypedSignature({ data: JSON.parse(msgParams), sig: result.result })

            // if (ethUtil.toChecksumAddress(recovered) === ethUtil.toChecksumAddress(from)) {
            //   alert('Successfully ecRecovered signer as ' + from)
            // } else {
            //   alert('Failed to verify signer when comparing ' + result + ' to ' + from)
            // }

            //getting r s v from a signature
            // const signature = result.result.substring(2);
            // const r = "0x" + signature.substring(0, 64);
            // const s = "0x" + signature.substring(64, 128);
            // const v = parseInt(signature.substring(128, 130), 16);
            // console.log("r:", r);
            // console.log("s:", s);
            // console.log("v:", v);
            console.log("signature", result.result.substring(2));
            let res = await services.post(`/coupon/save-coupon/`, {
              name: ipfsData.nameNFT,
              description: ipfsData.description,
              imageUrl: stringUri.path,
              noOfCoupons: ipfsData.noOfCoupons,
              validity: ipfsData.validity,
              couponCost: web3.utils.toWei(ipfsData.couponCost),
              couponHash: stringUri.metadataUri,
              signature: result.result,
              startTime: `${new Date(ipfsData.starttime).valueOf() / 1000}`,
              creator: ipfsData.creator,
              txnThroughOwner: txnThroughOwner,
            });
            console.log("res", res);
            // console.log("res", res);
            if (res.status == 200) {
              setCouponCode(res.data.data.couponCodes);
              setLoading(false);
              toast.success("Transaction Successful!");
            } else {
              toast.error("Transaction Failed!");
              setLoading(false);
            }
            // setCouponCode(res.data.data.couponCodes);
            // setLoading(false);
            // await contract.methods
            //   .executeSetIfSignatureMatch(v, r, s, signer, deadline, x)
            //   .send({ from: accounts[0] });
          }
        );
      }
    );
  };
  console.log("couponCode", couponCode);

  const downloadCoupons = async (fileName) => {
    console.log(couponCode);

    const ws = await XLSX.utils.json_to_sheet(couponCode);
    const wb = await { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = await XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = await new Blob([excelBuffer], { type: fileType });
    console.log(ws);

    await FileSaver.saveAs(data, fileName + fileExtension);

    await setCouponCode(null);
  };

  console.log("timeStamp", new Date(ipfsData.starttime).valueOf() / 1000);
  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");

  return (
    <>
      {authToken ? (
        <div className="container my-3">
          <h3 className="my-4">Create coupon</h3>
          <div className="row ">
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="cost">Coupon name:</label>
                <input
                  className="form-control"
                  placeholder="Enter Coupon name"
                  id="nameNFT"
                  type={"text"}
                  name="nameNFT"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="validity">Description:</label>
                <input
                  className="form-control"
                  placeholder="Description"
                  id="description"
                  type={"text"}
                  name="description"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">No. of coupons:</label>
                <input
                  className="form-control"
                  placeholder="No of coupon"
                  id="No of coupon"
                  type={"number"}
                  name="noOfCoupons"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Validity of coupons:</label>
                <input
                  className="form-control"
                  placeholder="Enter Validity of coupons"
                  id="Validity of coupons"
                  type={"number"}
                  name="validity"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Coupon Cost:</label>
                <input
                  className="form-control"
                  placeholder="Enter Coupon Cost"
                  id="Coupon Cost"
                  type={"number"}
                  min="0"
                  name="couponCost"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Creator Address:</label>
                <input
                  className="form-control"
                  placeholder="Enter Creator Address"
                  id="Coupon Cost"
                  type={"text"}
                  name="creator"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="title">Image Of Coupon:</label>
                <input
                  className="form-control"
                  placeholder="Image Of Coupon"
                  id="imageOfCoupon"
                  type={"file"}
                  accept="image/*,video/mp4,video/x-m4v,video/*"
                  name="imageOfCoupon"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group">
                <label for="no of coupon">Start time(timestamp):</label>
                <input
                  className="form-control"
                  placeholder="Enter Start time"
                  id="starttime"
                  type="datetime-local"
                  name="starttime"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="my-1" for="">
              Reedem from
              <ReactTooltip id="tooltip" />
            </div>
            <div
              className="form-check form-check-inline"
              // data-for="tooltip"
              // data-tip="Owner Wallet!"
            >
              <input
                className="form-check-input"
                type="radio"
                name="txnThroughOwner"
                id="inlineRadio1"
                value={true}
                onChange={changeIPFSData}
                checked={txnThroughOwner}
              />
              <label className="form-check-label" for="inlineRadio1">
                Owner Wallet
              </label>
            </div>
            <div
              className="form-check form-check-inline ms-3"
              // data-for="tooltip"
              // data-tip="User Wallet!"
            >
              <input
                className="form-check-input"
                type="radio"
                name="txnThroughOwner"
                id="inlineRadio2"
                value={false}
                onChange={changeIPFSData}
              />
              <label className="form-check-label" for="inlineRadio2">
                User Wallet
              </label>
            </div>
          </div>

          <br />
          <div className="">
            <button
              type="button"
              className="btn btnBackground px-5 btn-lg"
              onClick={createCoupon}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "Create Coupon"
              )}{" "}
            </button>
            {couponCode && (
              <button
                type="button"
                className="btn btnBackground btn-lg mx-4 px-5"
                onClick={() => downloadCoupons("CouponCodes")}
              >
                Download Coupon Code
              </button>
            )}
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default MainLayout;
