import React, { useEffect } from "react";
import { useState } from "react";
import {
  getSeaDropInstance,
  getERC721SeaDropInstance,
  csvFileToJson,
} from "../../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import useWeb3AccountData from "../../helpers/customHooks";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const AdminApproval = () => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [allowedFeeRecipient, setAllowedFeeRecipient] = useState({
    seaDropImpl: "",
    feeRecipient: "",
    isAllowed: true,
  });
  const [creatorPayoutAddress, setCreatorPayoutAddress] = useState({
    seaDropImpl: "",
    payoutAddress: "",
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  // const [allowance, setAllowance] = useState(false);

  const changeFeeReceipt = async (e) => {
    setAllowedFeeRecipient({
      ...allowedFeeRecipient,
      [e.target.name]: e.target.value,
    });
  };
  const changePayoutAddr = async (e) => {
    setCreatorPayoutAddress({
      ...creatorPayoutAddress,
      [e.target.name]: e.target.value,
    });
  };

  const updateAllowedFeeRecipient = async () => {
    if (
      allowedFeeRecipient.seaDropImpl == "" ||
      allowedFeeRecipient.feeRecipient == "" ||
      allowedFeeRecipient.isAllowed == ""
    )
      toast.error("All fields are required");
    else {
      setLoading(true);
      const obj = await [
        allowedFeeRecipient.seaDropImpl,
        allowedFeeRecipient.feeRecipient,
        allowedFeeRecipient.isAllowed,
      ];
      const erc721SeaDropInstance = await getERC721SeaDropInstance();
      await erc721SeaDropInstance.methods
        .updateAllowedFeeRecipient(...obj)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log("on hash ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", (receipt) => {
          console.log("on receipt ", receipt);
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          setLoading(false);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoading(false);
        });
    }
  };

  //updatePayoutAddress
  const updatePayoutAddress = async () => {
    if (
      allowedFeeRecipient.seaDropImpl == "" ||
      allowedFeeRecipient.payoutAddress == ""
    )
      toast.error("All fields are required");
    else {
      setLoading(true);
      const obj = await [
        allowedFeeRecipient.seaDropImpl,
        allowedFeeRecipient.payoutAddress,
      ];
      const erc721SeaDropInstance = await getERC721SeaDropInstance();
      await erc721SeaDropInstance.methods
        .updateCreatorPayoutAddress(...obj)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log("on hash ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", (receipt) => {
          console.log("on receipt ", receipt);
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          setLoading(false);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoading(false);
        });
    }
  };

  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div>
          <div className="container my-5 border-bottom border-primary">
            <h3>Update AllowedFeeRecipient</h3>
            <div className="form-group">
              <label for="cost">seaDropImpl Address:</label>
              <input
                className="form-control"
                placeholder="Enter seaDropImpl Address here..."
                id="seaDropImpl"
                type={"text"}
                name="seaDropImpl"
                onChange={changeFeeReceipt}
                required
              />
            </div>
            <div className="form-group">
              <label for="validity">FeeRecipient Address:</label>
              <input
                className="form-control"
                placeholder="Enter FeeRecipient Address here..."
                id="feeRecipient"
                type={"text"}
                name="feeRecipient"
                onChange={changeFeeReceipt}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="restrictFeeRecipients">IsAllowed</label>
              <select
                className="form-select form-select"
                aria-label=".form-select-lg example"
                name="isAllowed"
                onChange={(e) => changeFeeReceipt(e)}
                required
              >
                <option selected disabled>
                  Select an Option
                </option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>

            <br />
            <div className="d-grid gap-2 mb-5">
              <button
                type="button"
                className="btn btn-primary  btn-lg"
                onClick={updateAllowedFeeRecipient}
                disabled={loading}
              >
                {" "}
                {loading ? (
                  <div>
                    Loading{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "Update AllowedFeeRecipient"
                )}{" "}
              </button>
            </div>
          </div>

          {/* Update PayOut Address */}
          <div className="container my-5">
            <h3>Update Creator PayoutAddress</h3>
            <div className="form-group">
              <label for="cost">seaDropImpl Address:</label>
              <input
                className="form-control"
                placeholder="Enter seaDropImpl Address here..."
                id="seaDropImpl"
                type={"text"}
                name="seaDropImpl"
                onChange={changePayoutAddr}
                required
              />
            </div>
            <div className="form-group">
              <label for="validity">PayoutAddress Address:</label>
              <input
                className="form-control"
                placeholder="Enter payoutAddress Address here..."
                id="payoutAddress"
                type={"text"}
                name="payoutAddress"
                onChange={changePayoutAddr}
                required
              />
            </div>

            <br />
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn btn-primary  btn-lg"
                onClick={updatePayoutAddress}
                disabled={loading}
              >
                {" "}
                {loading ? (
                  <div>
                    Loading{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "Update CreatorPayoutAddress"
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AdminApproval;
