import React, { useEffect } from 'react'
import { useState } from 'react'
import { getMetricNFTInstance } from '../helpers/functions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip } from "react-tooltip";
import { web3 } from "../web3";
import { storeNFT } from "../config/storage";
import { services } from "../services";
import getContractAddresses from "../contractData/address";
import CSK from "../assets/csk.jpeg";
import MI from "../assets/mi.jpeg";
import RCB from "../assets/rcb.jpg";
import DD from "../assets/dd.jpg";
import GT from "../assets/gt.jpeg";
import KP from "../assets/kp.jpg";
import RR from "../assets/rr.jpeg";
import SRH from "../assets/srh.jpg";
import useWeb3AccountData from "../helpers/customHooks";
import Web3 from "web3";

const data = [
  { image: CSK, name: 'Chennai Super King', price: '20000000000000000' },
  { image: MI, name: 'Mumbai Indians', price: '10000000000000000' },
  {
    image: RCB,
    name: 'Royal Challengers Bangalore',
    price: '20000000000000000'
  },
  { image: DD, name: 'Delhi Daredevil', price: '20000000000000000' },
  { image: GT, name: 'Gujrat Titans', price: '10000000000000000' },
  { image: KP, name: 'King XI Punjab', price: '20000000000000000' },
  { image: RR, name: 'Rajisthan Royals', price: '10000000000000000' },
  { image: SRH, name: 'Sun Risers Hydrabad', price: '20000000000000000' }
]

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{' '}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  )
}
const MetricNFT = () => {
  const [address, isLoading, isConnected] = useWeb3AccountData()
  const { metricNFTContract } = getContractAddresses()
  const [loadingID, setLoadingID] = useState(null)
  const [NFTCount, setNFTCount] = useState(0)
  const [currBalance, setCurrBalance] = useState(0)
  const [isEligibleForNFTRedemption, setIsEligibleForNFTRedemption] =
    useState(true)
  const [PaidTillNow, setPaidTillNow] = useState(null)

  // fetch the paidtill amount and conditionaly render that amount if user's total purchase with fiat worth >= 5000.
  useEffect(() => {
    console.log('setIsEligibleForNFTRedemption useEffect running')
    if (PaidTillNow >= 5000) {
      setIsEligibleForNFTRedemption(false)
    }
    fetchUserData()
  }, [address, PaidTillNow])

  const init = async () => {
    if (address) {
      const metricNFTInstance = await getMetricNFTInstance()
      let nftCount = await metricNFTInstance.methods.balanceOf(address).call()
      console.log('NFTCount', nftCount)
      let currBalance = await metricNFTInstance.methods
        .getBNBBalance(address)
        .call()
      console.log('currBalance', currBalance)
      setNFTCount(nftCount)
      setCurrBalance(currBalance)
    }
  }

  // Redeem NFT function call with metric contract
  const RedeemNft = async () => {
    try {
      const metricNFTInstance = await getMetricNFTInstance()
      let nftRedeem = await metricNFTInstance.methods
        .redeem()
        .send({
          from: address
        })
        .on('transactionHash', hash => {
          console.log('transaction hash : ', hash)
          toast.info('Transaction is Processing...')
        })
      console.log('This is log of RedeemNFT', nftRedeem)
      alert('Redeem Successfull')
    } catch (error) {
      console.log(error)
      alert('Redeem Failed')
    }
  }

  // Mint and transfer NFT when user pay with fiat

  // const PayWithFiat = async ()=>{
  //   try{
  //     const metricNFTInstance = await getMetricNFTInstance();
  //     let res = await metricNFTInstance.methods._mintNFT().send({
  //       from: address,
  //     })
  //     .on("transactionHash", (hash) => {
  //       console.log("transaction hash : ", hash);
  //       toast.info("Transaction is Processing...");
  //     })
  //     console.log("RedeemNFT===", res);
  //     alert("pay with fiat successfull")
  //   }catch (error){
  //     console.log(error);
  //     alert("Pay with fiat failed")
  //   }
  // }

  // RAZORPAY integration
  // This function is created to load the script of razorpay
  function loadScript(src) {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  // This function is when we load script of razorpay and display razor pay popup.
  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    // Creating a new order
    const requestData = {
      name: 'Jersey NFT',
      price: '500',
      amount: '2',
      walletAddress: '0xA347C4cCd3EF82326d6670A883147eE6Cf767A73'
    }

    try {
      const result = await services.post(
        'https://arcapi.minddeft.com/api/payment/initPayment',
        requestData
      )
      console.log('initpayment Result:', result)

      if (!result) {
        alert('Server error. Are you online?')
        return
      }
      // Getting the order details
      const { amount, id: order_id, currency } = result.data.data
      console.log('Initpayment Amount:', amount)

      const pkey = process.env.key

      const options = {
        key: pkey,
        amount: amount.toString(),
        currency: currency,
        name: 'ARC NFTS',
        description: 'Test Transaction',
        image: 'https://arc.minddeft.com/favicon-32x32.png',
        order_id: order_id,
        callback_url: 'http://arc.minddeft.com/metric-NFT-reward',
        theme: {
          color: '#7d33ff'
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    } catch (error) {
      console.error('Error:', error)
      alert('An error occurred while fetching payment details.')
      return
    }
  }

  const handleBuy = async (key, price) => {
    try {
      setLoadingID(key)
      console.log('address', address, 'metricNFTContract', metricNFTContract)
      await web3.eth
        .sendTransaction({
          from: address,
          to: metricNFTContract,
          value: price
        })
        .on('transactionHash', hash => {
          console.log('on hash ', hash)
          toast.info('Transaction is Processing...')
        })
        .on('receipt', async receipt => {
          console.log('on receipt ', receipt)
          init()
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />)
          setLoadingID(null)
        })
        .on('error', error => {
          console.log('on error ', error)
          toast.error('Transaction Failed!')
          setLoadingID(null)
        })
    } catch (error) {
      console.log('on error ', error)
      setLoadingID(null)
    }
  }

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `https://arcapi.minddeft.com/api/user/details/${address}`
      )
      const data = await response.json()
      console.log('this is userdata', data.data.paidTillNow)
      console.log('this is data', data)
      // setUserData(data);
      setPaidTillNow(data.data.paidTillNow)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }
  return (
    <>
      <div className="container p-5">
        <div className="fs-3 fw-bold">Buy IPL Team T-Shirt</div>
        <div className="row border rounded-2 mt-3 mx-1 bg-transparent text-break d-flex justify-content-around">
          <div className="col-12 col-lg-6 my-3">
            Total NFT Reward Minted:{' '}
            {!address ? 'Connect your Wallet' : NFTCount}
          </div>
          <div className="col-12 col-lg-6 my-3 text-end">
            Reward Point:{' '}
            {!address
              ? 'Connect your Wallet'
              : currBalance != 0
              ? Web3.utils.fromWei(`${currBalance}`,'ether')
              : 0}
          </div>
        </div>

        {/* Redeem NFT if user isEligibleForNFTRedemption  */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <div style={{ marginRight: '10px' }}>
            {PaidTillNow ? (
              <div>
                <p style={{ marginBottom: '0.5px', marginTop: '10px' }}>
                  Current Fiat Balance: {PaidTillNow}
                </p>
              </div>
            ) : (
              'Current Fiat Balance: 0'
            )}
          </div>
          {isEligibleForNFTRedemption ? (
            <p
              className="redeemnft"
              style={{ margin: '0', marginLeft: '10px' }}
            >
              Purchase of ₹ 5000 with Fiat to Redeem NFT
            </p>
          ) : (
            <button
              onClick={RedeemNft}
              disabled={PaidTillNow <= 5000}
              style={{ marginLeft: '10px' }}
            >
              Redeem NFT
            </button>
          )}
        </div>

        <div className="row">
          {data.map((ele, key) => (
            <div key={key} className="col-12 col-md-4 col-lg-3 my-3">
              <div
                className="Nftcard w-100 pb-2 p-2"
                style={{ backgroundColor: '#161924' }}
              >
                <div className="border rounded-2 bg-light text-center">
                  <img
                    src={ele.image}
                    className=""
                    style={{ weigth: '100px', height: '150px' }}
                    alt="..."
                  />
                </div>
                <div className=" text-light text-center my-2">
                  <div className=" p-0 ">{ele.name}</div>
                  <p className="">
                    <span className="">Price:</span>{' '}
                    <span className="text-primary">
                      {Web3.utils.fromWei(ele.price,'ether')} BNB
                    </span>
                  </p>
                  <button
                    className="btn btnBackground px-5"
                    onClick={() => handleBuy(key, ele.price)}
                    disabled={loadingID == key ? true : false}
                  >
                    {loadingID == key ? 'Loading...' : 'BUY'}
                  </button>

                  <button
                    className="App-link"
                    onClick={async () => {
                      await displayRazorpay()
                    }}
                  >
                    Pay with Fiat ₹
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default MetricNFT
