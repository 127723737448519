// import { async } from "q";
import React from "react";
import { useState } from "react";
import { services } from "../services";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo-png.png";

const Login = () => {
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const handleChange = async (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };
  console.log("loginDetails", loginDetails);
  const authLogin = async () => {
    const res = await services.post(`/admin/login`, {
      username: loginDetails.username,
      password: loginDetails.password,
    });
    console.log("res", res);
    if (res.status === 200) {
      console.log("res", res.data.data.token);
      localStorage.setItem("arcAdminAuthToken", res.data.data.token);
      navigate("/");
      navigate(0);
      
      
    } else {
      console.log(alert, "Error1");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 5000);
    }
  };
  console.log(alert, "Error2");
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("called submit");
    if (loginDetails.username && loginDetails.password) {
      authLogin();
    } else {
      console.log("Password is wrong");
    }
  };
  return (
    <>
      {/* <div className="container my-5">
        <form>
          <div className="mb-3">
            <label for="exampleInputUsername" className="form-label">
              User Name
            </label>
            <input
              type="username"
              className="form-control"
              id="exampleInputUsername"
              aria-describedby="username"
              name="username"
              placeholder="enter your username here..."
              onChange={(e) => handleChange(e)}
              value={loginDetails?.username}
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              name="password"
              placeholder="enter your password here..."
              onChange={(e) => handleChange(e)}
              value={loginDetails?.password}
            />
          </div>

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </form>
      </div> */}

      <div className="container-fluid">
        <div className="row LoginMainlayout">
          <div className="col-12 col-lg-6 col-centered d-flex justify-content-center align-items-center">
            <div className="">
              <img
                src={Logo}
                className="my-3"
                width="auto"
                height={50}
                alt="logo"
              />
              <form className="FormStyle" onSubmit={handleSubmit}>
                {alert ? (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    Incorrect Username/Password, Please try again!
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setAlert(false)}
                    ></button>
                  </div>
                ) : null}
                <div className="d-flex flex-row align-items-center mb-4">
                  <div className="form-outline flex-fill mb-0">
                    <label className="form-label" for="form3Example1c">
                      USERNAME
                    </label>
                    <div>
                      <input
                        type="text"
                        id="form3Example1c"
                        className=""
                        name="username"
                        onChange={(e) => handleChange(e)}
                        value={loginDetails?.username}
                        />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-center mb-4">
                  <div className="form-outline flex-fill mb-0">
                    <label className="form-label" for="form3Example4c">
                      PASSWORD
                    </label>
                    <div>
                      <input
                        type="password"
                        id="form3Example4c"
                        className=""
                        name="password"
                        onChange={(e) => handleChange(e)}
                        value={loginDetails?.password}
                       
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-start  mb-3 mb-lg-4">
                  <button
                    type="submit"
                    className="btn btnBackground"
                    onClick={handleSubmit}
                  >
                    LOGIN
                  </button>
                  {/* <button type="button" className="btn btn-primary btn-lg">
                    CREATE ACCOUNT
                  </button> */}
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-centered">
            <div className="RightSide d-flex align-items-center">
              <p className="ms-5">
                WELCOME TO <div className="dfont">ARC DASHBOARD</div>
                <hr />
              </p>
            </div>
          </div>
        </div>
        {/* <div className="LeftLayout">
          <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign In</p>
          <form className="mx-1 mx-md-4">
            <div className="d-flex flex-row align-items-center mb-4">
              <i className="fas fa-user fa-lg me-3 fa-fw"></i>
              <div className="form-outline flex-fill mb-0">
                <input
                  type="text"
                  id="form3Example1c"
                  className="form-control"
                  name="username"
                  onChange={(e) => handleChange(e)}
                  value={loginDetails?.username}
                />
                <label className="form-label" for="form3Example1c">
                  Your UserName
                </label>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center mb-4">
              <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
              <div className="form-outline flex-fill mb-0">
                <input
                  type="password"
                  id="form3Example4c"
                  className="form-control"
                  name="password"
                  onChange={(e) => handleChange(e)}
                  value={loginDetails?.password}
                />
                <label className="form-label" for="form3Example4c">
                  Your Password
                </label>
              </div>
            </div>

            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div className="RightLayout">
          <div className="RightSide">
            <img src={LoginImg} className="Img" alt="" />
            <h1>
              WELCOME TO <span>ARC DASHBOARD</span>
            </h1>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Login;
