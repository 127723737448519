import Web3 from 'web3'
import { init } from '@web3-onboard/react'
import { chains, wallets } from './helpers/walletConfigs'
let web3 = new Web3(
  new Web3.providers.HttpProvider(
    //'https://bsc-dataseed.binance.org/'
    'https://data-seed-prebsc-1-s1.binance.org:8545/'
  )
)
  
const metamaskConnectInit = () => {
  // Check if Web3 has been injected by the browser (Mist/MetaMask).
  return new Promise((resolve, reject) => {
    if (typeof window.web3 !== 'undefined') {
      console.log('provider called', window.web3)
      // Use Mist/MetaMask's provider.
      web3 = new Web3(window.web3.currentProvider)
      localStorage.setItem('walletConnect', 0)
      console.log(Web3, 'web3', web3)
      resolve(true)
    } else {
      // Handle the case where the user doesn't have web3. Probably
      // show them a message telling them to install Metamask in
      // order to use the app.
      console.log('provider called1', window.web3)
      web3 = new Web3(
        new Web3.providers.HttpProvider(
          //'https://bsc-dataseed.binance.org/'
          'https://data-seed-prebsc-1-s1.binance.org:8545/'
        )
      )
      console.log(Web3, 'web31', web3)
      reject(false)
    }
  })
}
console.log('web3', web3)
if (!web3) {
  metamaskConnectInit()
}
const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070'
function web3OnboardConnectInit() {
  init({
    // connect: {
    //   autoConnectAllPreviousWallet: true,
    // },
    apiKey,
    wallets: wallets,
    chains: chains
  })
}

function setWeb3Provider(provider) {
  console.log('i recieved provider', provider)
  web3 = new Web3(provider)
}

export { web3, metamaskConnectInit, web3OnboardConnectInit, setWeb3Provider }
