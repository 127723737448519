import React, { useEffect } from "react";
import { useState } from "react";
import { getSweepstakesInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip } from "react-tooltip";
import { web3 } from "../web3";
import { storeNFT } from "../config/storage";
import { services } from "../services";
import getContractAddresses from "../contractData/address";
import { useNavigate } from "react-router-dom";
import useWeb3AccountData from "../helpers/customHooks";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const CreateSweepstakes = ({ header }) => {

  const [address,isLoading,isConnected]=useWeb3AccountData()

  const [ipfsData, setIpfsData] = useState({
    name: "",
    prize: "",
    starttime: "",
    endtime: "",
  });
  const navigate = useNavigate();

  const [couponCode, setCouponCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const changeIPFSData = async (e) => {
    setIpfsData({ ...ipfsData, [e.target.name]: e.target.value });
  };

  console.log(ipfsData);

  const signData = async (stringUri) => {
    let startTime = new Date(ipfsData.starttime).valueOf() / 1000;
    let endtime = +startTime + 86400 * +ipfsData.validity;
    // const { web3, accounts, contract } = this.state;

    let res = await services.post(`/coupon/save-coupon/`, {
      name: ipfsData.nameCoupon,
      description: ipfsData.description,
      imageUrl: stringUri.path,
      noOfCoupons: ipfsData.noOfCoupons,
      validity: ipfsData.validity,
      rewardCost: web3.utils.toWei(ipfsData.rewardCost),
      couponHash: stringUri.metadataUri,
      startTime: `${new Date(ipfsData.starttime).valueOf() / 1000}`,
      creator: ipfsData.creator,
      // txnThroughOwner: txnThroughOwner,
    });
    console.log("res", res);
    // console.log("res", res);
    if (res.status == 200) {
      setCouponCode(res.data.data.couponCodes);
      setLoading(false);
      toast.success("Transaction Successful!");
    } else {
      toast.error("Transaction Failed!");
      setLoading(false);
    }
  };
  console.log("couponCode", couponCode);

  const handleSweepstakes = async () => {
    let startTime = new Date(ipfsData.starttime).valueOf() / 1000;
    let endTime = new Date(ipfsData.endtime).valueOf() / 1000;
    if (
      ipfsData.name == "" ||
      ipfsData.prize == "" ||
      ipfsData.starttime == "" ||
      ipfsData.endtime == ""
    )
      toast.error("All fields are required");
    else if (
      Math.floor(Date.now() / 1000) >
      new Date(ipfsData.starttime).valueOf() / 1000
    )
      toast.error("Start time should be greater than current time");
    else if (
      new Date(ipfsData.starttime).valueOf() / 1000 >=
      new Date(ipfsData.endtime).valueOf() / 1000
    )
      toast.error("End time should be greater than start time");
    else {
      setLoading(true);
      console.log(
        ipfsData.name,
        web3.utils.toWei(ipfsData.prize),
        startTime,
        endTime
      );
      const getSStakeInstance = await getSweepstakesInstance();
      console.log(getSStakeInstance);
      await getSStakeInstance.methods
        .createSweepStake(
          ipfsData.name,
          web3.utils.toWei(ipfsData.prize),
          startTime,
          endTime
        )
        .send({ from: address, value: web3.utils.toWei(ipfsData.prize) })
        .on("transactionHash", (hash) => {
          console.log("on hash ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", async (receipt) => {
          console.log("on receipt ", receipt);
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          setLoading(false);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoading(false);
        });
    }
  };

  console.log("timeStamp", new Date(ipfsData.starttime).valueOf() / 1000);
  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-3">
          <h3 className="my-4">Create your own sweepstakes</h3>
          <div className="row">
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="cost">SWEEPSTAKES NAME:</label>
                <input
                  className="form-control"
                  placeholder="enter sweepstake name here..."
                  id="name"
                  type={"text"}
                  name="name"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="validity">PRIZE:</label>
                <input
                  className="form-control"
                  placeholder="enter prize here..."
                  id="prize"
                  type={"text"}
                  name="prize"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="no of coupon">START TIME(timestamp):</label>
                <input
                  className="form-control"
                  placeholder="Enter Start time"
                  id="starttime"
                  type="datetime-local"
                  name="starttime"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-6 pe-5 pb-4">
              <div className="form-group mt-2">
                <label for="no of coupon">END TIME(timestamp):</label>
                <input
                  className="form-control"
                  placeholder="enter end time"
                  id="endtime"
                  type="datetime-local"
                  name="endtime"
                  onChange={changeIPFSData}
                  required
                />
              </div>
            </div>
          </div>

          <div className="">
            <button
              type="button"
              className="btn btnBackground px-5 fw-bold btn-lg"
              onClick={handleSweepstakes}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "CREATE SWEEPSTAKES"
              )}{" "}
            </button>
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default CreateSweepstakes;
