import React from 'react'
import { useState, useEffect } from 'react'
import { getContractInstance } from '../helpers/functions'
import { getMDTTokenInstance } from '../helpers/functions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { web3 } from '../web3'
import { storeNFT } from '../config/storage'
import { services } from '../services'
import getContractAddresses from '../contractData/address'
import { useNavigate } from 'react-router-dom'
import useWeb3AccountData from '../helpers/customHooks'

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{' '}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  )
}

const LazymintNft = () => {
  const [address, isLoading, isConnected] = useWeb3AccountData()
  const { lazyNFTContract } = getContractAddresses()
  console.log(lazyNFTContract)
  const [ipfsData, setIpfsData] = useState({
    nameNFT: '',
    description: '',
    imageOfCoupon: '',
    noOfCoupons: '',
    couponCost: '',
    creator: '',
    type: 'mintNft',
    saleType: '0',
    auctionStartTime: 0,
    auctionEndTime: 12
  })
  const [auctionTime, setAuctionTime] = useState(12)
  const [saleType, setSaleType] = useState('AUCTION')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const changeIPFSData = async e => {
    // if (e.target.name == "saleType") {
    //   setIpfsData({ ...ipfsData, [e.target.name]: e.target.value });
    // }
    if (e.target.name == 'imageOfCoupon') {
      setIpfsData({ ...ipfsData, [e.target.name]: e.target.files[0] })
    } else if (e.target.name == 'couponCost') {
      if (+e.target.value < 0) toast.error('Price must be a positive integer')
      else setIpfsData({ ...ipfsData, [e.target.name]: e.target.value })
    } else {
      setIpfsData({ ...ipfsData, [e.target.name]: e.target.value })
    }
  }

  //

  const mintNft = async e => {
    let currentTime = await Math.floor(Date.now() / 1000)
    console.log(currentTime)
    if (
      ipfsData.nameNFT == '' ||
      ipfsData.description == '' ||
      ipfsData.imageOfCoupon == '' ||
      //   ipfsData.noOfCoupons == "" ||
      ipfsData.couponCost == '' ||
      ipfsData.creator == ''
    ) {
      toast.error('All fields are required')
    }
    // else if (currentTime>ipfsData.)
    else {
      //   txnThroughOwner
      setLoading(true)
      setIpfsData({ ...ipfsData, [e.target.name]: currentTime })
      toast.info('Transaction is Processing...')
      console.log('1', ipfsData)
      let stringUri = await storeNFT(
        ipfsData.imageOfCoupon,
        ipfsData.nameNFT,
        ipfsData.description,
        1, //noOfCoupons
        ipfsData.type,
        web3.utils.toWei(ipfsData.couponCost)
      )
      console.log('2', ipfsData)
      if (stringUri) {
        await signData(stringUri, currentTime)

        console.log('StringUri: ', stringUri)
      } else {
        setLoading(false)
      }
    }
  }
  console.log(ipfsData)

  const signData = async (stringUri, currentTime) => {
    var signer = address
    var milsec_deadline = Date.now() / 1000 + 100
    console.log(milsec_deadline, 'milisec')
    var deadline = parseInt(String(milsec_deadline).slice(0, 10))
    console.log(deadline, 'sec')
    var x = 157
    console.log('deadline', deadline)

    web3.currentProvider.sendAsync(
      {
        method: 'net_version',
        params: [],
        jsonrpc: '2.0'
      },
      function (err, result) {
        const netId = result.result
        console.log('netId', netId)
        const msgParams = JSON.stringify({
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' }
            ],
            set: [
              { name: 'name', type: 'string' },
              { name: 'creator', type: 'address' },
              { name: 'uri', type: 'string' },
              { name: 'price', type: 'uint256' },
              { name: 'saleType', type: 'uint256' },
              { name: 'startTime', type: 'uint256' },
              { name: 'endTime', type: 'uint256' }
            ]
          },
          //make sure to replace verifyingContract with address of deployed contract
          primaryType: 'set',
          domain: {
            name: 'SetTest',
            version: '1',
            chainId: 97,
            verifyingContract: lazyNFTContract
          },
          message: {
            name: ipfsData?.nameNFT,
            creator: address,
            uri: stringUri?.metadataUri,
            price: web3.utils.toWei(ipfsData?.couponCost),
            saleType: +ipfsData?.saleType,
            startTime: ipfsData?.saleType == 1 ? currentTime : 0,
            endTime:
              ipfsData?.saleType == 1
                ? +currentTime + 3600 * +ipfsData.auctionEndTime
                : 0
          }
        })

        var from = signer

        console.log(
          'CLICKED, SENDING PERSONAL SIGN REQ',
          'from',
          from,
          msgParams
        )
        var params = [from, msgParams]
        console.dir(params)
        var method = 'eth_signTypedData_v3'

        web3.currentProvider.sendAsync(
          {
            method,
            params,
            from
          },
          async function (err, result) {
            if (err) {
              setLoading(false)
              return console.dir(err)
            }
            if (result.error) {
              alert(result.error.message)
            }
            if (result.error) return console.error('ERROR', result)
            console.log('TYPED SIGNED:' + JSON.stringify(result.result))
            // console.log("result:", result);
            console.log('signature', result.result.substring(2))
            let res = await services.post(`/nft/saveNftDetails/`, {
              name: ipfsData.nameNFT,
              description: ipfsData.description,
              imageUrl: stringUri.path,
              nftCost: web3.utils.toWei(ipfsData?.couponCost),
              nftUri: stringUri.metadataUri,
              signature: result.result,
              creator: ipfsData.creator,
              txnThroughOwner: false,
              saleType: +ipfsData?.saleType,
              startTime: ipfsData?.saleType == 1 ? currentTime : 0,
              endTime:
                ipfsData?.saleType == 1
                  ? +currentTime + 3600 * +ipfsData.auctionEndTime
                  : 0
            })
            console.log('res', res)
            if (res.status == 200) {
              setLoading(false)
              toast.success('Transaction Successful!')
            } else {
              toast.error('Transaction Failed!')
              setLoading(false)
            }
          }
        )
      }
    )
  }
  const testNFT = () => {
    var signer = address
    var milsec_deadline = Date.now() / 1000 + 100
    console.log(milsec_deadline, 'milisec')
    var deadline = parseInt(String(milsec_deadline).slice(0, 10))
    console.log(deadline, 'sec')
    var x = 157
    console.log('deadline', deadline)

    web3.currentProvider.sendAsync(
      {
        method: 'net_version',
        params: [],
        jsonrpc: '2.0'
      },
      function (err, result) {
        const netId = result.result
        console.log('netId', netId)
        const msgParams = JSON.stringify({
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' }
            ],
            set: [
              { name: 'name', type: 'string' },
              { name: 'creator', type: 'address' },
              { name: 'uri', type: 'string' },
              { name: 'price', type: 'uint256' }
            ]
          },
          //make sure to replace verifyingContract with address of deployed contract
          primaryType: 'set',
          domain: {
            name: 'SetTest',
            version: '1',
            chainId: 97,
            verifyingContract: '0x0b76aB3f6d358554824CF6B1b18481c2F476Cd8c'
          },
          message: {
            name: 'Dominos',
            creator: address,
            uri: 'https://ipfs.io/ipfs/bafyreif4dfyokruefub2xmu5rbfwxj6n6oekb3iyle5s5hgrmx35t242rq/metadata.json',
            price: '10000000000000000'
          }
        })

        var from = signer

        console.log(
          'CLICKED, SENDING PERSONAL SIGN REQ',
          'from',
          from,
          msgParams
        )
        var params = [from, msgParams]
        console.dir(params)
        var method = 'eth_signTypedData_v3'

        web3.currentProvider.sendAsync(
          {
            method,
            params,
            from
          },
          async function (err, result) {
            if (err) {
              setLoading(false)
              return console.dir(err)
            }
            if (result.error) {
              alert(result.error.message)
            }
            if (result.error) return console.error('ERROR', result)
            console.log('TYPED SIGNED:' + JSON.stringify(result.result))
          }
        )
      }
    )
    // const { web3, accounts, contract } = this.state;
    // var signer = address;
    // var milsec_deadline = Date.now() / 1000 + 100;
    // console.log(milsec_deadline, "milisec");
    // var deadline = parseInt(String(milsec_deadline).slice(0, 10));
    // console.log(deadline, "sec");
    // var x = 157;
    // console.log("deadline", deadline);

    // web3.currentProvider.sendAsync(
    //   {
    //     method: "net_version",
    //     params: [],
    //     jsonrpc: "2.0",
    //   },
    //   function (err, result) {
    //     const netId = result.result;
    //     console.log("netId", netId);
    //     const msgParams = JSON.stringify({
    //       types: {
    //         EIP712Domain: [
    //           { name: "name", type: "string" },
    //           { name: "version", type: "string" },
    //           { name: "chainId", type: "uint256" },
    //           { name: "verifyingContract", type: "address" },
    //         ],
    //         set: [
    //           { name: "name", type: "string" },
    //           { name: "creator", type: "address" },
    //           { name: "uri", type: "string" },
    //           { name: "price", type: "uint256" },
    //         ],
    //       },
    //       //make sure to replace verifyingContract with address of deployed contract
    //       primaryType: "set",
    //       domain: {
    //         name: "SetTest",
    //         version: "1",
    //         chainId: 97,
    //         verifyingContract: lazyNFTContract,
    //       },
    //       message: {
    //         name: "rishabh",
    //         creator: address,
    //         uri: "rishabh",
    //         price: "1000",
    //       },
    //     });

    //     var from = signer;

    //     console.log(
    //       "CLICKED, SENDING PERSONAL SIGN REQ",
    //       "from",
    //       from,
    //       msgParams
    //     );
    //     var params = [from, msgParams];
    //     console.dir(params);
    //     var method = "eth_signTypedData_v3";

    //     web3.currentProvider.sendAsync(
    //       {
    //         method,
    //         params,
    //         from,
    //       },
    //       async function (err, result) {
    //         // f (result.error) return console.error("ERROR", result);
    //         console.log("TYPED SIGNED:" + JSON.stringify(result.result));
    //         console.log("result:", result);
    //         console.log("signature", result.result.substring(2));
    //         if (err) {
    //           setLoading(false);
    //           return console.dir(err);
    //         }
    //         if (result.error) {
    //           alert(result.error.message);
    //         }
    //       }
    //     );
    //   }
    // );
  }
  console.log(saleType, auctionTime)
  //adminLogin auth
  let authToken = localStorage.getItem('arcAdminAuthToken')
  return (
    <>
      {authToken ? (
        <div
          className="container my-3"
          // style={{
          //   margin: "50px 200px 50px 200px",
          // }}
        >
          <div>
            <h3 className="my-4">Create NFT</h3>
            <div className="row">
              <div className="col-12 col-md-6 pe-5 pb-4">
                <div className="form-group mt-3">
                  <label htmlFor="cost">NFT name:</label>
                  <input
                    className="form-control"
                    placeholder="Enter NFT name"
                    id="nameNFT"
                    type={'text'}
                    name="nameNFT"
                    onChange={changeIPFSData}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 pe-5 pb-4">
                <div className="form-group mt-3">
                  <label htmlFor="validity">Description:</label>
                  <input
                    className="form-control"
                    placeholder="description"
                    id="description"
                    type={'text'}
                    name="description"
                    onChange={changeIPFSData}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 pe-5 pb-4">
                <div className="form-group mt-3">
                  <label htmlFor="no of coupon">NFT Price:</label>
                  <input
                    className="form-control"
                    placeholder="Enter NFT Price"
                    id="NFT Cost"
                    type={'number'}
                    min="0"
                    name="couponCost"
                    onChange={changeIPFSData}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 pe-5 pb-4">
                <div className="form-group mt-3">
                  <label htmlFor="no of coupon">Creator Address:</label>
                  <input
                    className="form-control"
                    placeholder="Enter Creator Address"
                    id="Coupon Cost"
                    type={'text'}
                    name="creator"
                    onChange={changeIPFSData}
                    required
                  />
                </div>
              </div>

              <div className="col-12 col-md-6 pe-5 pb-4">
                <div className="form-group mt-3">
                  <div htmlFor="title">Sale Status:</div>
                  <div
                    className=""
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="saleType"
                      id="buy"
                      value={'0'}
                      onChange={changeIPFSData}
                      checked={ipfsData.saleType == 0 ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary me-3 px-5"
                      htmlFor="buy"
                    >
                      BUY NOW
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="saleType"
                      id="auction"
                      value={'1'}
                      onChange={changeIPFSData}
                      checked={ipfsData.saleType == 1 ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary me-3 px-5"
                      htmlFor="auction"
                    >
                      AUCTION
                    </label>
                  </div>
                </div>
                {ipfsData.saleType == '1' ? (
                  <div className="form-group mt-3">
                    <div htmlFor="title">Sale Status:</div>
                    <div
                      className=""
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="auctionEndTime"
                        id="12"
                        value={'12'}
                        onChange={changeIPFSData}
                        checked={ipfsData.auctionEndTime == 12 ? true : false}
                      />
                      <label
                        className="btn btn-outline-primary me-3 px-3"
                        htmlFor="12"
                      >
                        12 hours
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="auctionEndTime"
                        id="24"
                        value={'24'}
                        onChange={changeIPFSData}
                        checked={ipfsData.auctionEndTime == 24 ? true : false}
                      />
                      <label
                        className="btn btn-outline-primary me-3 px-3"
                        htmlFor="24"
                      >
                        24 hours
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="auctionEndTime"
                        id="48"
                        value={'48'}
                        onChange={changeIPFSData}
                        checked={ipfsData.auctionEndTime == 48 ? true : false}
                      />
                      <label
                        className="btn btn-outline-primary me-3 px-3"
                        htmlFor="48"
                      >
                        48 hours
                      </label>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="col-12 col-md-6 pe-5 pb-4">
                <div className="form-group mt-3">
                  <label htmlFor="title">Image Of NFT:</label>
                  <input
                    className="form-control"
                    placeholder="Image Of Coupon"
                    id="imageOfCoupon"
                    type={'file'}
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                    name="imageOfCoupon"
                    onChange={changeIPFSData}
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="">
            <div className="my-1" htmlFor="">
              Reedem from
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="txnThroughOwner"
                id="inlineRadio1"
                value={true}
                onChange={changeIPFSData}
                checked={txnThroughOwner}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Owner Wallet
              </label>
            </div>
            <div className="form-check form-check-inline ms-3">
              <input
                className="form-check-input"
                type="radio"
                name="txnThroughOwner"
                id="inlineRadio2"
                value={false}
                onChange={changeIPFSData}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                User Wallet
              </label>
            </div>
          </div> */}
            <br />
            <div className="">
              <button
                type="button"
                className="btn btnBackground px-5 fw-bold btn-lg"
                onClick={mintNft}
                disabled={loading}
              >
                {' '}
                {loading ? (
                  <div>
                    {' '}
                    Loading{' '}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  'CREATE LAZY NFT'
                )}{' '}
              </button>
            </div>
          </div>
        </div>
      ) : (
        navigate('/login')
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default LazymintNft
