import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSeaDropInstance,
  getERC721SeaDropInstance,
  csvFileToJson,
} from "../../helpers/functions";
import getContractAddresses from "../../contractData/address";
import { web3 } from "../../web3";
import { parseBalanceMap } from "./seadropmerkle";
import useWeb3AccountData from "../../helpers/customHooks";
const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const SeaDrop = () => {
  const fileReader = new FileReader();
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [seaDropInputs, setSeaDropInputs] = useState({
    nftContract: "",
    feeRecipient: "",
    minterIfNotPayer: "",
    quantity: "",
  });
  const [dropInputs, setDropInputs] = useState({
    seaDropImpl: "",
    mintPrice: "",
    startTime: "",
    endTime: "",
    maxTotalMintableByWallet: "",
    feeBps: "",
    restrictFeeRecipients: false,
  });
  const [mintLoading, setMintLoading] = useState(false);
  const [dropLoading, setDropLoading] = useState(false);
  const { seaDropContract } = getContractAddresses();
  const seaDropInstance = getSeaDropInstance();
  console.log(
    "seaDropContract",
    seaDropContract,
    "seaDropInputs",
    seaDropInputs
  );

  const createSeaDropRoot = (e) => {
    fileReader.onload = async function (event) {
      const text = event.target.result;
      // let jsonArray = await csvFileToArray(text);
      let jsonArray = await csvFileToJson(text);
      console.log(jsonArray);
      let hash = parseBalanceMap(jsonArray);
      console.log(hash);
    };

    fileReader.readAsText(e.target.files[0]);
  };

  const handleChange = async (e) => {
    setSeaDropInputs({ ...seaDropInputs, [e.target.name]: e.target.value });
  };
  const handleDropChange = async (e) => {
    if (e.target.name == "restrictFeeRecipients")
      setDropInputs({
        ...dropInputs,
        [e.target.name]: e.target.value == "true" ? true : false,
      });
    else if (e.target.name == "startTime" || e.target.name == "endTime")
      setDropInputs({
        ...dropInputs,
        [e.target.name]: new Date(e.target.value).valueOf() / 1000,
      });
    else setDropInputs({ ...dropInputs, [e.target.name]: e.target.value });
  };
  const handlePublicDrop = async () => {
    try {
      if (
        dropInputs.seaDropImpl == "" ||
        dropInputs.mintPrice == "" ||
        dropInputs.startTime == "" ||
        dropInputs.endTime == "" ||
        dropInputs.maxTotalMintableByWallet == "" ||
        dropInputs.feeBps == ""
      )
        toast.error("Please Enter all required fields");
      else if (!address) toast.error("Please Connect your Wallet First!");
      else {
        setDropLoading(true);
        let obj = await [
          +dropInputs.mintPrice,
          dropInputs.startTime,
          dropInputs.endTime,
          +dropInputs.maxTotalMintableByWallet,
          +dropInputs.feeBps,
          dropInputs.restrictFeeRecipients,
        ];
        console.log("obj: ", obj);
        // setMintLoading(false);
        const erc721SeaDropInstance = await getERC721SeaDropInstance();
        await erc721SeaDropInstance.methods
          .updatePublicDrop(dropInputs.seaDropImpl, obj)
          .send({ from: address })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", async (receipt) => {
            console.log("on receipt ", receipt);
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setDropLoading(false);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setDropLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleMintPublic = async () => {
    try {
      if (
        seaDropInputs.nftContract == "" ||
        seaDropInputs.feeRecipient == "" ||
        seaDropInputs.minterIfNotPayer == "" ||
        seaDropInputs.quantity == ""
      )
        toast.error("Please Enter all required fields");
      else if (!address) toast.error("Please Connect your Wallet First!");
      else {
        setMintLoading(true);
        let obj = await [
          seaDropInputs.nftContract,
          seaDropInputs.feeRecipient,
          seaDropInputs.minterIfNotPayer,
          seaDropInputs.quantity,
        ];
        const seaDropInstance = await getSeaDropInstance();
        console.log("seaDropInstance", seaDropInstance);
        let mintPrice = await seaDropInstance.methods.getPublicDrop(
          "0xA721D143270A90fbEf1E9572e5754b4f06da38E5"
        );
        console.log("mintPrice", mintPrice);
        await seaDropInstance.methods
          .mintPublic(...obj)
          .send({ from: address, value: 2 })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", async (receipt) => {
            console.log("on receipt ", receipt);
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setMintLoading(false);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setMintLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log("dropInputs", dropInputs);
  function testSignedMint() {
    async function signMessageWithMetaMask(
      nftContract,
      minter,
      feeRecipient,
      mintParams,
      salt
    ) {
      console.log("called3");

      web3.currentProvider.sendAsync(
        {
          method: "net_version",
          params: [],
          jsonrpc: "2.0",
        },
        function (err, result) {
          const netId = result.result;
          console.log("netId", netId);
          const msgParams = JSON.stringify({
            types: {
              EIP712Domain: [
                { name: "name", type: "string" },
                { name: "version", type: "string" },
                { name: "chainId", type: "uint256" },
                { name: "verifyingContract", type: "address" },
              ],
              MintParams: [
                { name: "mintPrice", type: "uint256" },
                { name: "maxTotalMintableByWallet", type: "uint256" },
                { name: "startTime", type: "uint256" },
                { name: "endTime", type: "uint256" },
                { name: "dropStageIndex", type: "uint256" },
                { name: "maxTokenSupplyForStage", type: "uint256" },
                { name: "feeBps", type: "uint256" },
                { name: "restrictFeeRecipients", type: "bool" },
              ],
              SignedMint: [
                { name: "nftContract", type: "address" },
                { name: "minter", type: "address" },
                { name: "feeRecipient", type: "address" },
                { name: "mintParams", type: "MintParams" },
                { name: "salt", type: "uint256" },
              ],
            },
            primaryType: "SignedMint",
            domain: {
              name: "SeaDrop",
              version: "1.0",
              chainId: 1, // Replace with the desired chain ID
              verifyingContract: "0xddaAd340b0f1Ef65169Ae5E41A8b10776a75482d", // Replace with the contract address
            },
            message: {
              nftContract,
              minter,
              feeRecipient,
              mintParams,
              salt,
            },
          });
          var from = address;

          console.log(
            "CLICKED, SENDING PERSONAL SIGN REQ",
            "from",
            from,
            msgParams
          );
          var params = [from, msgParams];
          console.dir(params);
          var method = "eth_signTypedData_v3";

          web3.currentProvider.sendAsync(
            {
              method,
              params,
              from,
            },
            async function (err, result) {
              if (err) {
                return console.dir(err);
              }
              if (result.error) {
                alert(result.error.message);
              }
              if (result.error) return console.error("ERROR", result);
              console.log("TYPED SIGNED:" + JSON.stringify(result.result));
            }
          );
        }
      );
    }

    // Usage example
    const nftContract = "0xA721D143270A90fbEf1E9572e5754b4f06da38E5";
    const minter = "0x5B38Da6a701c568545dCfcB03FcB875f56beddC4";
    const feeRecipient = "0x863Ce3D6Aa68851aF2AdB09A479369326C3B1E13";
    const mintParams = {
      mintPrice: 1000000,
      maxTotalMintableByWallet: 5,
      startTime: 1687438793,
      endTime: 1687438793,
      dropStageIndex: 1,
      maxTokenSupplyForStage: 1000,
      feeBps: 100,
      restrictFeeRecipients: true,
    };
    const salt = 1234;
    console.log("called 2");
    signMessageWithMetaMask(
      nftContract,
      minter,
      feeRecipient,
      mintParams,
      salt
    );
  }
  return (
    <>
      <div className="container my-5  text-start">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>Public Drop</h3>
            <div className="form-group mt-2">
              <label htmlFor="cost">SeaDrop Impl:</label>
              <input
                className="form-control"
                placeholder="Enter SeaDrop Impl here..."
                id="seaDropImpl"
                type={"text"}
                name="seaDropImpl"
                onChange={(e) => handleDropChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="cost">Mint Price:</label>
              <input
                className="form-control"
                placeholder="Enter NFT Mint Price here..."
                id="mintPrice"
                type={"number"}
                name="mintPrice"
                onChange={(e) => handleDropChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="validity">Start Time:</label>
              <input
                className="form-control"
                placeholder="Enter Start Time here..."
                id="startTime"
                type={"datetime-local"}
                name="startTime"
                onChange={(e) => handleDropChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="validity">End Time:</label>
              <input
                className="form-control"
                placeholder="Enter Start Time here..."
                id="endTime"
                type={"datetime-local"}
                name="endTime"
                onChange={(e) => handleDropChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="validity">MaxTotal Mintable By Wallet:</label>
              <input
                className="form-control"
                placeholder="Enter MaxTotal Mintable By Wallet here..."
                id="maxTotalMintableByWallet"
                type={"number"}
                name="maxTotalMintableByWallet"
                onChange={(e) => handleDropChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="restrictFeeRecipients">FeeBps:</label>
              <input
                className="form-control"
                placeholder="Enter feeBps here..."
                id="feeBps"
                type={"number"}
                name="feeBps"
                onChange={(e) => handleDropChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="restrictFeeRecipients">
                Restrict Fee Recipients:
              </label>
              <select
                className="form-select form-select"
                aria-label=".form-select-lg example"
                name="restrictFeeRecipients"
                onChange={(e) => handleDropChange(e)}
                required
              >
                <option selected disabled>
                  Select an Option
                </option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
              {/* <input
                className="form-control"
                placeholder="Enter Restrict Fee Recipients here..."
                id="restrictFeeRecipients"
                type={"number"}
                name="restrictFeeRecipients"
                onChange={(e) => handleDropChange(e)}
                required
              /> */}
            </div>
            <br />
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn btn-primary  btn-lg"
                onClick={handlePublicDrop}
                disabled={dropLoading}
              >
                {" "}
                {dropLoading ? (
                  <div>
                    Loading{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "Public Drop"
                )}{" "}
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <h3>Mint Public</h3>
            <div className="form-group mt-2">
              <label htmlFor="cost">NftContract :</label>
              <input
                className="form-control"
                placeholder="Enter nftContract Address here..."
                id="minter"
                type={"text"}
                name="nftContract"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="cost">FeeRecipient :</label>
              <input
                className="form-control"
                placeholder="Enter feeRecipient Address here..."
                id="minter"
                type={"text"}
                name="feeRecipient"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="cost">MinterIfNotPayer :</label>
              <input
                className="form-control"
                placeholder="Enter minterIfNotPayer Address here..."
                id="minter"
                type={"text"}
                name="minterIfNotPayer"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className="form-group mt-2">
              <label htmlFor="no of coupon">Quantity:</label>
              <input
                className="form-control"
                placeholder="Enter Quantity here..."
                id="quantity"
                type={"text"}
                name="quantity"
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label for="validity">Upload CSV File:</label>
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Choose CSV File"
                  id="csvFile"
                  type={"file"}
                  accept=".csv"
                  name="csvFile"
                  onChange={createSeaDropRoot}
                  required
                />
                {/* <button
                className="btn btn-primary"
                type="button"
                id="button-addon2"
                onClick={generateRootHash}
                disabled={generateLoading}
              >
                {generateLoading ? "Loading..." : "Generate Root hash"}
              </button> */}
              </div>
            </div>

            <br />
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn btn-primary  btn-lg"
                onClick={handleMintPublic}
                disabled={mintLoading}
              >
                {" "}
                {mintLoading ? (
                  <div>
                    Loading{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    <span
                      className="spinner-grow  text-light spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                ) : (
                  "Mint"
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeaDrop;
