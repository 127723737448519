import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  let authToken = localStorage.getItem("arcAdminAuthToken");

  return (
    <>
      <div className="w-100 row">
        <div
          className=" fs-5 col-sm-12 col-md-4 col-lg-3 col-xl-3 pb-5"
          style={{ backgroundColor: "#191d26" }}
        >
          <ul className="nav flex-column sticky-top ">
            <NavLink
              className={(active) =>
                "" +
                (active.isActive
                  ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                  : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
              }
              to="/"
              // style={({ isActive }) => ({
              //   color: isActive ? "white" : "black",
              //   backgroundColor: isActive ? "black" : "white",
              //   textDecoration: "none",
              //   padding: "10px",
              // })}
            >
              Dashboard
            </NavLink>
            {/* <NavLink
              className={
                "SidebarBG  text-decoration-none ps-5 py-3 fs-5 "
              }
              onClick={() => setDropdown(!dropdown)}
            >
              Deploy {dropdown ? "▲" : "▼"}
            </NavLink> */}
            {/* <ul
            > */}
            {authToken ? (
              <>
                <NavLink
                  to="/createCoupon"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : " SidebarBG text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Create coupon
                </NavLink>
                <NavLink
                  to="/distributedCoupon"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : " SidebarBG text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Distribute Coupon
                </NavLink>
                <NavLink
                  to="/create-directory-ipfs"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Create IPFS Directory
                </NavLink>
                <NavLink
                  to="/collections"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : " SidebarBG text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Collections
                </NavLink>
                <NavLink
                  className={`${
                    dropdown ? "SelectSidebarBG" : "SidebarBG"
                  } text-decoration-none ps-5 py-3 fs-5 `}
                  onClick={() => setDropdown(!dropdown)}
                >
                  Sea Drop {dropdown ? "▲" : "▼"}
                </NavLink>
                <ul
                  className={`nav flex-column SidebarBG ps-5 py-1 fs-6 ${
                    dropdown ? "" : "d-none"
                  }`}
                  // style={{ backgroundColor: "#9DB2BF" }}
                >
                  <NavLink
                    to="/create-collections"
                    className={(active) =>
                      "" +
                      (active.isActive
                        ? "  text-white fw-normal text-decoration-none ps-4 py-1"
                        : "SidebarBG text-white fw-light text-decoration-none ps-4 py-1")
                    }
                  >
                    Create Collections
                  </NavLink>
                  <NavLink
                    to="/seaDrop"
                    className={(active) =>
                      "" +
                      (active.isActive
                        ? "  text-white fw-normal text-decoration-none ps-4 py-1"
                        : "SidebarBG text-white fw-light text-decoration-none ps-4 py-1")
                    }
                  >
                    Create Public Mint
                  </NavLink>

                  <NavLink
                    to="/mintAllowList"
                    className={(active) =>
                      "" +
                      (active.isActive
                        ? "  text-white fw-normal text-decoration-none ps-4 py-1"
                        : "SidebarBG text-white fw-light text-decoration-none ps-4 py-1")
                    }
                  >
                    Mint AllowList
                  </NavLink>
                  <NavLink
                    to="/adminApproval"
                    className={(active) =>
                      "" +
                      (active.isActive
                        ? "  text-white fw-normal text-decoration-none ps-4 py-1"
                        : "SidebarBG text-white fw-light text-decoration-none ps-4 py-1")
                    }
                  >
                    AdminApproval
                  </NavLink>
                </ul>
                <NavLink
                  to="/createERC20Token"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Create ERC20 token
                </NavLink>
                <NavLink
                  to="/airdropTokens"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG text-white text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Airdrop Tokens
                </NavLink>
                <NavLink
                  to="/lazymintNft"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : " SidebarBG text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Lazy Mint NFT
                </NavLink>
                <NavLink
                  to="/createMerkleDrop"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG text-white text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Create Merkle Airdrop
                </NavLink>
                <NavLink
                  to="/sweepstakes"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Create Sweepstakes
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  className={`${
                    dropdown1 ? "SelectSidebarBG" : "SidebarBG"
                  }  text-decoration-none ps-5 py-3 fs-5`}
                  onClick={() => setDropdown1(!dropdown1)}
                >
                  Integrated Rewards {dropdown1 ? "▲" : "▼"}
                </NavLink>
                <ul
                  className={`nav flex-column SidebarBG ps-5 py-1 fs-6  ${
                    dropdown1 ? "" : "d-none"
                  }`}
                >
                  <NavLink
                    to="/metric-NFT-reward"
                    className={(active) =>
                      "" +
                      (active.isActive
                        ? "  text-white fw-normal text-decoration-none ps-4 py-1"
                        : "SidebarBG text-white fw-light text-decoration-none ps-4 py-1")
                    }
                  >
                    Metric NFT Rewards
                  </NavLink>
                </ul>
                {/* <NavLink
                  to="/collections"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : " SidebarBG text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Drop
                </NavLink> */}
                <NavLink
                  to="/redeemCoupon"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Redeem Coupon
                </NavLink>
                <NavLink
                  to="/participate-in-sweepstakes"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Participate in Sweepstakes
                </NavLink>
                <NavLink
                  to="/view-distributed-coupon"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  View Distribute Coupon
                </NavLink>
                <NavLink
                  to="/redeemNft"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  NFT Marketplace
                </NavLink>
                <NavLink
                  to="/claimMerkleDrop"
                  className={(active) =>
                    "" +
                    (active.isActive
                      ? "SelectSidebarBG  text-decoration-none ps-5 py-3 fs-5"
                      : "SidebarBG  text-decoration-none ps-5 py-3 fs-5")
                  }
                >
                  Claim Merkle Airdrop
                </NavLink>
              </>
            )}
          </ul>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9 " >{children}</div>
      </div>
    </>
  );
};

export default Sidebar;
