import React, { useEffect } from "react";
import { useState } from "react";
import { getlazyNFTTokenInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { web3 } from "../web3";
import { services } from "../services";
// import Loader from "../assets/loading_arc.gif";
import Timer from "./timer";
import PlaceBidModal from "../modal/placeBidModal";
import LoginpopUp from "../modal/LoginpopUp";
import Loader from "../assets/spinner-loading.gif";
import useWeb3AccountData from "../helpers/customHooks";
import { Loader1 } from './loader';
const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const ReedemUsingQr = ({ idd }) => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [nftDetails, setNftDtails] = useState([]);
  const [openBidModal, setOpenBidModal] = useState({
    id: null,
    load: false,
  });
  const [loading, setLoading] = useState({ id: null, load: false });
  const [emptyLoading, setEmptyLoading] = useState(true);
  const [claimLoading, setClaimLoading] = useState(false);
  //   let idd = localStorage.getItem("lazyNftId");
  //   console.log("idd: ", idd);
  useEffect(() => {
    getNftList();
  }, [idd, address]);

  const getNftList = async () => {
    console.log("getNftList called");
    const LazyNFTInstance = await getlazyNFTTokenInstance();
    const nftListRes = await services.get(`/nft/${idd}`);
    if (nftListRes?.status === 200) {
      let getDetails = await LazyNFTInstance.methods
        .getBidDetails(nftListRes.data.data.signature)
        .call();
      console.log("nftListRes", nftListRes.data.data, getDetails);
      await setNftDtails({ ...getDetails, ...nftListRes.data.data });
      await setEmptyLoading(false);
    }
    await setEmptyLoading(false);
  };
  console.log("couponList", nftDetails);

  const redeemCoupon = async () => {
    setLoading({ id: nftDetails._id, load: true });
    let obj = await [
      nftDetails.name,
      nftDetails.nftUri,
      nftDetails.creator,
      nftDetails.nftCost,
      address,
      nftDetails.signature,
      nftDetails.saleType,
      nftDetails.startTime,
      nftDetails.endTime,
    ];
    console.log("obj", obj);
    const couponContractInstance = await getlazyNFTTokenInstance();
    console.log("contractInstance", couponContractInstance);
    await couponContractInstance.methods
      .mint(obj)
      .send({
        from: address,
        value: nftDetails.nftCost,
      })
      .on("transactionHash", (hash) => {
        // console.log("transaction hash : ", hash);
        toast.info("Transaction is Processing...");
      })
      .on("receipt", (receipt) => {
        console.log("on receipt ", receipt);
        toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
        setLoading({ id: null, load: false });
        getNftList();
      })
      .on("error", (error) => {
        console.log("on error ", error);
        toast.error("Transaction Failed!");
        setLoading({ id: null, load: false });
      });
    // }
  };
  const claimNFT = async (id, sign) => {
    try {
      console.log(id, sign);
      setClaimLoading(id);
      const LazyNFTInstance = await getlazyNFTTokenInstance();
      await LazyNFTInstance.methods
        .claimNFT(sign)
        .send({
          from: address,
        })
        .on("transactionHash", (hash) => {
          // console.log("transaction hash : ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", (receipt) => {
          console.log("on receipt ", receipt);
          getNftList();
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          setClaimLoading(false);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setClaimLoading(false);
        });
    } catch (error) {
      console.log("on error ", error);
      setClaimLoading(false);
    }
  };
  const handleBidding = (id) => {
    if (!address) toast.error("Please Connect your Wallet first!");
    else {
      setOpenBidModal({
        id: id,
        load: !openBidModal.load,
      });
    }
  };
  return (
    <div>
      {nftDetails.length == 0 ? (
        <div
          className="text-center mt-5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <img src={Loader} width={200} height={200} /> */}
          <Loader1/>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center rounded">
          <div
            className="card align-items-center m-5"
            style={{ width: "25rem" }}
          >
            <img className="card-img-top" src={nftDetails.imageUrl} alt="" />
            <div
              className="card-block text-center p-2 border-0"
              // style={{ backgroundColor: "#161924", color: "#fff" }}
            >
              <h4 className="card-title">{nftDetails?.name}</h4>
              <p className="card-text">{nftDetails?.description}</p>
            </div>
            <ul className="list-group list-group-flush w-100 align-items-stretch">
              <li
                className="list-group-item text-center"
                style={{ backgroundColor: "#161924", color: "#fff" }}
              >
                Cost:-{" "}
                {nftDetails.saleType == 1
                  ? nftDetails.amount == "0"
                    ? web3.utils.fromWei(nftDetails?.nftCost)
                    : web3.utils.fromWei(nftDetails?.amount)
                  : nftDetails?.nftCost > 1
                  ? web3.utils.fromWei(nftDetails?.nftCost)
                  : nftDetails?.nftCost / 1000000000000000000}
                {"BNB "}
              </li>
              <li
                className="list-group-item text-center d-inline-block "
                style={{ backgroundColor: "#161924", color: "#fff" }}
              >
                Creator:- {nftDetails?.creator?.slice(0, 7)}
                {"...."}
                {nftDetails?.creator?.slice(33)}
              </li>
              {nftDetails?.redeemedBy !== null ? (
                <li
                  className="list-group-item text-center d-inline-block"
                  style={{ backgroundColor: "#161924", color: "#fff" }}
                >
                  MintedBy:- {nftDetails?.redeemedBy?.slice(0, 7)}
                  {"...."}
                  {nftDetails?.redeemedBy?.slice(33)}
                </li>
              ) : (
                ""
              )}
              {nftDetails.saleType == 1 ? (
                Math.floor(Date.now() / 1000) < nftDetails?.endTime &&
                nftDetails.redeemedBy == null ? (
                  <li
                    className="list-group-item text-center d-inline-block"
                    style={{ backgroundColor: "#161924", color: "#fff" }}
                  >
                    Auction End in :<br />
                    <Timer timeLeft={+nftDetails.endTime} />
                  </li>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <li
                className="list-group-item text-center d-inline-block"
                style={{ backgroundColor: "#161924", color: "#fff" }}
              >
                <div className="d-grid gap-2">
                  {!address ? (
                    <LoginpopUp
                      msg={"Connect your Wallet"}
                      btnColor={"btn-danger"}
                    />
                  ) : nftDetails.saleType == 1 ? (
                    Math.floor(Date.now() / 1000) < nftDetails?.endTime ? (
                      <div className="d-grid gap-2 ">
                        <button
                          className="btn backgroundBtn"
                          onClick={
                            () => handleBidding(nftDetails._id)
                            // setOpenBidModal({
                            //   id: nftDetails._id,
                            //   load: !openBidModal.load,
                            // })
                          }
                        >
                          Place a Bid
                        </button>

                        {/* {console.log(bidDetails)} */}
                        {openBidModal.id == nftDetails._id ? (
                          <PlaceBidModal
                            id={nftDetails._id}
                            coupon={nftDetails}
                            setOpenBidModal={setOpenBidModal}
                            openBidModal={openBidModal}
                            getnftList={getNftList}
                          />
                        ) : null}
                      </div>
                    ) : nftDetails.bidder == address ? (
                      <button
                        className="btn backgroundBtn"
                        onClick={() =>
                          claimNFT(nftDetails._id, nftDetails.signature)
                        }
                        disabled={claimLoading == nftDetails._id ? true : false}
                      >
                        {claimLoading == nftDetails._id
                          ? "Loading..."
                          : "Claim NFT"}
                      </button>
                    ) : (
                      <button className="btn backgroundBtn" disabled>
                        Auction Ended
                      </button>
                    )
                  ) : nftDetails.redeemedBy != null ? (
                    <button
                      type="button"
                      className="btn backgroundBtn"
                      onClick={() => redeemCoupon(nftDetails)}
                      disabled={true}
                    >
                      Already Minted
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn backgroundBtn"
                      onClick={() => redeemCoupon(nftDetails)}
                      disabled={false}
                    >
                      Mint NFT
                    </button>
                  )}
                </div>
              </li>
              {/* <button
              type="button"
              className={`btn ${
                nftDetails.saleType == 1
                  ? Math.floor(Date.now() / 1000) > nftDetails?.endTime ||
                    nftDetails.redeemedBy != null
                    ? "btn-secondary"
                    : "btn-primary"
                  : nftDetails.redeemedBy != null
                  ? "btn-secondary"
                  : "btn-primary"
              }`}
              onClick={() => redeemCoupon()}
              disabled={
                loading.id == nftDetails._id
                  ? true
                  : nftDetails.saleType == 1
                  ? Math.floor(Date.now() / 1000) > nftDetails?.endTime ||
                    nftDetails.redeemedBy != null
                    ? true
                    : false
                  : nftDetails.redeemedBy != null
                  ? true
                  : false
              }
            >
              {" "}
              {loading.id == nftDetails._id
                ? "Loading..."
                : nftDetails.saleType == 1
                ? Math.floor(Date.now() / 1000) > nftDetails?.endTime ||
                  nftDetails.redeemedBy != null
                  ? "Already Minted"
                  : "Mint NFT"
                : nftDetails.redeemedBy != null
                ? "Already Minted"
                : "Mint NFT"}{" "}
            </button> */}
            </ul>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ReedemUsingQr;
