import { keccak256, keccakFromString, bufferToHex } from "ethereumjs-util";
import { BigNumber, utils } from "ethers";
import { web3 } from "../../web3";
// {
//   "amount": "0x4563918244f40000",
// "proof": [
//     "0x606c7ff8690b1e6b64edaaa9d70b37f550314537cbe9484208d9ec374228f058",
//     "0xa172809a959a6b7123eb546b7b740b4e6e03549a154c6ddfaf97c53af5cc1763",
//     "0x908fd7bd15d41e9bd133c9035660db7729b3f30cbef6b69c92165234f8fbe309",
//     "0xf9195d4953b67d4aefdef8b9b591169f5e92791b0f9b9260865382fd76fbae98"
// ]
// }

const { isAddress, getAddress } = utils;

// This is the blob that gets distributed and pinned to IPFS.
// It is completely sufficient for recreating the entire merkle tree.
// Anyone can verify that all air drops are included in the tree,
// and the tree has no additional distributions.
// interface AirdropInfo {
//   merkleRoot: string;
//   tokenTotal: string;
//   claims: {
//     [account: string]: {
//       index: number;
//       amount: string;
//       proof: string[];
//     };
//   };
// }

//=======mintparams=======//

// uint256 mintPrice;
// uint256 maxTotalMintableByWallet;
// uint256 startTime;
// uint256 endTime;
// uint256 dropStageIndex; // non-zero
// uint256 maxTokenSupplyForStage;
// uint256 feeBps;
// bool restrictFeeRecipients;
let objmintparams = {
  mintPrice: "1000000000000000",
  maxTotalMintableByWallet: "5",
  startTime: "1687247208",
  endTime: "1688237380",
  dropStageIndex: "1", // non-zero
  maxTokenSupplyForStage: "500",
  feeBps: "1000000000000",
  restrictFeeRecipients: true,
};
// let mintParams = [
//   "1000000000000000",
//   "5",
//   "1687247208",
//   "1688237380",
//   "1",
//   "500",
//   "1000000000000",
//   true,
// ];Hi Darshan, we have created another module , but it will be used in different website (Not ARC frontend) . We will be requiring 3 pages for the same.

export function parseBalanceMap(balanceMap, mintParams) {
  const mapped = Object.keys(balanceMap).reduce((memo, account) => {
    if (!isAddress(account)) {
      throw new Error(`Found invalid address: ${account}`);
    }
    const parsed = getAddress(account);
    if (memo[parsed]) throw new Error(`Duplicate address: ${parsed}`);
    // const parsedNum = BigNumber.from(web3.utils.toWei(balanceMap[account]));
    // if (parsedNum.lte(0))
    //   throw new Error(`Invalid amount for account: ${account}`);
    memo[parsed] = true;
    return memo;
  }, {});
  console.log("mapped", mapped);

  const treeElements = Object.keys(mapped).map((account) => ({
    account,
    mintParams: mintParams,
  }));
  console.log("treeElements", treeElements);
  const tree = new BalanceTree(treeElements);
  console.log("tree root hash", tree.getHexRoot());

  const proofs = treeElements.reduce((memo, { account, amount }, index) => {
    memo[account] = tree.getProof(account, mintParams);
    return memo;
  }, {});

  return {
    merkleRoot: tree.getHexRoot(),
    proofs,
  };
}

export class BalanceTree {
  tree;
  constructor(balances) {
    this.tree = new MerkleTree(
      balances.map(({ account, mintParams }, index) => {
        return BalanceTree.toNode(account, mintParams);
      })
    );
  }

  static verifyProof(account, mintParams, proof, root) {
    let pair = BalanceTree.toNode(account, mintParams);
    for (const item of proof) {
      pair = MerkleTree.combinedHash(pair, item);
    }

    return pair.equals(root);
  }

  // keccak256(abi.encode(account, amount))
  // const encodedParams = solidityKeccak256(
  //   ['address', 'tuple(uint256, uint256, uint256, uint256, uint256, uint256, uint256, bool)'],
  //   [minter, [mintParams.mintPrice, mintParams.maxTotalMintableByWallet, mintParams.startTime, mintParams.endTime, mintParams.dropStageIndex, mintParams.maxTokenSupplyForStage, mintParams.feeBps, mintParams.restrictFeeRecipients]]
  // )

  static toNode(account, mintParams) {
    return Buffer.from(
      utils
        .keccak256(
          utils.defaultAbiCoder.encode(
            [
              "address",
              "tuple(uint256, uint256, uint256, uint256, uint256, uint256, uint256, bool)",
            ],
            [account, mintParams]
          )
        )
        .substr(2),
      "hex"
    );
  }

  getHexRoot() {
    return this.tree.getHexRoot();
  }

  // returns the hex bytes32 values of the proof
  getProof(account, mintParams) {
    return this.tree.getHexProof(BalanceTree.toNode(account, mintParams));
  }
}

export class MerkleTree {
  elements;
  layers;

  constructor(elements) {
    this.elements = [...elements];
    // Sort elements
    this.elements.sort(Buffer.compare);
    // Deduplicate elements
    this.elements = this.bufDedup(this.elements);

    // Create layers
    this.layers = this.getLayers(this.elements);
  }

  getLayers(elements) {
    if (elements.length === 0) {
      throw new Error("empty tree");
    }

    const layers = [];
    layers.push(elements);

    // Get next layer until we reach the root
    while (layers[layers.length - 1].length > 1) {
      layers.push(this.getNextLayer(layers[layers.length - 1]));
    }

    return layers;
  }

  getNextLayer(elements) {
    return elements.reduce((layer, el, idx, arr) => {
      if (idx % 2 === 0) {
        // Hash the current element with its pair element
        layer.push(MerkleTree.combinedHash(el, arr[idx + 1]));
      }

      return layer;
    }, []);
  }

  static combinedHash(first, second) {
    if (!first) {
      return second;
    }
    if (!second) {
      return first;
    }

    return keccak256(MerkleTree.sortAndConcat(first, second));
  }

  getRoot() {
    return this.layers[this.layers.length - 1][0];
  }

  getHexRoot() {
    return bufferToHex(this.getRoot());
  }

  getProof(el) {
    let idx = this.bufIndexOf(el, this.elements);

    if (idx === -1) {
      throw new Error("Element does not exist in Merkle tree");
    }

    return this.layers.reduce((proof, layer) => {
      const pairElement = this.getPairElement(idx, layer);

      if (pairElement) {
        proof.push(pairElement);
      }

      idx = Math.floor(idx / 2);

      return proof;
    }, []);
  }

  getHexProof(el) {
    const proof = this.getProof(el);

    return this.bufArrToHexArr(proof);
  }

  getPairElement(idx, layer) {
    const pairIdx = idx % 2 === 0 ? idx + 1 : idx - 1;

    if (pairIdx < layer.length) {
      return layer[pairIdx];
    } else {
      return null;
    }
  }

  bufIndexOf(el, arr) {
    let hash;

    // Convert element to 32 byte hash if it is not one already
    if (el.length !== 32 || !Buffer.isBuffer(el)) {
      hash = keccakFromString(el);
    } else {
      hash = el;
    }

    for (let i = 0; i < arr.length; i++) {
      if (hash.equals(arr[i])) {
        return i;
      }
    }

    return -1;
  }

  bufDedup(elements) {
    return elements.filter((el, idx) => {
      return idx === 0 || !elements[idx - 1].equals(el);
    });
  }

  bufArrToHexArr(arr) {
    if (arr.some((el) => !Buffer.isBuffer(el))) {
      throw new Error("Array is not an array of buffers");
    }

    return arr.map((el) => "0x" + el.toString("hex"));
  }

  static sortAndConcat(...args) {
    return Buffer.concat([...args].sort(Buffer.compare));
  }
}
