import React, { useEffect, useState } from "react";
import { getAirDropTokenInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { web3 } from "../web3";
import Template from "../csvfileTemplate/AirdropTokens.csv";
import useWeb3AccountData from "../helpers/customHooks";
const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const AirDropTokens = () => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [airDropData, setAirDropData] = useState({
    stringURI: "",
    file: "",
  });
  const fileReader = new FileReader();
  const [loading, setLoading] = useState(false);
  const [dataArray, setDataArray] = useState([]);

  //onChange event called this function
  const handleChange = async (event) => {
    let nObj = await airDropData;
    if (event.target.name == "file") {
      nObj[event.target.name] = await event.target.files[0];
    } else {
      nObj[event.target.name] = await event.target.value;
    }
    console.log("nObj", nObj);
    setAirDropData(nObj);
  };

  //on submittion DropNFts function call
  const DropNFts = async () => {
    if (airDropData.file == "") toast.error("Please Choose CSV File...");
    else {
      setLoading(true);
      let res1 = [];
      let res2 = [];
      let result = null;
      const airDropContractInstance = await getAirDropTokenInstance();
      let addresses = null;
      if (airDropData.file) {
        fileReader.onload = async function (event) {
          const text = await event.target.result;
          //  console.log(event);
          //  console.log(event.target.result);
          var array = await text.toString().split("\r\n");
          // console.log(array.toString().split(","));
          addresses = await array
            .slice(1, array.length - 1)
            .toString()
            .split(",");

          for (let i = 0; i < addresses.length; i++) {
            if (i % 2 == 0) {
              await res1.push(addresses[i]);
            } else {
              await res2.push(addresses[i]);
            }
          }
          result = await [res1, res2];
          // console.log("Result 1: ", res1, "Result 2: ", res2);
          console.log(result);
          await airDropContractInstance.methods
            .airDrop(res1)
            .send({ from: address })
            .on("transactionHash", (hash) => {
              toast.info("Transaction is Processing...");
            })
            .on("receipt", (receipt) => {
              console.log("on receipt ", receipt);
              toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
              setLoading(false);
            })
            .on("error", (error) => {
              console.log("on error ", error);
              toast.error("Transaction Failed!");
              setLoading(false);
            });
          // console.log(obj);
          // var data = [];
          // for (const r of array) {
          //   let row = r.toString().split(",");
          //   data.push(row);
          // }
          // console.log(data);
          // var heading = await data[0];
          // setDataArray(array.slice(heading.length));
        };

        fileReader.readAsText(airDropData.file);
      }
    }
  };
  console.log("airDropData", airDropData);
  return (
    <>
      <div
        className="container my-3"
        // style={{
        //   margin: "50px 200px 50px 200px",
        // }}
      >
        <h3 className="my-4">AirDrop</h3>
        {/* <div className="row">
        <div className="col-12 col-md-6 pe-5 pb-4"></div>
        </div> */}
        <div className="row">
          <div className="col-12 col-md-6 pe-5 pb-4">
            <div className="form-group">
              <label for="title">StringURI</label>
              <input
                className="form-control"
                placeholder="Enter String URI here..."
                id="stringURI"
                type={"text"}
                name="stringURI"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-12 col-md-6 pe-5 pb-4">
            <div className="form-group">
              <label for="title" className="d-flex justify-content-between">
                Import CSV File:{" "}
                <span>
                  <a
                    href={Template}
                    download="Airdrop_CSV_Template"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Template
                  </a>
                </span>
              </label>
              <input
                className="form-control"
                placeholder="Import CSV File"
                id="file"
                type={"file"}
                name="file"
                accept=".csv"
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="">
          <button
            type="button"
            className="btn btnBackground px-5 btn-lg"
            onClick={DropNFts}
            disabled={loading}
          >
            {" "}
            {loading ? "Loading..." : "AirDrop Token"}{" "}
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AirDropTokens;
