import React, { useState, useEffect } from 'react'
import './App.css'
import Header from './Header'
import Web3 from 'web3'
import { Route, Routes } from 'react-router-dom'
import MainLayout from './components/MainLayout'
import AirDropTokens from './components/AirDropTokens'
import ReedemCoupon from './components/ReedemCoupon'
import LazymintNft from './components/LazymintNft'
import ReedemNft from './components/ReedemNft'
import CreateMerkleDrop from './components/CreateMerkleDrop'
import ClaimToken from './components/ClaimMerkleToken'
import Sidebar from './components/Sidebar'
import CreateERC20Token from './components/CreateERC20Token'
import Dashboard from './components/Dashboard'
import DistributedCoupon from './components/DistributedCoupon'
import CreateSweepstakes from './components/CreateSweepstakes'
import ViewDistributedCoupon from './components/ViewDistributedCoupon'
import ParticipateInSweepstakes from './components/ParticipateInSweepstakes'
import ReedemUsingQr from './components/ReedemUsingQr'
import MetricNFT from './components/MetricNFT'
import SeaDrop from './components/SeaDrop/SeaDrop'
import CreateFolderIPFS from './components/createFolderIPFS'
import Login from './pages/Login'
import { useNavigate } from 'react-router-dom'
import MintAllowList from './components/MintAllowList'
import AdminApproval from './components/SeaDrop/AdminApproval'
import CreateCollection from './components/CreateCollection'
import Collection from './components/Collections'
import { initWeb3Onboardd } from './helpers/newWalletConfigs'

function App() {
  const [web3Onboard, setWeb3Onboard] = useState()
  useEffect(() => {
    setWeb3Onboard(initWeb3Onboardd)
  }, [])
  // const { address } = useAccount();
  // const provider = usePublicClient();
  const [currPath, setCurrPath] = useState(window.location.pathname)
  let authToken = localStorage.getItem('arcAuthToken')
  const navigate = useNavigate()
  let idd = currPath.slice(15)
  // console.log("signer?.provider", provider);
  // let idd = localStorage.getItem("lazyNftId");
  // useEffect(() => {
  //   metamaskConnectInit(provider);
  //   setCurrPath(window.location.pathname);
  // }, [window.location.pathname]);
  console.log('currPath: ', currPath)
  return (
    <div className="bodyBG" style={{ minHeight: '100vh' }}>
      <Routes>
      {/* <Dashboard /> */}
        <Route path="/login" element={<Login />} />
      </Routes>
      {currPath == '/login' ? '' : <Header />}
      {/* currPath != "/login" ? ( */}
      {currPath != '/login' && currPath.slice(0, 14) !== `/redeemLazyNft` && (
        <Sidebar>
          <Routes>
            <Route exact path="/" element={<Dashboard/>} />
            <Route exact path="/createCoupon" element={<MainLayout />} />
            <Route
              exact
              path="/distributedCoupon"
              element={<DistributedCoupon />}
            />
            <Route
              exact
              path="/createERC20Token"
              element={<CreateERC20Token />}
            />
            <Route
              path="/create-directory-ipfs"
              element={<CreateFolderIPFS />}
            />
            <Route path="/seaDrop" element={<SeaDrop />} />
            {/* <Route path="/collections" element={<Collection />} /> */}
            <Route path="/create-collections" element={<CreateCollection />} />
            <Route path="/mintAllowList" element={<MintAllowList />} />
            <Route path="/adminApproval" element={<AdminApproval />} />
            <Route path="/sweepstakes" element={<CreateSweepstakes />} />
            <Route path="/createMerkleDrop" element={<CreateMerkleDrop />} />
            <Route path="/lazymintNft" element={<LazymintNft />} />
            <Route path="/redeemCoupon" element={<ReedemCoupon />} />
            <Route path="/redeemNft" element={<ReedemNft />} />
            <Route
              path="/view-distributed-coupon"
              element={<ViewDistributedCoupon />}
            />
            <Route
              path="/participate-in-sweepstakes"
              element={<ParticipateInSweepstakes />}
            />
            <Route path="/metric-NFT-reward" element={<MetricNFT />} />
            <Route path="/airdropTokens" element={<AirDropTokens />} />
            <Route path="/claimMerkleDrop" element={<ClaimToken />} />
          </Routes>
        </Sidebar>
      )}
      {/* )
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes> */}
      {/* ------lazyNft_Qrcode------- */}
      <Routes>
        <Route
          path="/redeemLazyNft/:id"
          element={<ReedemUsingQr idd={idd} />}
        />
      </Routes>
    </div>
  )
}

export default App
