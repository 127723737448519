import React, { useEffect } from 'react'
import { useState } from 'react'
import { getlazyNFTTokenInstance } from '../helpers/functions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { web3 } from '../web3'
import { services } from '../services'
import Loader from '../assets/loading_arc.gif'
import Timer from './timer'
import QRCode from 'react-qr-code'
import ModalDialog from '../modal/QrpopUp'
import PlaceBidModal from '../modal/placeBidModal'
import useWeb3AccountData from '../helpers/customHooks'
// import Loader from "../assets/loading_arc.gif";
import { Loader1 } from "./loader";
import "../App.css";



  


const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{' '}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  )
}

const ReedemNft = () => {
  // const address = {};
  const [address, isLoading, isConnected] = useWeb3AccountData()
  const [couponDetails, setCouponDetails] = useState({
    couponCode: null,
    reedemType: 0
  })
  const [loading, setLoading] = useState({ id: null, load: false })
  const [emptyLoading, setEmptyLoading] = useState(true)
  const [openBidModal, setOpenBidModal] = useState({
    id: null,
    load: false,
  });
  const [nftList, setNftList] = useState([]);
  const [bidDetails, setBidDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [showQrCode, setShowQrCode] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  console.log("page: " + page);
  
  useEffect(() => {
    getnftList()
  }, [address, page])

  const getnftList = async () => {
    // if (address) {
    const LazyNFTInstance = await getlazyNFTTokenInstance()
    let list = []
    const nftListRes = await services.get(`/nft/list?page=${page}&limit=10`)
    if (nftListRes?.status == 200) {
      for (let i = 0; i < nftListRes.data.data.nftList.length; i++) {
        let getDetails = await LazyNFTInstance.methods
          .getBidDetails(nftListRes.data.data.nftList[i].signature)
          .call()
        await list.push({ ...nftListRes.data.data.nftList[i], ...getDetails })
      }
      console.log('list', list)
      await setNftList(list)
      await setEmptyLoading(false)
    }
    await setEmptyLoading(false)
  }
  // console.log("couponList", nftList);

  const handleChange = e => {
    setCouponDetails({ ...couponDetails, [e.target.name]: e.target.value })
  }

  const redeemCoupon = async coupon => {
    setLoading({ id: coupon._id, load: true })
    let obj = await [
      coupon.name,
      coupon.nftUri,
      coupon.creator,
      coupon.nftCost,
      address,
      coupon.signature,
      coupon.saleType,
      coupon.startTime,
      coupon.endTime
    ]
    // console.log("obj", obj);
    const couponContractInstance = await getlazyNFTTokenInstance()
    // console.log("contractInstance", couponContractInstance);
    console.log('coupn contract addresses', couponContractInstance)
    await couponContractInstance.methods
      .mint(obj)
      .send({
        from: address,
        value: coupon.nftCost
      })
      .on('transactionHash', hash => {
        // console.log("transaction hash : ", hash);
        toast.info('Transaction is Processing...')
      })
      .on('receipt', receipt => {
        console.log('on receipt ', receipt)
        getnftList()
        toast.success(<SuccessPopUp txn={receipt.transactionHash} />)
        setLoading({ id: null, load: false })
      })
      .on('error', error => {
        console.log('on error ', error)
        toast.error('Transaction Failed!')
        setLoading({ id: null, load: false })
      })
    // }
  }

  const claimNFT = async (id, sign) => {
    try {
      console.log(id, sign)
      setClaimLoading(id)
      const LazyNFTInstance = await getlazyNFTTokenInstance()
      await LazyNFTInstance.methods
        .claimNFT(sign)
        .send({
          from: address
        })
        .on('transactionHash', hash => {
          // console.log("transaction hash : ", hash);
          toast.info('Transaction is Processing...')
        })
        .on('receipt', receipt => {
          console.log('on receipt ', receipt)
          getnftList()
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />)
          setClaimLoading(false)
        })
        .on('error', error => {
          console.log('on error ', error)
          toast.error('Transaction Failed!')
          setClaimLoading(false)
        })
    } catch (error) {
      console.log('on error ', error)
      setClaimLoading(false)
    }
  }
  const handleBidding = id => {
    if (!address) toast.error('Please Connect your Wallet first!')
    else {
      setOpenBidModal({
        id: id,
        load: !openBidModal.load
      })
    }
  }
  console.log('bidDetails', bidDetails)
  return (
    <>
      <div
        className="container-fluid my-5"
        // style={{
        //   margin: "50px 10px auto 10px",
        // }}
      >
        <h2 className="fw-bold">NFT Marketplace</h2>
        {emptyLoading ? (
          <div
            className="text-center mt-5"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {/* <img src={Loader} width={200} height={200} /> */}
            <Loader1 />
          </div>
        ) : nftList?.length !== 0 ? (
          <div className="table-responsive-sm">
            <div className="row text-center nft-header  py-2 fw-bold fs-6">
              <div className="col-1 text-start">SN</div>
              <div className="col-2 t">NFT Image</div>
              <div className="col-2">Name</div>
              <div className="col-3">Description</div>
              <div className="col-1">NFT Cost</div>
              <div className="col-1">Creator</div>
              <div className="col-2">Action</div>
            </div>
            {nftList &&
              nftList.map((coupon, key) => (
                <div
                  className="row text-center my-1 p-1 fs-6"
                  style={{
                    backgroundColor:
                      coupon.saleType == 1
                        ? Math.floor(Date.now() / 1000) > coupon?.endTime ||
                          coupon.redeemedBy != null
                          ? '#435B66'
                          : '#2D4356'
                        : coupon.redeemedBy != null
                        ? '#435B66'
                        : '#2D4356',
                    color: '#fff'
                  }}
                >
                  <div className="col-1 text-start">{key + 1}</div>
                  <div className="col-2">
                    <img src={coupon?.imageUrl} width={130} height={130} alt=""/>
                  </div>
                  <div className="col-2 ">
                    {coupon?.name ? coupon?.name : 'NFT Name'}
                  </div>

                  <>
                  <div className="col-3">
                    {coupon?.description}
                    {'...'}
                  </div>
                </>
                  
                  {/* <div className="col-3">{coupon?.description} {"..."}
                  </div> */}
                  
                  
                  <div className="col-1 ">
                    {coupon.saleType == 1
                      ? coupon.amount == '0'
                        ? web3.utils.fromWei(coupon?.nftCost)
                        : web3.utils.fromWei(coupon?.amount)
                      : coupon?.nftCost > 1
                      ? web3.utils.fromWei(coupon?.nftCost)
                      : coupon?.nftCost / 1000000000000000000}
                  </div>
                  <div className="col-1 ">
                    {coupon?.creator.slice(0, 4)}
                    {'....'}
                    {coupon?.creator.slice(38)}
                  </div>
                  <div className="col-2 ">
                      {coupon.saleType == 1 ? (
                        Math.floor(Date.now() / 1000) < coupon?.endTime &&
                        coupon.redeemedBy == null ? (
                          <div>
                            Auction End in :<br />
                            <Timer timeLeft={+coupon.endTime} />
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    {coupon.saleType == 1 ? (
                      Math.floor(Date.now() / 1000) < coupon?.endTime ? (
                        <div>
                          <button
                            className="btn btnNFTBackground"
                            onClick={() => handleBidding(coupon._id)}
                          >
                            PLACE A BID
                          </button>

                          {console.log(bidDetails)}
                          {openBidModal.id == coupon._id ? (
                            <PlaceBidModal
                              id={coupon._id}
                              coupon={coupon}
                              setOpenBidModal={setOpenBidModal}
                              openBidModal={openBidModal}
                              getnftList={getnftList}
                            />
                          ) : null}
                        </div>
                      ) : coupon.bidder == address ? (
                        <button
                          className="btn btnNFTBackground"
                          onClick={() => claimNFT(coupon._id, coupon.signature)}
                          disabled={claimLoading == coupon._id ? true : false}
                        >
                          {claimLoading == coupon._id
                            ? 'Loading...'
                            : 'CLAIM NFT'}
                        </button>
                      ) : (
                        <button
                          className="btn btnNFT1Background border-0"
                          disabled
                        >
                          AUCTION ENDED
                        </button>
                      )
                    ) : coupon.redeemedBy != null ? (
                      <button
                        type="button"
                        className="btn btnNFT1Background border-0"
                        onClick={() => redeemCoupon(coupon)}
                        disabled={true}
                      >
                        ALREADY MINTED
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btnNFTBackground border-0"
                        onClick={() => redeemCoupon(coupon)}
                        disabled={false}
                      >
                        MINT NFT
                      </button>
                    )}
                    <div>
                      <ModalDialog id={coupon._id} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <h2 style={{ marginLeft: '554px' }}>
            No Data found in Current Page!!
          </h2>
        )}
        {/* <div className="row">
          {nftList &&
            nftList.map((coupon, i) => (
              <div className={`col-5 m-3 p-2 bg-light text-dark`} key={i}>
                <div className="row border">
                  <div className="col-12 p-3 text-center">
                    <img src={coupon?.imageUrl} width={100} height={100} />
                  </div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td className=" text-break">
                          {coupon?.name ? coupon?.name : "NFT Name"}
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td className=" text-break">{coupon?.description}</td>
                      </tr>
                      <tr>
                        <td>Validity</td>
                        <td className=" text-break">{coupon?.validity} Days</td>
                      </tr>
                      <tr>
                        <td>Coupon Cost</td>
                        <td className=" text-break">{coupon?.couponCost}</td>
                      </tr>
                      <tr>
                        <td>Creator</td>
                        <td className=" text-break">{coupon?.creator}</td>
                      </tr>
                      <tr>
                        <td>Minted By</td>
                        <td className=" text-break">
                          {coupon?.txnThroughOwner
                            ? "Owner Wallet"
                            : "User Wallet"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-12 pb-3">
                    <div className="form-group">
                      <label className="p-1" for="CouponCode">
                        CouponCode:
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter CouponCode"
                        id="CouponCode"
                        type={"number"}
                        name="couponCode"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mt-1">
                      <label className="p-1" for="CouponCode">
                        Reedem Coupon Token Type:
                      </label>
                      <select
                        className="form-select form-select"
                        aria-label=".form-select-lg example"
                        name="reedemType"
                        onChange={handleChange}
                        required
                      >
                        <option selected disabled>
                          Select an Option
                        </option>
                        <option value={0}>ERC Token</option>
                        <option value={1}>NFT Token</option>
                      </select>
                    </div>
                    <br />
                    <div className="d-grid gap-2 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => redeemCoupon(coupon)}
                        disabled={loading.id == coupon._id ? true : false}
                      >
                        {" "}
                        {loading.id == coupon._id
                          ? "Loading..."
                          : "Redeem Coupon"}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div> */}
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={page > 2 ? 'page-item' : 'page-item disabled'}>
            <a
              className="page-link"
              href="#"
              tabindex="-1"
              aria-disabled="true"
              onClick={() => setPage(page - 1)}
            >
              Previous
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => setPage(1)}>
              1
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => setPage(2)}>
              2
            </a>
          </li>
          {page > '2' ? (
            <>
              <li className="page-item">
                <a className="page-link" href="#">
                  ...
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  {page}
                </a>
              </li>
            </>
          ) : null}
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => setPage(page + 1)}>
              Next
            </a>
          </li>
        </ul>
      </nav>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default ReedemNft
