import React, { useEffect } from "react";
import { useState } from "react";
import { getSweepstakesInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "react-tooltip/dist/react-tooltip.css";
// import { Tooltip } from "react-tooltip";
// import { web3 } from "../web3";
import { storeNFT } from "../config/storage";
import { services } from "../services";
import getContractAddresses from "../contractData/address";
import Timer from "./timer";
import Loader from "../assets/loading_arc.gif";
import useWeb3AccountData from "../helpers/customHooks";
// import Loader from "../assets/loading_arc.gif";
import { Loader1 } from "./loader";
import Web3 from "web3";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const ParticipateInSweepstakes = ({ header }) => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [ipfsData, setIpfsData] = useState({
    name: "",
    prize: "",
    starttime: "",
    endtime: "",
  });
  const [couponCode, setCouponCode] = useState(null);
  const [sweepstakeDetails, setSweepstakeDetails] = useState(null);
  const [playerDetails, setPlayerDetails] = useState([]);
  const [owner, setOwner] = useState("");
  const [loadingID, setLoadingID] = useState(null);
  const [loadingClaimID, setLoadingClaimID] = useState(null);
  let crntTime = Math.floor(Date.now() / 1000);

  useEffect(() => {
    playersAddresses();
    init();
  }, []);
  const init = async () => {
    const getSStakeInstance = await getSweepstakesInstance();
    let obj = [];
    let getOwner = await getSStakeInstance.methods.getOwner().call();
    setOwner(getOwner);
    let stakeCount = await getSStakeInstance.methods.stakeCount().call();
    console.log(getSStakeInstance);
    for (let index = 1; index < stakeCount; index++) {
      let getDetails = await getSStakeInstance.methods
        .stakeDetails(index)
        .call();
     obj.push({ ...getDetails, index: index });
    }
    await setSweepstakeDetails(obj);

    console.log("getDetails", obj);
  };

  const changeIPFSData = async (e) => {
    setIpfsData({ ...ipfsData, [e.target.name]: e.target.value });
  };

  console.log(ipfsData);

  const handleWinner = async (index, endDate) => {
    try {
      if (crntTime < endDate) toast.error("SweepStakes not ended yet!");
      else {
        const getSStakeInstance = await getSweepstakesInstance();
        await getSStakeInstance.methods
          .selectWinner(index)
          .send({ from: address })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", async (receipt) => {
            console.log("on receipt ", receipt);
            init();
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setLoadingID(null);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setLoadingID(null);
          });
      }
    } catch (error) {
      console.log("on error ", error);
      setLoadingID(null);
    }
  };
  const playersAddresses = async () => {
    const getSStakeInstance = await getSweepstakesInstance();
    let obj = [];
    let stakeCount = await getSStakeInstance.methods.stakeCount().call();
    console.log(getSStakeInstance);
    for (let index = 1; index < stakeCount; index++) {
      let players = await getSStakeInstance.methods.getPlayers(index).call();
      obj.push({ arrAddress: players, index: index });
    }
    console.log("arr", obj);
    setPlayerDetails(obj);
    // return arr;
  };
  console.log("couponCode", couponCode);

  const handleAddParticipate = async (index) => {
    try {
      let exist = undefined;
      exist = await playerDetails[index - 1].arrAddress.find(
        (addr) => addr == address
      );
      if (exist != undefined) toast.error("You are already a participant!");
      else {
        console.log("index", index, "exist", exist);
        setLoadingID(index);
        const getSStakeInstance = await getSweepstakesInstance();
        console.log(getSStakeInstance);
        await getSStakeInstance.methods
          .enterSweepstake(index)
          .send({ from: address })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", async (receipt) => {
            console.log("on receipt ", receipt);
            playersAddresses();
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setLoadingID(null);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setLoadingID(null);
          });
      }
    } catch (error) {
      console.log("on error ", error);
      // toast.error("Transaction Failed!");
      setLoadingID(null);
    }
  };

  const claimWinner = async (index) => {
    try {
      // if (crntTime < endDate) toast.error("SweepStakes not ended yet!");
      // else {
      setLoadingClaimID(index);
      const getSStakeInstance = await getSweepstakesInstance();
      await getSStakeInstance.methods
        .claimPrize(index)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log("on hash ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", async (receipt) => {
          console.log("on receipt ", receipt);
          init();
          toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
          setLoadingClaimID(null);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoadingClaimID(null);
        });
      // }
    } catch (error) {
      console.log("on error ", error);
      setLoadingClaimID(null);
    }
  };
  return (
    <>
      <div className="container my-4">
        <h2 className="fw-bold">Sweepstake Participants</h2>

        <div className="container row">
          <></>
          {sweepstakeDetails?.length == undefined ? (
            <div
              className="text-center mt-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <img src={Loader} width={200} height={200} /> */}
              <Loader1/>
            </div>
          ) : sweepstakeDetails?.length > 0 ?
           (
            sweepstakeDetails?.map((sweepstake, key) => (
              <div class="card">
             
                {/* <div
                  className="cardBGColor p-3"
                  style={{ backgroundColor: "#161824", color: "#fff" }}
                > */}
                  <table className="mb-3 w-100">
                    <thead></thead>
                    <tbody className="text-break fs-6">
                      <tr>
                        <th style={{"width":"10rem"}}>Name</th>
                        <td>{sweepstake.name}</td>
                      </tr>
                      <tr>
                        <th>Winning Prize</th>
                        <td>{Web3.utils.fromWei(sweepstake?.prize,'ether')} BNB</td>
                      </tr>
                      <tr>
                        <th>Start Time</th>
                        <td>
                          {new Date(Number(sweepstake?.startDate) * 1000).toString()}
                        </td>
                      </tr>
                      <tr>
                        <th>End Time</th>
                        <td>
                          {new Date(Number(sweepstake?.endDate) * 1000).toString()}
                        </td>
                      </tr>
                      <tr>
                        <th>Winner</th>
                        <td className="">
                          {sweepstake.winner !=
                          "0x0000000000000000000000000000000000000000"
                            ? sweepstake.winner
                            : "Not Announced Yet!"}
                        </td>
                      </tr>
                      <tr>
                        <th>Completed</th>
                        <td>{sweepstake?.isCompleted ? "Yes" : "No"}</td>
                      </tr>
                      {sweepstake.winner !=
                      "0x0000000000000000000000000000000000000000" ? (
                        sweepstake.winner != address ? (
                          <tr>
                            <td>Claim Prize</td>
                            <td>
                              <button
                                className="btn btn-success"
                                onClick={() => claimWinner(sweepstake.index)}
                              >
                                Claim Prize
                              </button>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      {sweepstake?.winner ==
                      "0x0000000000000000000000000000000000000000" ? (
                        owner == address ? (
                          <tr>
                            <th>Get Winner</th>
                            <td>
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  handleWinner(
                                    sweepstake?.index,
                                    sweepstake?.endDate
                                  )
                                }
                              >
                                GET WINNER
                              </button>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      ) : sweepstake?.winner == address ? (
                        <tr>
                          <th>Claim Prize</th>
                          <td>
                            {sweepstake.isCompleted ? (
                              <button className="btn btn-secondary" disabled>
                                PRIZE CLAIMED
                              </button>
                            ) : (
                              <button
                                className="btn btn-success"
                                onClick={() => claimWinner(sweepstake?.index)}
                                disabled={
                                  loadingClaimID == sweepstake?.index
                                    ? true
                                    : false
                                }
                              >
                                {loadingClaimID == sweepstake?.index
                                  ? "Loading..."
                                  : "CLAIM PRIZE"}
                              </button>
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  <div className="">
                    <button
                      type="button"
                      className="btn btnBackground border-0 btn-lg px-5 fw-bold"
                      onClick={() => handleAddParticipate(sweepstake?.index)}
                      disabled={
                        loadingID == sweepstake?.index
                          ? true
                          : crntTime < +Number(sweepstake?.startDate) ||
                            crntTime > +Number(sweepstake?.endDate)
                          ? true
                          : false
                      }
                    >
                      {" "}
                      {console.log(crntTime, +Number(sweepstake?.startDate))}
                      {loadingID == sweepstake?.index ? (
                        <div>
                          Loading{" "}
                          <span
                            className="spinner-grow  text-light spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          <span
                            className="spinner-grow  text-light spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          <span
                            className="spinner-grow  text-light spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      ) : crntTime < +Number(sweepstake?.startDate) ? (
                        <Timer timeLeft={+Number(sweepstake?.startDate)} />
                      ) : crntTime > +Number(sweepstake?.endDate) ? (
                        "SWEEPSTAKE CLOSED"
                      ) : (
                        "PARTICIPATE IN SWEEPSTAKE"
                      )}{" "}
                    </button>
                  </div>
                  {}
                  <div className="w-100 p-1">
                    <div className="fw-bold my-3">Registed players</div>
                    <div className="row">
                      {playerDetails?.map((address, key) =>
                        address?.index == sweepstake?.index
                          ? address?.arrAddress.length > 0
                            ? address?.arrAddress.map((player, key) => (
                                <div
                                  className="fw-light text-break py-0 px-4"
                                  key={key}
                                >
                                  {player}
                                </div>
                              ))
                            : ""
                          : ""
                      )}
                    </div>
                  </div>
                {/* </div> */}
              </div> // card div
            
            ))
          )
           : (
            <div className="text-center fs-1">No Current Sweepstakes Found</div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default ParticipateInSweepstakes;
