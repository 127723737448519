import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSeaDropInstance,
  getERC721SeaDropInstance,
  csvFileToJson,
} from "../helpers/functions";
import getContractAddresses from "../contractData/address";
import { useNavigate } from "react-router-dom";
import { parseBalanceMap } from "./SeaDrop/seadropmerkle";
import useWeb3AccountData from "../helpers/customHooks";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const MintAllowList = () => {
  const fileReader = new FileReader();
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const navigate = useNavigate();
  const [seaDropInputs, setSeaDropInputs] = useState({
    nftContract: "",
    feeRecipient: "",
    minterIfNotPayer: "",
    quantity: "",
  });
  const [dropInputs, setDropInputs] = useState({
    mintPrice: "",
    maxTotalMintableByWallet: "",
    startTime: "",
    endTime: "",
    dropStageIndex: "",
    maxTokenSupplyForStage: "",
    feeBps: "",
    restrictFeeRecipients: null,
  });
  const [mintLoading, setMintLoading] = useState(false);
  const [dropLoading, setDropLoading] = useState(false);
  const { seaDropContract } = getContractAddresses();
  const seaDropInstance = getSeaDropInstance();
  console.log(
    "seaDropContract",
    seaDropContract,
    "seaDropInputs",
    seaDropInputs
  );
  const createSeaDropRoot = (e) => {
    fileReader.onload = async function (event) {
      const text = event.target.result;
      // let jsonArray = await csvFileToArray(text);
      let jsonArray = await csvFileToJson(text);
      console.log(jsonArray);
      let hash = parseBalanceMap(jsonArray);
      console.log(hash);
    };

    fileReader.readAsText(e.target.files[0]);
  };
  const handleChange = async (e) => {
    setSeaDropInputs({ ...seaDropInputs, [e.target.name]: e.target.value });
  };
  const handleDropChange = async (e) => {
    if (e.target.name == "restrictFeeRecipients")
      setDropInputs({
        ...dropInputs,
        [e.target.name]: e.target.value == "true" ? true : false,
      });
    else if (e.target.name == "startTime" || e.target.name == "endTime")
      setDropInputs({
        ...dropInputs,
        [e.target.name]: new Date(e.target.value).valueOf() / 1000,
      });
    else setDropInputs({ ...dropInputs, [e.target.name]: e.target.value });
  };
  const handleParams = async () => {
    try {
      if (!address) toast.error("Please Connect your Wallet First!");
      else {
        setDropLoading(true);
        let nftContract = "0xA721D143270A90fbEf1E9572e5754b4f06da38E5";
        let feeRecipient = "0x863Ce3D6Aa68851aF2AdB09A479369326C3B1E13";
        let minterIfNotPayer = "0x0000000000000000000000000000000000000000";
        let quantity = "1";
        let params = await [
          "1000000000000000",
          "5",
          "1687247208",
          "1688237380",
          "1",
          "500",
          "1000",
          true,
        ];

        let proof = await [
          "0xa563a0fe984509b6e8f0f4ea55b02c13d53de6efb22900c9265b154820186b4b",
          "0xe6d01287d0f0293294fdbf4b19784ee9c0d0daa7884cc6f8b8d8dc5f7bea1df5",
          "0x30439fa881791c0d809063636a3f9d0e14a6f85047d7440c45e3593f954a150d",
          "0x49b6f1f44da353363252984ec2ba79002001f4865e326f590492edd3cc924db7",
        ];
        console.log("params: ", params);
        // setMintLoading(false);
        const seaDropInstance = await getSeaDropInstance();
        await seaDropInstance.methods
          .mintAllowList(
            nftContract,
            feeRecipient,
            minterIfNotPayer,
            quantity,
            params,
            proof
          )
          .send({ from: address, value: "1000000000000000" })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", async (receipt) => {
            console.log("on receipt ", receipt);
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setDropLoading(false);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setDropLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleMintPublic = async () => {
    try {
      if (
        seaDropInputs.nftContract == "" ||
        seaDropInputs.feeRecipient == "" ||
        seaDropInputs.minterIfNotPayer == "" ||
        seaDropInputs.quantity == ""
      )
        toast.error("Please Enter all required fields");
      else if (!address) toast.error("Please Connect your Wallet First!");
      else {
        setMintLoading(true);
        let obj = await [
          seaDropInputs.nftContract,
          seaDropInputs.feeRecipient,
          seaDropInputs.minterIfNotPayer,
          seaDropInputs.quantity,
        ];
        let params = await [
          dropInputs.mintPrice,
          dropInputs.maxTotalMintableByWallet,
          dropInputs.startTime,
          dropInputs.endTime,
          dropInputs.dropStageIndex,
          dropInputs.maxTokenSupplyForStage,
          dropInputs.feeBps,
          dropInputs.restrictFeeRecipients,
        ];
        const seaDropInstance = await getSeaDropInstance();
        console.log("seaDropInstance", seaDropInstance);
        let mintPrice = await seaDropInstance.methods.getPublicDrop(
          "0xA721D143270A90fbEf1E9572e5754b4f06da38E5"
        );
        console.log("mintPrice", mintPrice);
        await seaDropInstance.methods
          .mintAllowList(...obj, params)
          .send({ from: address, value: 2 })
          .on("transactionHash", (hash) => {
            console.log("on hash ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", async (receipt) => {
            console.log("on receipt ", receipt);
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setMintLoading(false);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setMintLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(seaDropInputs, "dropInputs", dropInputs);
  //adminLogin auth
  let authToken = localStorage.getItem("arcAdminAuthToken");
  return (
    <>
      {authToken ? (
        <div className="container my-5  text-start">
          <h3>Mint Allow List</h3>
          <div className="form-group mt-2">
            <label htmlFor="cost">NftContract :</label>
            <input
              className="form-control"
              placeholder="Enter nftContract Address here..."
              id="minter"
              type={"text"}
              name="nftContract"
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="cost">FeeRecipient :</label>
            <input
              className="form-control"
              placeholder="Enter feeRecipient Address here..."
              id="minter"
              type={"text"}
              name="feeRecipient"
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="cost">MinterIfNotPayer :</label>
            <input
              className="form-control"
              placeholder="Enter minterIfNotPayer Address here..."
              id="minter"
              type={"text"}
              name="minterIfNotPayer"
              onChange={(e) => handleChange(e)}
              required
            />
          </div>

          <div className="form-group mt-2">
            <label htmlFor="no of coupon">Quantity:</label>
            <input
              className="form-control"
              placeholder="Enter Quantity here..."
              id="quantity"
              type={"text"}
              name="quantity"
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label htmlFor="cost">Mint Params:</label>
            <div className="px-4 border pb-2">
              <div className="form-group mt-2">
                <label htmlFor="cost">Mint Price:</label>
                <input
                  className="form-control"
                  placeholder="Enter NFT Mint Price here..."
                  id="mintPrice"
                  type={"number"}
                  name="mintPrice"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="validity">MaxTotal Mintable By Wallet:</label>
                <input
                  className="form-control"
                  placeholder="Enter MaxTotal Mintable By Wallet here..."
                  id="maxTotalMintableByWallet"
                  type={"number"}
                  name="maxTotalMintableByWallet"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="validity">Start Time:</label>
                <input
                  className="form-control"
                  placeholder="Enter Start Time here..."
                  id="startTime"
                  type={"datetime-local"}
                  name="startTime"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="validity">End Time:</label>
                <input
                  className="form-control"
                  placeholder="Enter Start Time here..."
                  id="endTime"
                  type={"datetime-local"}
                  name="endTime"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="restrictFeeRecipients">Drop Stage Index:</label>
                <input
                  className="form-control"
                  placeholder="Enter Drop Stage Index here..."
                  id="dropStageIndex"
                  type={"number"}
                  name="dropStageIndex"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="restrictFeeRecipients">
                  MaxToken Supply For Stage:
                </label>
                <input
                  className="form-control"
                  placeholder="Enter feeBps here..."
                  id="maxTokenSupplyForStage"
                  type={"number"}
                  name="maxTokenSupplyForStage"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="restrictFeeRecipients">FeeBps:</label>
                <input
                  className="form-control"
                  placeholder="Enter feeBps here..."
                  id="feeBps"
                  type={"number"}
                  name="feeBps"
                  onChange={(e) => handleDropChange(e)}
                  required
                />
              </div>
              <div className="form-group mt-2">
                <label htmlFor="restrictFeeRecipients">
                  Restrict Fee Recipients:
                </label>
                <select
                  className="form-select form-select"
                  aria-label=".form-select-lg example"
                  name="restrictFeeRecipients"
                  onChange={(e) => handleDropChange(e)}
                  required
                >
                  <option selected disabled>
                    Select an Option
                  </option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
            </div>
          </div>
          {/* <div className="form-group mb-3">
            <label for="validity">Upload CSV File:</label>
            <div className="input-group">
              <input
                className="form-control"
                placeholder="Choose CSV File"
                id="csvFile"
                type={"file"}
                accept=".csv"
                name="csvFile"
                onChange={createSeaDropRoot}
                required
              />
            </div>
          </div> */}
          {/* <div className="form-group mt-2">
            <label htmlFor="no of coupon">Proof:</label>
            <input
              className="form-control"
              placeholder="Enter Proof(bytes32[]) here..."
              id="proof"
              type={"text"}
              name="proof"
              onChange={(e) => handleChange(e)}
              required
            />
          </div> */}

          <br />
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-primary  btn-lg"
              onClick={handleParams}
            >
              Test Params
            </button>
            <button
              type="button"
              className="btn btn-primary  btn-lg"
              onClick={handleMintPublic}
              disabled={mintLoading}
            >
              {" "}
              {mintLoading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "Mint"
              )}{" "}
            </button>
          </div>
        </div>
      ) : (
        navigate("/login")
      )}
    </>
  );
};

export default MintAllowList;
