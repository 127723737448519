import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import truncateEthAddress from "truncate-eth-address";
import { web3 } from "../web3";
import { services } from "../services";
import { useConnectWallet } from "@web3-onboard/react";
import useWeb3AccountData from "../helpers/customHooks";
import { ethers } from "ethers";

const LoginpopUp = ({ msg, btncolor }) => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [ethersProvider, setProvider] = useState(null);
  // const [account, setAccount] = useState<Account | null>(null)
  const [address, isLoading, isConnected] = useWeb3AccountData();

  //const { address } = useAccount();

  //const { disconnect } = useDisconnect();
  //const { switchNetwork } = useNetwork();

  // console.log("address", address);
  const generateNonce = async (address) => {
    const nonce = await services.get(`/user/generateNonce/${address}`);
    console.log("nonceee", nonce);
    if (nonce?.status === 200) {
      return nonce.data.data.nonce;
    }
  };
  
  useEffect(() => {
    // If the wallet has a provider than the wallet is connected
    if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, "any"));
      // if using ethers v6 this is:
      // ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')
    }
  }, [wallet]);

  // useEffect(async() => {

  //     // Refresh the state of the component.
  //     let authToken = localStorage.getItem("arcAuthToken");
  //     let addr = localStorage.getItem("addr");
  //     console.log("authToken", !authToken, authToken, addr);
  //     if (authToken == null) {
  //       if(address){
  //         signMessage();
  //       }
  //     } else if (addr !== address) {
  //       console.log("Else if while wallet connect")
  //       signMessage();
  //       // refreshStates(true);
  //       // disconnect();
  //       // buttonRef.current?.click();

  //     }

  // }, [address]);

  const signMessage = useCallback(async () => {
    const nonce = await generateNonce(address);
    console.log("nonce", nonce, address);
    if (address && nonce) {
      const chainId = await web3.eth.net.getId();
      console.log("chainId", chainId);
      // if (chainId !== 97) switchNetwork(97);
      try {
        let signature = await web3.eth.personal.sign(
          web3.utils.utf8ToHex(
            `Welcome to ARC!\nClick to sign in and accept the ARC Terms of Service.\nThis request will not trigger a blockchain transaction or cost any gas fees.\nYour authentication status will reset after 24 hours.\nWallet address:\n${address}\nNonce:\n${nonce}`
          ),
          address
        );
        if (signature) {
          console.log("signature", signature);
          // await authLogin(nonce, signature,address);
        }
      } catch (error) {
        disconnect();
        console.log("disconnect function called");
        if (error.code === 4001) {
          console.log("Signature denied by the user.");
        } else {
          console.error("An error occurred while signing the message:", error);
        }
      }
    }
  });
  const refreshStates = (clearStorage) => {
    if (clearStorage) {
      localStorage.clear();
    }
  };
  return (
    <div className="d-flex align-items-center justify-content-center">
      {/* {openConnectModal && (
        <Button
          variant={`btn btnBackground px-4 py-1`}
          onClick={openConnectModal}
          ref={buttonRef}
        >
          {msg}
        </Button>
      )} */}

      {/* {openAccountModal && (
        <Button
          variant="btn btnBackground px-4 py-1"
          onClick={openAccountModal}
        >
          {truncateEthAddress(address)}
        </Button>
      )} */}
    </div>
  );
};

export default LoginpopUp;
