import React, { useEffect } from "react";
import { useState } from "react";
import { getContractInstance } from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { web3 } from "../web3";
import { services } from "../services";
// import Loader from "../assets/loading_arc.gif";
import Timer from "./timer";
import useWeb3AccountData from "../helpers/customHooks";
import { Loader1 } from './loader';

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const ReedemCoupon = () => {
  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [couponDetails, setCouponDetails] = useState({
    couponCode: null,
    reedemType: 0,
  });
  const [loading, setLoading] = useState({ id: null, load: false });
  const [emptyLoading, setEmptyLoading] = useState(true);
  const [couponList, setCouponList] = useState([]);
  const [countDown, setCountDown] = useState({});
  let crntTime = Math.floor(Date.now() / 1000);
  useEffect(() => {
    const getCouponList = async () => {
      // if (address) {
      let list = [];
      const couponListRes = await services.get(`/coupon/list/`);
      if (couponListRes?.status == 200) {
        for (let i = +couponListRes.data.data.length - 1; i >= 0; i--) {
          await list.push(couponListRes.data.data[i]);
        }
        await setCouponList(list);
        await setEmptyLoading(false);
      }
      await setEmptyLoading(false);
    };
    getCouponList();

    // countDownTimer();
  }, [address]);
  console.log("couponList", couponList);
  const handleChange = (e) => {
    setCouponDetails({ ...couponDetails, [e.target.name]: e.target.value });
  };

  const redeemCoupon = async (coupon) => {
    let currentTime = await Math.floor(Date.now() / 1000);
    let endTime = (await +coupon.startTime) + 86400 * +coupon.validity;
    await console.log(
      "startTime",
      +coupon.startTime,
      "currentTime",
      currentTime,
      "endTime",
      endTime
    );
    let obj = await [
      coupon.name,
      coupon.couponHash,
      coupon.creator,
      coupon.startTime,
      endTime,
    ];
    console.log("obj", obj);
    if (currentTime <= +coupon.startTime) {
      await toast.error("Redeeming time has not started yet!");
    } else if (currentTime >= +endTime) {
      await toast.error("Validity of redeeming is over!");
    } else if (coupon?.creator == address) {
      await toast.error("Creater can't reedem own coupon!");
    } else {
      console.log("testing");
      setLoading({ id: coupon._id, load: true });
      if (coupon.txnThroughOwner) {
        let res = await services.post(`/coupon/redeem`, {
          couponCode: couponDetails.couponCode,
          signature: coupon.signature,
          redeemedBy: address,
          couponType: couponDetails.reedemType,
        });
        console.log("res", res);
        if (res.status == 200) {
          toast.success("Transaction Successful!");
          setLoading({ id: null, load: false });
        } else {
          if (res.response.status == 404) {
            toast.error(res.response.data.message);
            setLoading({ id: null, load: false });
          } else {
            toast.error("Transaction Failed!");
            setLoading({ id: null, load: false });
          }
        }
      } else {
        let reedemData = await services.get(
          `/coupon/redeemable/${coupon.signature}/${couponDetails.couponCode}`
        );
        console.log("reedemData", reedemData);
        if (reedemData.status == 200) {
          let sign = await reedemData.data.data.signature;
          let obj = await [
            coupon.name,
            coupon.couponHash,
            coupon.creator,
            coupon.couponCost,
            address,
            couponDetails.couponCode,
            couponDetails.reedemType,
            sign,
            coupon.startTime,
            endTime,
          ];

          
          console.log("obj", obj);
          const couponContractInstance = await getContractInstance();
          await couponContractInstance.methods
            .redeemCoupon(obj)
            .send({ from: address })
            .on("transactionHash", (hash) => {
              console.log("transaction hash : ", hash);
              toast.info("Transaction is Processing...");
            })
            .on("receipt", (receipt) => {
              console.log("on receipt ", receipt);
              toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
              setLoading({ id: null, load: false });
            })
            .on("error", (error) => {
              console.log("on error ", error);
              toast.error("Transaction Failed!");
              setLoading({ id: null, load: false });
            });
        } else {
          toast.error("Coupon Not Valid");
          setLoading({ id: null, load: false });
        }
      }
    }
  };
  // const countDownTimer = async () => {
  // let currTime = await Math.floor(Date.now() / 1000);

  // };
  console.log(countDown);
  return (
    <>
      <div className="container my-5">
      <h2 className="fw-bold">Redeem Coupon</h2>

        <div className="row">
          {emptyLoading ? (
            <div
              className="text-center mt-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <img src={Loader} width={200} height={200} /> */}
              <Loader1/>
            </div>
          ) : couponList.length == 0 ? (
            <div className="text-center my-5">
              <span style={{ fontSize: "4rem", fontWeight: "bold" }}>
                Coupon Not Available
              </span>
            </div>
          ) : (
            couponList.map((coupon, i) => (
              <div class="redeemcard">
              
              <div  key={i}>
                <div>
                  <div className="col-12 p-0 text-center">
                    <img
                      src={coupon?.imageUrl}
                      className="rounded-0"
                      width="100%"
                      height={100}
                    />
                  </div>
                  <table
                    className="my-2"
                    style={
                      {
                        // backgroundColor: "#171c2b",
                        // crntTime < +coupon.startTime + 86400 * +coupon.validity
                        //   ? "#171c2b"
                        //   : "#c9c9c9",
                      }
                    }
                  >
                    <tbody className="bodyTableCoupon">
                      <tr>
                        <th className="">Name</th>
                        <td className=" text-break">
                          {coupon?.name ? coupon?.name : "NFT Name"}
                        </td>
                      </tr>
                      <tr>
                        <th className="">Description</th>
                        <td className=" text-break">{coupon?.description}</td>
                      </tr>
                      <tr>
                        <th className="">Validity</th>
                        <td className=" text-break">{coupon?.validity} Days</td>
                      </tr>
                      <tr>
                        <th className="">Coupon Cost</th>
                        <td className=" text-break">
                          {coupon?.couponCost > 1
                            ? web3.utils.fromWei(coupon?.couponCost)
                            : coupon?.couponCost / 1000000000000000000}
                        </td>
                      </tr>
                      <tr>
                        <th className="">Creator</th>
                        <td className=" text-break">{coupon?.creator}</td>
                      </tr>
                      <tr>
                        <th className="">Minted By</th>
                        <td className=" text-break">
                          {coupon?.txnThroughOwner
                            ? "Owner Wallet"
                            : "User Wallet"}
                        </td>
                      </tr>
                      <tr>
                        <th className="">Start Date</th>
                        <td className=" text-break">
                          {new Date(coupon?.startTime * 1000).toString()}
                        </td>
                      </tr>
                      <tr>
                        <th className="">CouponCode</th>
                        <td className=" text-break">
                          <input
                            className="form-control"
                            id="CouponCode"
                            type="number"
                            name="couponCode"
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#292F3D",
                              color: "#FFFF",
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th className="">Reedem Coupon Token Type</th>
                        <td className=" text-break">
                          <select
                            className="form-select form-select"
                            aria-label=".form-select-lg example"
                            name="reedemType"
                            onChange={handleChange}
                            required
                            style={{
                              backgroundColor: "#292F3D",
                              color: "#FFFF",
                            }}
                          >
                            <option selected disabled>
                              Select an Option
                            </option>
                            <option value={0}>ERC Token</option>
                            <option value={1}>NFT Token</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-12">
                    <div className="text-center pt-2 pb-4">
                      <button
                        type="button"
                        className={`btn px-5 border-0 ${
                          crntTime >
                          +coupon.startTime + 86400 * +coupon.validity
                            ? "btnBackgroundExpired"
                            : "btnBackground"
                        }`}
                        onClick={() => redeemCoupon(coupon)}
                        disabled={
                          loading.id == coupon._id
                            ? true
                            : crntTime <= +coupon.startTime ||
                              crntTime >
                                +coupon.startTime + 86400 * +coupon.validity
                            ? true
                            : false
                        }
                      >
                        {" "}
                        {loading.id == coupon._id ? (
                          "Loading..."
                        ) : crntTime <= +coupon.startTime ? (
                          <Timer timeLeft={+coupon.startTime} />
                        ) : crntTime >
                          +coupon.startTime + 86400 * +coupon.validity ? (
                          "REDEEM COUPON EXPIRED!"
                        ) : (
                          "REDEEM COUPON"
                        )}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            ))
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default ReedemCoupon;
