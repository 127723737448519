import React, { useEffect } from "react";
import { useState } from "react";
import { getlazyNFTTokenInstance } from "../helpers/functions";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { web3 } from "../web3";
import Timer from "../components/timer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWeb3AccountData from "../helpers/customHooks";

const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};
const PlaceBidModal = ({
  id,
  coupon,
  setOpenBidModal,
  openBidModal,
  getnftList,
}) => {

  const [address,isLoading,isConnected]=useWeb3AccountData()
  const [isShow, invokeModal] = useState(false);
  const [loadingID, setLoadingID] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [loading, setLoading] = useState(false);
  const [bidPrice, setBidPrice] = useState(0);
  let crntTime = Math.floor(Date.now() / 1000);

  const compressAddress = (address) => {
    return (
      address.slice(0, 7) +
      "...." +
      address.slice(address.length - 7, address.length)
    );
  };

  const handleBidPlace = async (id) => {
    try {
      let incbidvalue =
        (await coupon?.amount) == 0
          ? +coupon?.nftCost + (+coupon?.nftCost / 100) * 5
          : +coupon?.amount + (+coupon?.amount / 100) * 5;
      // console.log(
      //   incbidvalue,
      //   "coupon",
      //   coupon,
      //   "web3.utils.toWei(bidPrice)",
      //   web3.utils.toWei(bidPrice)
      // );
      if (+web3.utils.toWei(bidPrice) < +incbidvalue)
        toast.error("Next bid must be 5% more than the current bid");
      else if (!address) toast.error("Please connect your wallet first!");
      else {
        setLoading(true);
        let obj = await [
          coupon.name,
          coupon.nftUri,
          coupon.creator,
          coupon.nftCost,
          address,
          coupon.signature,
          coupon.saleType,
          coupon.startTime,
          coupon.endTime,
        ];
        console.log(web3.utils.toWei(bidPrice), "object", obj);
        const LazyNFTInstance = await getlazyNFTTokenInstance();
        await LazyNFTInstance.methods
          .placeBid(obj)
          .send({
            from: address,
            value: web3.utils.toWei(bidPrice),
          })
          .on("transactionHash", (hash) => {
            // console.log("transaction hash : ", hash);
            toast.info("Transaction is Processing...");
          })
          .on("receipt", (receipt) => {
            console.log("on receipt ", receipt);
            getnftList();
            toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
            setLoading(false);
          })
          .on("error", (error) => {
            console.log("on error ", error);
            toast.error("Transaction Failed!");
            setLoading(false);
          });
      }
    } catch (error) {
      console.log("on error ", error);
      setLoading(false);
    }
  };

  const initModal = (id) => {
    setLoadingID(id);
    return setOpenBidModal({
      id: null,
      load: !openBidModal.load,
    });
  };
  localStorage.setItem("lazyNftId", id);
  console.log("coupon", coupon);
  return (
    <>
      {/* {loadingFirst ? (
        "Loading..."
      ) : crntTime < coupon.endTime ? (
        <Button variant="btn btn-primary" onClick={() => initModal(id)}>
          {loadingID == id ? "Loading..." : "Place a Bid"}
        </Button>
      ) : coupon?.bidder == address && coupon.redeemedBy == null ? (
        <Button
          variant="btn btn-primary"
          onClick={() => claimNFT(id, coupon.signature)}
        >
          {loadingID == id ? "Loading..." : "Claim NFT"}
        </Button>
      ) : (
        <Button
          variant="btn btn-secondary"
          disabled={true}
          // onClick={() => claimNFT(id, coupon.signature)}
        >
          {"Auction Ended"}
        </Button>
      )} */}
      <Modal show={openBidModal.load}>
        <Modal.Header closeButton onClick={initModal}>
          <Modal.Title>Place a Bid</Modal.Title>
        </Modal.Header>
        <Modal.Body className="align-items-center justify-content-center rounded py-2 px-4">
          <Row>
            <Col xs={5}>Current Bidder</Col>
            <Col xs={7} className="text-break">
              {compressAddress(coupon?.bidder)}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={5}>Current Bidding Price</Col>
            <Col xs={7}>
              {coupon?.amount == undefined
                ? web3.utils.fromWei(coupon?.nftCost)
                : coupon?.amount == 0
                ? web3.utils.fromWei(coupon?.nftCost)
                : web3.utils.fromWei(coupon?.amount)}{" "}
              BNB
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={5}>Bid Ended In</Col>
            <Col xs={7}>
              {coupon?.endTime ? <Timer timeLeft={+coupon.endTime} /> : ""}
            </Col>
          </Row>
          <hr />
          <div className=" mt-2">
            <Form.Label htmlFor="newBid">Place your bid</Form.Label>
            <Form.Control
              type="number"
              id="newBid"
              aria-describedby="bid"
              onChange={(e) => setBidPrice(e.target.value)}
            />

            <Form.Text id="bid" muted>
              Next bid must be 5% more than the current bid
            </Form.Text>
          </div>
          <div className="d-grid gap-2 my-2">
            <Button
              variant="primary"
              size="lg"
              onClick={() => handleBidPlace(id)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Place a Bid"}
            </Button>
            <Button variant="danger" size="lg" onClick={initModal}>
              Close
            </Button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="align-items-center justify-content-center rounded  py-2 px-4">
          <div className="d-grid gap-2">
            <Button variant="danger" size="lg" onClick={initModal}>
              Close
            </Button>
          </div>
        </Modal.Footer> */}
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default PlaceBidModal;
