import { BigNumber, utils } from 'ethers'
import BalanceTree from './balance-tree'
// import fs from "fs";
// import program from "commander";
import data from './Address.json'
import { web3 } from '../../web3'

const { isAddress, getAddress } = utils

// const program = require("commander");
// const fs = require("fs");
// import { parseBalanceMap } from "../src/parse-balance-map";

console.log('data', data)
// program.version("0.0.0");

// program.parse(process.argv);

// const json = JSON.parse(data);

if (typeof data !== 'object') throw new Error('Invalid JSON')

console.log(JSON.stringify(parseBalanceMap(data)))

// This is the blob that gets distributed and pinned to IPFS.
// It is completely sufficient for recreating the entire merkle tree.
// Anyone can verify that all air drops are included in the tree,
// and the tree has no additional distributions.
// interface AirdropInfo {
//   merkleRoot: string;
//   tokenTotal: string;
//   claims: {
//     [account: string]: {
//       index: number;
//       amount: string;
//       proof: string[];
//     };
//   };
// }

export function parseBalanceMap(balanceMap) {
  const mapped = Object.keys(balanceMap).reduce((memo, account) => {
    console.log('memo', memo)
    if (!isAddress(account)) {
      throw new Error(`Found invalid address: ${account}`)
    }
    const parsed = getAddress(account)
    if (memo[parsed]) throw new Error(`Duplicate address: ${parsed}`)
    // console.log(
    //   'balance map value',
    //   balanceMap[account],
    //   web3.utils.toWei(balanceMap[account])
    // )
    // const parsedNum = balanceMap[account]
    //   ? BigNumber.from(web3.utils.toWei(balanceMap[account]))
    //   : ''
    const parsedNum = BigNumber.from('10000000000000000000')
    // if (parsedNum.lte(0))
    //   throw new Error(`Invalid amount for account: ${account}`)
    memo[parsed] = parsedNum
    return memo
  }, {})

  const treeElements = Object.keys(mapped).map(account => ({
    account,
    amount: mapped[account]
  }))

  const tree = new BalanceTree(treeElements)
  console.log('tree root hash', tree.getHexRoot())

  const claims = treeElements.reduce((memo, { account, amount }, index) => {
    memo[account] = {
      index,
      amount: mapped[account].toHexString(),
      proof: tree.getProof(index, account, mapped[account])
    }
    return memo
  }, {})

  const tokenTotal = Object.keys(mapped).reduce(
    (memo, key) => memo.add(mapped[key]),
    BigNumber.from(0)
  )

  return {
    merkleRoot: tree.getHexRoot(),
    tokenTotal: tokenTotal.toHexString(),
    claims
  }
}
