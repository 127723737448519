import React, { useEffect } from "react";
import { useState } from "react";
import {
  getMerkelClaimTokenInstance,
  getMDTTokenInstance,
} from "../helpers/functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { services } from "../services";
import { web3 } from "../web3.js";
import getContractAddresses from "../contractData/address";
import ClaimData from "./testMerkle/ClaimData.json";
import Loader from "../assets/loading_arc.gif";
import useWeb3AccountData from "../helpers/customHooks";
// import Loader from "../assets/loading_arc.gif";
import { Loader1 } from './loader';


const SuccessPopUp = ({ txn }) => {
  return (
    <>
      Transaction Successful! Check your transaction{" "}
      <a
        href={`https://testnet.bscscan.com/tx/${txn}`}
        rel="noreferrer"
        target="_blank"
      >
        Click here
      </a>
    </>
  );
};

const ClaimToken = () => {
  const [address, isLoading, isConnected] = useWeb3AccountData();

  const { merkleClaimContract } = getContractAddresses();
  const [claimInfo, setClaimInfo] = useState([]);
  const [claimDetails, setClaimDetails] = useState(null);
  const [loading, setLoading] = useState({ id: null, loader: true });
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (address) merkleProof(address);
    else setFirstLoading(false);
  }, [address]);

  const merkleProof = async (address) => {
    let obj = [];
    const merkleClaimContractInstance = await getMerkelClaimTokenInstance();

    let list = await services.get(`/claim/list/`);
    // console.log("res", list);
    if (list.status === 200) {
      for (let i = list.data.data.length - 1; i >= 0; i--) {
        let proofDetails = await services.get(
          `/claim/getProof?CLAIM_ID=${list.data.data[i]._id}&USER_ADDRESS=${address}`
        );
        // console.log("proofDetails", proofDetails);

        if (proofDetails.status == 200) {
          let vault_Add = await merkleClaimContractInstance.methods
            .vaultAddress(proofDetails.data.data.rootHash)
            .call();
          // console.log("vault_Add", vault_Add);
          let claimedToken = await merkleClaimContractInstance.methods
            .claimedMap(vault_Add, proofDetails.data.data.index)
            .call();
          // console.log("claimedToken", claimedToken);
          let ele = await list.data.data[i];
          obj.push({
            ...ele,
            claimedToken: claimedToken,
            hex: proofDetails.data.data.rootHash,
            proof: proofDetails.data.data.userProof,
            index: proofDetails.data.data.index,
            amount: web3.utils.hexToNumberString(
              proofDetails.data.data.amount.hex
            ),
          });
          // );
          // console.log("ele", ele);
        }
      }
      setClaimInfo(obj);
      setFirstLoading(false);
      setLoading({ id: null, loader: false });
    } else {
      setFirstLoading(false);
    }
  };

  const claimMerkleDrop = async (claim) => {
    let currTimeStamp = await Math.floor(Date.now() / 1000);
    if (+claim.startTime > currTimeStamp)
      toast.error("Claim time not started yet!");
    else if (+claim.endTime < currTimeStamp) toast.error("Claim time ended!");
    else {
      setLoading({ id: claim._id, loader: true });
      const merkleClaimContractInstance = await getMerkelClaimTokenInstance();
      console.log("merkleClaimInstance", merkleClaimContractInstance);
      await merkleClaimContractInstance.methods
        .claim([claim?.hex], [claim?.proof], [claim?.index], [claim?.amount])
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log("transaction hash : ", hash);
          toast.info("Transaction is Processing...");
        })
        .on("receipt", async (receipt) => {
          console.log("on receipt ", receipt);
          await merkleProof(address);
          await toast.success(<SuccessPopUp txn={receipt.transactionHash} />);
        })
        .on("error", (error) => {
          console.log("on error ", error);
          toast.error("Transaction Failed!");
          setLoading({ id: null, loader: false });
        });
    }
  };

  console.log(claimInfo);
  return (
    <>
      <div className="container my-3 px-5">
        <h2 className="fw-bold">Claim Merkle Airdrop</h2>

        {/* <div className="container text-center"> */}
        {claimInfo.length != 0 ? (
          claimInfo.map((claim) => (
            <div
              className="row border-0 align-items-center py-2 mt-1"
              style={{
                backgroundColor:
                  Math.floor(Date.now() / 1000) > claim.endTime &&
                  !claim.claimedToken
                    ? "#191d26"
                    : claim.claimedToken
                    ? "#191d26"
                    : "#191d26",
              }}
            >
              {console.log(Math.floor(Date.now() / 1000))}
              <div className="col-12 col-lg-3 col-md-3 text-start px-2">
                <div className="row align-items-center text-break">
                  <div className="col-4 text-start">
                    <img
                      className="border-0 rounded-circle "
                      src={
                        "https://image.similarpng.com/very-thumbnail/2020/09/Currency-Bitcoin-on-transparent-background-PNG.png"
                      }
                      width={"75px"}
                      height={"75px"}
                    ></img>
                  </div>
                  <div className="col-8 p-2 align-self-center">
                    <span className="text-start fs-4 fw-semibold">
                      {claim.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-2 col-md-2">
                <h5>Start Time</h5>
                <p>
                  {new Date(+claim.startTime * 1000).toString().split("GMT")[0]}
                </p>
              </div>
              <div className="col-6 col-lg-2 col-md-2">
                <h5>End Time</h5>
                <p>
                  {new Date(+claim.endTime * 1000).toString().split("GMT")[0]}
                </p>
              </div>
              <div className="col-6 col-lg-1 col-md-1 text-center">
                <h5>Amount</h5>
                <p>
                  {/* {generateProof(claim._id)} */}
                  {claim?.amount && web3.utils.fromWei(claim?.amount)}
                </p>
              </div>
              <div className="col-6 col-lg-1 col-md-1 text-center">
                <h5>Symbol</h5>
                <p>
                  {/* {generateProof(claim._id)} */}
                  {claim?.symbol}
                </p>
              </div>
              <div className="col-12 col-lg-3 col-md-3 text-center">
                {Math.floor(Date.now() / 1000) > claim.endTime &&
                !claim.claimedToken ? (
                  <button className="btn btn-lg btn-secondary" disabled>
                    CLAIMED EXPIRED
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`btn btn-lg ${
                      claim.claimedToken ? "btn-secondary" : "btn-primary"
                    }`}
                    onClick={() => claimMerkleDrop(claim)}
                    disabled={
                      loading.id == claim._id
                        ? true
                        : claim.claimedToken
                        ? true
                        : false
                    }
                  >
                    {" "}
                    {loading.id == claim._id ? (
                      <div>
                        Loading{" "}
                        <span
                          className="spinner-grow  text-light spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        <span
                          className="spinner-grow  text-light spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                      </div>
                    ) : claim.claimedToken ? (
                      "ALREADY CLAIMED"
                    ) : (
                      "CLAIM MERKLE AIRDROP"
                    )}{" "}
                  </button>
                )}
              </div>
            </div>
          ))
        ) : firstLoading ? (
          <div
            className="text-center mt-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <img src={Loader} width={200} height={200} /> */}
            <Loader1 />

          </div>
        ) : address ? (
          <div className="fs-1 text-center fw-bold">
            Merkle Token not Available
          </div>
        ) : (
          <div className="fs-1 text-center fw-bold">Connect your Wallet</div>
        )}
        {/* </div> */}
        <br />
        {/* <div className="d-grid gap-2">
          {ClaimData.claims[address] != undefined ? (
            <button
              type="button"
              className="btn btn-primary  btn-lg"
              onClick={claimMerkleDrop}
              disabled={loading}
            >
              {" "}
              {loading ? (
                <div>
                  Loading{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  <span
                    className="spinner-grow  text-light spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              ) : (
                "Claim Merkle Airdrop"
              )}{" "}
            </button>
          ) : (
            <div className="fs-1 text-center fw-bold">
              Merkle Token not Available
            </div>
          )}
        </div> */}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default ClaimToken;
